import CallSplitIcon from '@mui/icons-material/CallSplit';
import { Box, Divider, IconButton, MenuItem, Skeleton, Stack, Tooltip, useTheme } from '@mui/material';
import { FC, memo, useEffect, useMemo, useState, MouseEvent, useCallback } from 'react';
import ReactGA from 'react-ga4';
import StyledMenu from 'src/components/StyledMenu';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import { OrderEntity } from 'src/entities/orderEntity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsNotAllocReason } from 'src/entities/PlanningsNotAllocReasons.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { OrderFactory } from 'src/models/OrderFactory';

import DisplayPropertiesSelectableUnallocatedOrderPresenter
  from './DisplayPropertiesSelectableUnallocatedOrder.presenter';

type Props = {
  id: number;
  data: OrderEntity;
  addSelectedId: (id: number) => void;
  removeSelectedId: (id: number) => void;
  selected: boolean;
  isLoading: boolean;
  setToEditOrderId: (id: number) => void;
  setDialogIsOpen: (open: boolean) => void;
  setSplitDialogIsOpen: (open: boolean) => void;
  mutateDeleteSpecificOrder: (orderId: number) => void;
  mutateCloneOrder: (order: OrderEntity) => void;
  customInputFields: string[];
  hideOrderProperties: string[];
  notAllocReasons: PlanningsNotAllocReason[];
  truckEntities: PlanningsTruckEntity[];
  driverEntities: PlanningsDriverEntity[];
  deliveryEntities: PlanningsDeliveryEntity[];
  mutateRestoreSplittedOrder: (orderId: number) => void;
}

const OrderPresenter: FC<Props> = memo((
  {
    id,
    data,
    addSelectedId,
    removeSelectedId,
    selected,
    isLoading,
    setToEditOrderId,
    setDialogIsOpen,
    setSplitDialogIsOpen,
    mutateDeleteSpecificOrder,
    mutateCloneOrder,
    customInputFields,
    hideOrderProperties,
    notAllocReasons,
    truckEntities,
    driverEntities,
    deliveryEntities,
    mutateRestoreSplittedOrder,
  }
) => {
  const theme = useTheme();
  const [model, setModel] = useState<OrderFactory | undefined>(undefined);
  useEffect(() => {
    if (!data) return;

    setModel(
      new OrderFactory(data)
    );
  }, [data]);

  const [bgColor, setBgColor] = useState<string>(theme.colors.alpha.black[10]);
  useEffect(() => {
    if (selected) {
      setBgColor(theme.colors.primary.light);

      return;
    }

    setBgColor(theme.colors.alpha.black[10]);
  }, [selected, theme.colors]);

  const onClick = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: `案件選択 ${selected ? 'OFF' : 'ON'}`, label: id });
    if (isLoading) return;

    if (selected) {
      removeSelectedId(id);
    } else {
      addSelectedId(id);
    }
  }, [addSelectedId, id, isLoading, removeSelectedId, selected]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onClickMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);
  const onClickClose = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  }, []);

  const onClickEdit = useCallback((event: MouseEvent) => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '案件編集', label: id });
    event.stopPropagation();
    setAnchorEl(null);
    setToEditOrderId(id);
    setDialogIsOpen(true);
  }, [id, setDialogIsOpen, setToEditOrderId]);

  const onClickSplit = useCallback((event: MouseEvent) => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '案件分割', label: id });
    event.stopPropagation();
    setAnchorEl(null);
    setToEditOrderId(id);
    setSplitDialogIsOpen(true);
  }, [id, setSplitDialogIsOpen, setToEditOrderId]);

  const onClickClone = useCallback((event: MouseEvent) => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '案件コピー', label: id });
    event.stopPropagation();
    setAnchorEl(null);
    // eslint-disable-next-line no-alert
    if (window.confirm('案件のコピーを作成します。よろしいですか？')) {
      mutateCloneOrder(data);
    }
  }, [id, data, mutateCloneOrder]);

  const onClickDelete = useCallback((event: MouseEvent) => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '案件削除', label: id });
    event.stopPropagation();
    setAnchorEl(null);
    // eslint-disable-next-line no-alert
    if (window.confirm('案件を削除します。よろしいですか？')) {
      mutateDeleteSpecificOrder(id);
    }
  }, [id, mutateDeleteSpecificOrder]);

  const onClickRestoreSplittedOrder = useCallback((event: React.MouseEvent) => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '案件分割の復元', label: id });

    event.preventDefault();
    event.stopPropagation();

    mutateRestoreSplittedOrder(id);
  }, [id, mutateRestoreSplittedOrder]);

  const orderMenuMemo = useMemo(() => (
    <StyledMenu
      anchorEl={anchorEl}
      open={open}
      onClose={onClickClose}
    >
      <MenuItem
        onClick={onClickEdit}
        disableRipple
      >
        編集
      </MenuItem>
      <MenuItem
        onClick={onClickSplit}
        disableRipple
      >
        分割
      </MenuItem>
      <MenuItem
        onClick={onClickClone}
        disableRipple
      >
        コピー
      </MenuItem>
      <MenuItem
        onClick={onClickDelete}
        disableRipple
      >
        削除
      </MenuItem>
    </StyledMenu>
  ), [anchorEl, onClickClone, onClickClose, onClickDelete, onClickEdit, onClickSplit, open]);

  const orderSplitMemo = useMemo(() => (
    <Stack
      sx={{
        position: 'absolute',
        top: 5,
        right: 34,
      }}
    >
      <Tooltip title="自動分割された案件です">
        <IconButton
          onClick={onClickRestoreSplittedOrder}
          sx={{ p: 0.5, m: 0 }}
        >
          <CallSplitIcon color="error" fontSize="small" sx={{ p: 0, m: 0, }} />
        </IconButton>
      </Tooltip>
    </Stack>
  ), [onClickRestoreSplittedOrder]);

  const orderMemo = useMemo(() => (
    <DisplayPropertiesSelectableUnallocatedOrderPresenter
      data={data}
      customInputFields={customInputFields}
      onClickMenu={onClickMenu}
      hideOrderProperties={hideOrderProperties}
      notAllocReasons={notAllocReasons}
      truckEntities={truckEntities}
      driverEntities={driverEntities}
      deliveryEntities={deliveryEntities}
    />
  ), [data, customInputFields, onClickMenu, hideOrderProperties, notAllocReasons, truckEntities, driverEntities, deliveryEntities]);

  if (!model) return <Skeleton variant="rectangular" animation="wave" width="100%" height={145} />;

  return (
    <>
      <Box
        onClick={onClick}
        sx={{
          width: '100%',
          textAlign: 'left',
          display: 'block',
          mb: 0.1,
          position: 'relative',
          cursor: 'pointer',
        }}
      >
        <Stack
          direction="row"
          alignItems="stretch"
          spacing={0}
          sx={{
            bgcolor: bgColor,
          }}
          pl={0.5}
        >
          {orderMemo}
          {data.order_split && orderSplitMemo}
          {orderMenuMemo}
        </Stack>
      </Box>
      <Divider sx={{ height: '1px' }} />
    </>
  );
});

export default OrderPresenter;
