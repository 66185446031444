// eslint-disable-next-line max-classes-per-file
import { format } from 'date-fns-tz';
import { ArrivalOperationEntity } from 'src/entities/arrivalOperationEntity';
import { DepartureOperationEntity } from 'src/entities/departureOperationEntity';
import { DriverEntity } from 'src/entities/driverEntity';
import { GarageEntity } from 'src/entities/garageEntity';
import { OrderEntity } from 'src/entities/orderEntity';
import { OrderOperationEntity } from 'src/entities/orderOperationEntity';
import { ShiftEntity } from 'src/entities/shiftEntity';
import { TruckEntity } from 'src/entities/truckEntity';
import { ActionKind } from 'src/pages/OperationDirectionPresenter';

import arrayUtil from '../utils/array.util';

import { OrderModel } from './OrderModel';
import { OrderOperationModel, OrderOperationWithOrder } from './OrderOperationModel';

export type CurrentAndNextPolyline = {
  operations: Omit<OrderOperationWithOrder, 'merge'>[];
  currentLat: number;
  currentLng: number;
  currentName: string;
  currentDepartureAt: Date;
  currentMinArrivalDeadlineAt: Date;
  currentArrivalAt: Date;
  nextName: string;
  nextLng: number;
  nextLat: number;
  nextDepartureAt: Date;
  nextMinArrivalDeadlineAt: Date;
  nextArrivalAt: Date;
}

class TruckModel {
  id: number;

  garageId: number;

  licensePlateValue: string; // ナンバープレート番号

  maximumLoadingCapacityKg: number; // 最大積載重量

  maximumLoadingRate?: number; // 最大積載率

  minimumLoadingRate?: number; // 最小積載率

  klass?:
    | '軽貨物'
    | 'トラック'
    | 'セミトレーラー'
    | 'ポールトレーラー'
    | 'フルトレーラー'; // 車両タイプ

  carModel?:
    | '平ボディ'
    | 'バンボディ'
    | 'ウィングボディ'
    | '保冷車'
    | '冷凍車'
    | '車載車'
    | '重機運搬車'
    | '危険物運搬車'
    | 'ダンプ'
    | '幌'
    | 'ユニック'
    | '海上コンテナー用'; // 車種

  floorSpecification?: '鉄板' | 'ステンレス' | 'ジョルダー' | '縞板'; // 床仕様

  loadingPlatformHeight?: '標準' | '低床' | '中低床'; // 荷台高の種別

  loadingPlatformLength?: '大型' | '中型' | '小型' | 'ショート' | 'ロング'; // 荷台長の種別

  loadingPlatformWidth?: '標準' | 'セミワイド' | 'ワイド'; // 荷台幅の種別

  loadingPlatformHeightCm?: number; // 荷台高

  loadingPlatformLengthCm?: number; // 荷台高

  loadingPlatformWidthCm?: number; // 荷台高

  loadingPlatformVolumeM3?: number;

  features?: ('パワーゲート' | '空調' | 'エアサス' | 'スタンション')[]; // 装置・特徴

  groupIds: number[];

  constructor(entity: TruckEntity) {
    this.id = entity.id;
    this.garageId = entity.garage_id;
    this.licensePlateValue = entity.license_plate_value;
    this.maximumLoadingCapacityKg = entity.maximum_loading_capacity_kg;
    this.maximumLoadingRate = entity.maximum_loading_rate;
    this.minimumLoadingRate = entity.minimum_loading_rate;
    this.klass = entity.klass;
    this.carModel = entity.car_model;
    this.floorSpecification = entity.floor_specification;
    this.loadingPlatformHeight = entity.loading_platform_height;
    this.loadingPlatformLength = entity.loading_platform_length;
    this.loadingPlatformWidth = entity.loading_platform_width;
    this.loadingPlatformHeightCm = entity.loading_platform_height_cm;
    this.loadingPlatformLengthCm = entity.loading_platform_length_cm;
    this.loadingPlatformWidthCm = entity.loading_platform_width_cm;
    this.loadingPlatformVolumeM3 = entity.loading_platform_volume_m3;
    this.features = entity.features;
    this.groupIds = entity.group_ids;
  }

  toSearchKw() {
    return [
      this.licensePlateValue,
      this.maximumLoadingCapacityKg,
      this.maximumLoadingRate,
      this.minimumLoadingRate,
      this.klass,
      this.carModel,
      this.floorSpecification,
      this.loadingPlatformHeight,
      this.loadingPlatformLength,
      this.loadingPlatformWidth,
      this.loadingPlatformHeightCm,
      this.loadingPlatformLengthCm,
      this.loadingPlatformWidthCm,
      this.features
    ].filter((maybe) => !!maybe).join(' ');
  }
}

class DriverModel {
  id: number;

  truckId: number;

  name: string; // ドライバー名

  emailAddress?: string; // メールアドレス

  phoneNumber?: string; // 電話番号

  constructor(entity: DriverEntity) {
    this.id = entity.id;
    this.truckId = entity.default_truck_id;
    this.name = entity.name;
    this.emailAddress = entity.email_address;
    this.phoneNumber = entity.phone_number;
  }

  toSearchKw() {
    return [
      this.name,
      this.emailAddress,
      this.phoneNumber,
    ].filter((maybe) => !!maybe).join(' ');
  }
}
class GarageModel {
  id: number;

  name: string; // 車庫名

  address: string; // 住所

  lat?: number; // 緯度

  lng?: number; // 経度

  constructor(entity: GarageEntity) {
    this.id = entity.id;
    this.name = entity.name;
    this.address = entity.address;
    this.lat = entity.lat;
    this.lng = entity.lng;
  }

  toSearchKw() {
    return [
      this.name,
      this.address
    ].filter((maybe) => !!maybe).join(' ');
  }
}

class DepartureOperationModel {
  id: number;

  shiftId: number;

  arrivalAt: Date; // 出発日時

  departureAt: Date; // 到着日時

  drivingDurationSeconds: number; // 運転時間

  operationDurationSeconds: number; // 作業時間

  operationStartAt: Date; // 作業開始日時

  waitingDurationSeconds: number; // 待機時間

  constructor(entity: DepartureOperationEntity) {
    this.id = entity.id;
    this.shiftId = entity.shift_id;
    this.arrivalAt = new Date(entity.arrival_at);
    this.departureAt = new Date(entity.departure_at);
    this.drivingDurationSeconds = entity.driving_duration_seconds;
    this.operationDurationSeconds = entity.operation_duration_seconds;
    this.operationStartAt = new Date(entity.operation_start_at);
    this.waitingDurationSeconds = entity.waiting_duration_seconds;
  }
}
class ArrivalOperationModel {
  id: number;

  shiftId: number;

  arrivalAt: Date; // 出発日時

  departureAt: Date; // 到着日時

  drivingDurationSeconds: number; // 運転時間

  operationDurationSeconds: number; // 作業時間

  operationStartAt: Date; // 作業開始日時

  waitingDurationSeconds: number; // 待機時間

  drivingDistanceMm: number; // 前地点からの走行距離

  constructor(entity: ArrivalOperationEntity) {
    this.id = entity.id;
    this.shiftId = entity.shift_id;
    this.arrivalAt = new Date(entity.arrival_at);
    this.departureAt = new Date(entity.departure_at);
    this.drivingDurationSeconds = entity.driving_duration_seconds;
    this.operationDurationSeconds = entity.operation_duration_seconds;
    this.operationStartAt = new Date(entity.operation_start_at);
    this.waitingDurationSeconds = entity.waiting_duration_seconds;
    this.drivingDistanceMm = entity.driving_distance_mm;
  }
}

export type GroupedOperation = {
  address: string;
  firstOperationName: string;
  operations: Omit<OrderOperationWithOrder, 'merge'>[];
  lng: number;
  departureAt: Date;
  lat: number;
  arrivalAt: Date
  idx: number;
  filterOperations: (action: ActionKind) => Omit<OrderOperationWithOrder, 'merge'>[];
  totalWeightKg: (action: ActionKind) => number;
  totalVolume: (action: ActionKind) => number;
  totalItemsCount: (action: ActionKind) => number;
  minArrivalDeadlineAt: Date;// 絶対に到着しなければならない時間、作業締め切り時間から作業時間を減算した日時
}

export class ShiftModel {
  id: number;

  driver: DriverModel;

  truck: TruckModel;

  garage: GarageModel;

  orders: OrderModel[];

  departureOperation?: DepartureOperationModel;

  orderOperations: OrderOperationModel[];

  arrivalOperation?: ArrivalOperationModel;

  startAt: Date; // 業務開始日時

  endAt: Date; // 業務終了日時

  workingAvailableDurationHours: number; // 労働時間

  color?: string;

  driverCostYenPerHours?: number;

  truckFuelCostYenPerKm?: number;

  truckInsuranceFeeYenPerDay?: number;

  truckRepairCostYenPerDay?: number;

  truckExpresswayFeeYenPerShift?: number;

  constructor(entity: ShiftEntity, color?: string) {
    this.id = entity.id;
    this.orders = entity.orders.map((order: OrderEntity) => new OrderModel(order));
    this.truck = new TruckModel(entity.truck);
    this.driver = new DriverModel(entity.driver);
    this.garage = new GarageModel(entity.garage);
    if (entity.departure_operation) this.departureOperation = new DepartureOperationModel(entity.departure_operation);
    this.orderOperations = entity.order_operations.map((oOp: OrderOperationEntity) => new OrderOperationModel(oOp));
    if (entity.arrival_operation) this.arrivalOperation = new ArrivalOperationModel(entity.arrival_operation);
    this.startAt = new Date(entity.start_at);
    this.endAt = new Date(entity.end_at);
    this.workingAvailableDurationHours = Number(entity.working_available_duration_hours);
    this.color = color;
    this.driverCostYenPerHours = entity.driver_cost_yen_per_hours || 0;
    this.truckFuelCostYenPerKm = entity.truck_fuel_cost_yen_per_km || 0;
    this.truckInsuranceFeeYenPerDay = entity.truck_insurance_fee_yen_per_day || 0;
    this.truckRepairCostYenPerDay = entity.truck_repair_cost_yen_per_day || 0;
    this.truckExpresswayFeeYenPerShift = entity.truck_expressway_fee_yen_per_shift || 0;
  }

  driverName() {
    return this.driver.name;
  }

  driverPhoneNumber() {
    return this.driver.phoneNumber;
  }

  licensePlateValue() {
    return this.truck.licensePlateValue;
  }

  maximumLoadingCapacityKg() {
    return this.truck.maximumLoadingCapacityKg;
  }

  loadingPlatformVolumeM3() {
    return this.truck.loadingPlatformVolumeM3;
  }

  filterOrderOperations(action: ActionKind): OrderOperationModel[] {
    if (!action) return this.orderOperations;

    return this.orderOperations.filter((op) => op.action === action);
  }

  filterGroupedOperations(action: ActionKind) {
    if (!action) return this.groupedOperations();

    return this
      .groupedOperations()
      .filter((gOp) => gOp.operations.some((op) => op.action === action));
  }

  totalItemsCount(action: ActionKind) {
    const filtered = this
      .filterGroupedOperations(action)
      .flatMap((gop) => gop.totalItemsCount(action));

    return filtered.reduce((prev, current) => prev + current, 0);
  }

  totalWeightKg(action: ActionKind) {
    const filtered = this
      .filterGroupedOperations(action)
      .flatMap((gop) => gop.totalWeightKg(action));

    const sum = filtered.reduce((prev, current) => prev + current, 0);

    return Math.round(sum * 1000) / 1000;
  }

  totalVolume(action: ActionKind) {
    const filtered = this
      .filterGroupedOperations(action)
      .flatMap((gop) => gop.totalVolume(action));

    const sum = filtered.reduce((prev, current) => {
      if (!current) return prev;

      return prev + current;
    }, 0);

    return Math.round(sum * 1000) / 1000;
  }

  orderIds() {
    return this
      .orderOperations
      .map((oOp) => oOp.orderId);
  }

  workStartTime() {
    if (this.departureOperation) {
      return format(this.departureOperation.departureAt, 'dd日 HH:mm', { timeZone: 'Asia/Tokyo' });
    }
      return '';
  }

  workEndTime() {
    if (this.arrivalOperation) {
      return format(this.arrivalOperation.arrivalAt, 'dd日 HH:mm', { timeZone: 'Asia/Tokyo' });
    }
      return '';
  }

  initialOrStockedOrders(): OrderModel[] {
    return this
      .orders
      .filter(({ id }) => this.orderOperations.filter(({ orderId }) => orderId === id).length === 1);
  }

  initialStockOrders(): OrderModel[] {
    return this
      .initialOrStockedOrders()
      .filter((order) => this.orderOperations.find(({ orderId }) => order.id === orderId).action === '降');
  }

  finalStockOrders(): OrderModel[] {
    return this
      .initialOrStockedOrders()
      .filter((order) => this.orderOperations.find(({ orderId }) => order.id === orderId).action === '積');
  }

  totalOrdersCount() {
    return Array.from(new Set(this.orderOperations.map((oOp) => oOp.orderId))).length;
  }

  totalUnloadingCount(): number {
    return Array.from(new Set(this.orderOperations.filter((op) => op.action === '降').map((oOp) => oOp.orderId))).length;
  }

  totalLoadingKg() {
    const sum = this
      .orderOperations
      .filter((oOp) => oOp.action === '積')
      .map((oOp) => this.orders.find(({ id }) => id === oOp.orderId))
      .map(({ itemTotalWeightKg }) => itemTotalWeightKg)
      .reduce((memo, num) => memo + num, 0);

    return Math.round(sum * 1000) / 1000;
  }

  maximumLoadingWeight() {
    if (this.indexedOperations().length === 0) { return 0; }
    return Math.max(...this.indexedOperations().map((ops) => ops.currentWeightKg));
  }

  loadingWeightRate() {
     return Math.round((this.maximumLoadingWeight() / this.maximumLoadingCapacityKg()) * 100);
  }

  maximumLoadingVolumeM3() {
    if (this.indexedOperations().length === 0) { return 0; }
    return Math.max(...this.indexedOperations().map((ops) => ops.currentVolumeM3));
  }

  loadingVolumeRate() {
    return Math.round((this.maximumLoadingVolumeM3() / this.loadingPlatformVolumeM3()) * 100);
  }

  loadingWeightRateText() {
    return `${this.loadingWeightRate()}%`;
  }

  loadingVolumeRateText() {
    return (this.maximumLoadingVolumeM3 && this.loadingPlatformVolumeM3()) ? `${this.loadingVolumeRate()}%` : '-%';
  }

  loadingRateText() {
    return `${this.loadingWeightRateText()}, ${this.loadingVolumeRateText()}`;
  }

  workPlanMinutes() {
    return (this.endAt.getTime() - this.startAt.getTime()) / (60 * 1000);
  }

  utilizationRateText() {
    const denominator = this.workingAvailableDurationHours ? this.workingAvailableDurationHours * 60 : this.workPlanMinutes();
    return `${Math.round((this.workingMinutes() / denominator) * 100)}%`;
  }

  cycleCount() {
    const actions = this.groupedOperations()
      .flatMap((grp) => grp.operations.map((ops) => ops.action));
    let count = 0;
    actions.forEach((act, idx) => {
      const prev = actions[idx - 1];
      if (prev === '積' && act === '降') count++;
    });

    return count;
  }

  cycleCountText() {
    return `${this.cycleCount()}回転`;
  }

  truckSummaryText(unit: string) {
    return [
      this.truck.licensePlateValue,
      this.truck.maximumLoadingCapacityKg,
      unit,
      this.loadingPlatformVolumeM3() ? [this.loadingPlatformVolumeM3(), 'm3'].join(' ') : ''
    ].join(' ');
  }

  modifiedOperations(): Omit<OrderOperationWithOrder, 'merge'>[] {
    return this
      .orderOperations
      .sort((prev, current) => prev.arrivalAt.getTime() - current.arrivalAt.getTime())
      .map((orderOperation) => orderOperation.merge(this.orders));
  }

  initialWeightKg() {
    return this
      .initialStockOrders()
      .reduce((num, order) => num + order.itemTotalWeightKg, 0);
  }

  initialVolumeM3() {
    return this
      .initialStockOrders()
      .filter((order) => order.itemTotalVolumeM3 > 0)
      .reduce((num, order) => num + order.itemTotalVolumeM3, 0);
  }

  private cachedIndexedOperations: Omit<OrderOperationWithOrder, 'merge'>[] | undefined = undefined;

  indexedOperations(): Omit<OrderOperationWithOrder, 'merge'>[] {
    if (!this.cachedIndexedOperations) {
      this.cachedIndexedOperations = this
        .modifiedOperations()
        .reduce((
          acum: Omit<OrderOperationWithOrder, 'merge'>[],
          op: Omit<OrderOperationWithOrder, 'merge'>,
          idx: number
        ) => {
          const prev = acum[idx - 1];
          const sameLocation = prev?.lat === op.lat && prev?.lng === op.lng;
          const currentOrder: OrderModel = this.orders.find(({ id }) => id === op.orderId);
          const isLoad = op.action === '積';
          const operationWeightKg = isLoad ? currentOrder.itemTotalWeightKg : currentOrder.itemTotalWeightKg * -1;
          const currentWeightKg = (idx === 0) ? this.initialWeightKg() + operationWeightKg : prev.currentWeightKg + operationWeightKg;
          const operationVolumeM3 = isLoad ? (currentOrder.itemTotalVolumeM3 || 0) : (currentOrder.itemTotalVolumeM3 || 0) * -1;
          const currentVolumeM3 = (idx === 0) ? this.initialVolumeM3() + operationVolumeM3 : prev.currentVolumeM3 + operationVolumeM3;
          const isLoadKgExceeded = this.truck.maximumLoadingCapacityKg < currentWeightKg;

          return [
              ...acum,
              ...[
                {
                  ...op,
                  i: sameLocation ? prev.i : idx,
                  currentWeightKg,
                  currentVolumeM3,
                  isLoadKgExceeded
                }
              ]
            ];
        }, []);
      }

    return this.cachedIndexedOperations;
  }

  filterIndexedOperations(action: ActionKind): Omit<OrderOperationWithOrder, 'merge'>[] {
    if (!action) return this.indexedOperations();

    return this.indexedOperations().filter((op) => op.action === action);
  }

  private cacheGroupedOperations: GroupedOperation[] | undefined = undefined;

  groupedOperations(): GroupedOperation[] {
    if (!this.cacheGroupedOperations) {
      this.cacheGroupedOperations = Array
        .from(
          new Set(
            this
              .indexedOperations()
              .map((op) => op.i)
          )
        )
        .map((index, idx) => {
          const operations: Omit<OrderOperationWithOrder, 'merge'>[] = this.indexedOperations().filter((op) => op.i === index);
          const filterOperations = (action: ActionKind) => {
            const ops = (!action) ? operations : operations.filter((op) => op.action === action);
            return ops.sort((a, b) => {
              if (a.order.code < b.order.code) {
                return -1;
              }
              if (a.order.code > b.order.code) {
                return 1;
              }
              if (a.order.shipperName < b.order.shipperName) {
                return -1;
              }
              if (a.order.shipperName > b.order.shipperName) {
                return 1;
              }
              return 0;
            });
          };
          const totalWeightKg = (action: ActionKind) => {
            const filtered = filterOperations(action).map((op) => op.order);

            const sum = filtered.reduce((prev, current) => prev + current.itemTotalWeightKg, 0);

            return Math.round(sum * 1000) / 1000;
          };
          const totalVolume = (action: ActionKind) => {
            const filtered = filterOperations(action).map((op) => op.order);

            const sum = filtered.reduce((prev, current) => {
              if (!current.itemTotalVolumeM3) return prev;

              return prev + current.itemTotalVolumeM3;
            }, 0);

            return Math.round(sum * 1000) / 1000;
          };
          const totalItemsCount = (action: ActionKind) => {
            const filtered = filterOperations(action).map((op) => op.order);

            return filtered.reduce((prev, current) => prev + current.itemCount, 0);
          };
          const { lat } = operations[0];
          const { lng } = operations[0];
          const { address } = operations[0];
          const { arrivalAt } = operations[0];
          const { departureAt } = operations.slice(-1)[0];
          const firstOperationName = operations.length < 2 ? `${operations[0].placeName}` : `${operations[0].placeName} 他${operations.length - 1}件`;
          const arrivalDeadlineAts: Date[] = operations.map((op) => op.order.arrivalDeadlineAt(op.action));
          const minArrivalDeadlineAt: Date = arrayUtil.minAt(arrivalDeadlineAts);

          return {
            filterOperations,
            totalWeightKg,
            totalVolume,
            totalItemsCount,
            firstOperationName,
            lat,
            lng,
            address,
            arrivalAt,
            departureAt,
            operations,
            minArrivalDeadlineAt,
            idx: idx + 1
          };
        });
    }
    return this.cacheGroupedOperations;
  }

  toSearchKw() {
    return [
      this.truck.toSearchKw(),
      this.garage.toSearchKw(),
      this.orders.map((order) => order.toSearchKw()),
      this.startAt,
      this.endAt
    ].join(' ');
  }

  markers(): { operations: Omit<OrderOperationWithOrder, 'merge'>[]; lng: number; lat: number, idx: number }[] {
    const operationMarkers = this
      .groupedOperations()
      .map(({ lat, lng, operations }) => ({
        lat,
        lng,
        operations
      }));

    if (!operationMarkers.length) return [];

    const map = new Map(
      [
        { lat: this.garage.lat, lng: this.garage.lng, operations: [] },
        ...operationMarkers
      ].map((geo) => [geo.lat, geo])
    ).values();

    const uniqueMarkers = Array.from(map);

    return uniqueMarkers.map((marker, idx) => ({ ...marker, idx }));
  }

  polylines(): CurrentAndNextPolyline[] {
    const operationMarkers: {
      operations: Omit<OrderOperationWithOrder, 'merge'>[];
      firstOperationName: string;
      arrivalAt: Date;
      departureAt: Date;
      minArrivalDeadlineAt: Date;
      lng: number;
      lat: number;
    }[] = this
      .groupedOperations()
      .map(({
              lat,
              lng,
              firstOperationName,
              arrivalAt,
              departureAt,
              operations,
              minArrivalDeadlineAt
            }) => ({
          lat,
          lng,
          arrivalAt,
          departureAt,
          firstOperationName,
          operations,
          minArrivalDeadlineAt
        }));

    if (!operationMarkers.length) return [];

    const departure: {
      operations: Omit<OrderOperationWithOrder, 'merge'>[];
      firstOperationName: string;
      arrivalAt: Date;
      departureAt: Date;
      minArrivalDeadlineAt: Date;
      lng: number;
      lat: number;
    } = {
      lat: this.garage.lat,
      lng: this.garage.lng,
      firstOperationName: this.garage.name,
      arrivalAt: this.departureOperation.arrivalAt,
      departureAt: this.departureOperation.departureAt,
      minArrivalDeadlineAt: this.startAt,
      operations: []
    };

    const arrival: {
      operations: Omit<OrderOperationWithOrder, 'merge'>[];
      firstOperationName: string;
      arrivalAt: Date;
      departureAt: Date;
      minArrivalDeadlineAt: Date;
      lng: number;
      lat: number;
    } = {
      lat: this.garage.lat,
      lng: this.garage.lng,
      firstOperationName: this.garage.name,
      arrivalAt: this.arrivalOperation.arrivalAt,
      departureAt: this.arrivalOperation.departureAt,
      minArrivalDeadlineAt: this.endAt,
      operations: []
    };

    const geocodes: {
      operations: Omit<OrderOperationWithOrder, 'merge'>[];
      firstOperationName: string;
      arrivalAt: Date;
      departureAt: Date;
      minArrivalDeadlineAt: Date;
      lng: number;
      lat: number;
    }[] = [
      departure,
      ...operationMarkers,
      arrival,
    ];

    const geocodesWithoutLast = geocodes.slice(0, -1);

    return geocodesWithoutLast.map((geocode, idx) => {
      const nextGeocode = geocodes[idx + 1];

      return {
        operations: geocode.operations,
        currentName: geocode.firstOperationName,
        currentLat: geocode.lat,
        currentLng: geocode.lng,
        currentDepartureAt: geocode.departureAt,
        currentArrivalAt: geocode.arrivalAt,
        currentMinArrivalDeadlineAt: geocode.minArrivalDeadlineAt,
        nextName: nextGeocode.firstOperationName,
        nextLat: nextGeocode.lat,
        nextLng: nextGeocode.lng,
        nextDepartureAt: nextGeocode.departureAt,
        nextArrivalAt: nextGeocode.arrivalAt,
        nextMinArrivalDeadlineAt: nextGeocode.minArrivalDeadlineAt
      };
    });
  }

  overloaded(): boolean {
    return this.indexedOperations()
      .flatMap((op) => op.isLoadKgExceeded)
      .some((bool) => bool);
  }

  overtime(): boolean {
    return this.groupedOperations().flatMap((gOp) => gOp.operations).some((op) => {
      if (op.arrivalAt < this.startAt) return true;
      if (op.departureAt < this.startAt) return true;
      if (op.arrivalAt > this.endAt) return true;
      if (op.departureAt > this.endAt) return true;

      return false;
    });
  }

  totalDistanceMm(): number {
    if (!this.orderOperations.length) return 0;

    return [
      ...this.orderOperations,
      this.arrivalOperation
    ].filter((maybe) => !!maybe)
      .map((op) => op.drivingDistanceMm)
      .reduce((prev, current) => prev + current, 0);
  }

  totalDistanceRoundKm(): number {
    return Math.round((this.totalDistanceMm() || 0) / 1000000);
  }

  workingMinutes(): number {
    if (!this.orderOperations.length) return 0;
    if (![this.departureOperation, this.arrivalOperation].every((maybe) => maybe)) return 0;
    const startAt: Date = this.departureOperation.arrivalAt;
    const endAt: Date = this.arrivalOperation.departureAt;

    const diff = startAt.getTime() - endAt.getTime();

    return Math.abs(diff) / (60 * 1000);
  }

  roundedWorkingMinutes(): number {
    return Math.round(this.workingMinutes());
  }

  driverCostYenPerMinutes(): number {
    if (!this.driverCostYenPerHours) return 0;

    return this.driverCostYenPerHours / 60;
  }

  totalDriverCostYen(): number {
    if (![this.workingMinutes(), this.driverCostYenPerMinutes()].every((maybe) => maybe)) return 0;

    return Math.round(this.workingMinutes() * this.driverCostYenPerMinutes());
  }

  truckFuelCostYenPerMm(): number {
    if (!this.truckFuelCostYenPerKm) return 0;

    return this.truckFuelCostYenPerKm / 1000000;
  }

  totalOperationDistance(): number {
    if (!this.orderOperations.length) return 0;

    return [
      ...this.orderOperations,
      this.arrivalOperation
    ].filter((maybe) => maybe)
      .map((op) => op.drivingDistanceMm)
      .filter((maybe) => maybe)
      .reduce((prev, current) => prev + current, 0);
  }

  totalTruckFuelCostYen(): number {
    return Math.round(this.truckFuelCostYenPerMm() * this.totalOperationDistance());
  }

  truckExpresswayFeeYen(): number {
    return this.hasOperations() ? this.truckExpresswayFeeYenPerShift : 0;
  }

  totalCostYen(): number {
    return [
      this.totalTruckFuelCostYen(),
      this.totalDriverCostYen(),
      this.truckInsuranceFeeYenPerDay,
      this.truckRepairCostYenPerDay,
      this.truckExpresswayFeeYen()
    ].filter((maybe) => maybe)
      .reduce((prev, current) => prev + current, 0);
  }

  totalEarningsYen(): number {
    return this.filterOrderOperations('降')
      .map((op) => op.orderId)
      .map((oId) => this.orders.find((order) => order.id === oId))
      .filter((maybe) => !!maybe)
      .map((order) => order.totalEarningsYen())
      .filter((maybe) => maybe)
      .reduce((prev, current) => prev + current, 0);
  }

  averageEarningsYen(): number {
    return this.totalEarningsYen() / this.totalOrdersCount();
  }

  roundedAverageEarningsYen(): number {
    return Math.round(this.averageEarningsYen()) || 0;
  }

  totalProfits(): number {
    return this.totalEarningsYen() - this.totalCostYen();
  }

  hasOperations(): boolean {
    return !!this.orderOperations.length;
  }
}

/**
 * - 勤務(ShiftWithCycleModel)
 *   - 回転(OperationCycle)
 *     - 地点(Place)
 *       - 作業時間帯(PlaceOperationWindow)
 *         - 作業(Operation)
 */
export class ShiftWithCycleModel {
  entity: ShiftEntity;

  operationCycles: OperationCycle[];

  orders: OrderModel[];

  truck: TruckModel;

  driver: DriverModel;

  garage: GarageModel;

  departureOperation: DepartureOperationModel;

  private orderOperations: OrderOperationWithOrder[];

  arrivalOperation: ArrivalOperationModel;

  constructor(entity: ShiftEntity) {
    this.entity = entity;
    this.orders = entity.orders.reduce((acc, order) => {
      if (acc.findIndex((it) => it.id === order.id) >= 0) {
        return acc;
      }
      acc.push(order);
      return acc;
    }, new Array<OrderEntity>()).map((order: OrderEntity) => new OrderModel(order));
    this.truck = new TruckModel(entity.truck);
    this.driver = new DriverModel(entity.driver);
    this.garage = new GarageModel(entity.garage);
    if (entity.departure_operation) this.departureOperation = new DepartureOperationModel(entity.departure_operation);
    this.orderOperations = entity.order_operations.map((oOp: OrderOperationEntity) => new OrderOperationModel(oOp).merge(this.orders));
    if (entity.arrival_operation) this.arrivalOperation = new ArrivalOperationModel(entity.arrival_operation);

    if (this.orderOperations.length === 0) {
      this.operationCycles = [];
      return;
    }

    const splitted: OrderOperationWithOrder[][] = [];
    let subArray: OrderOperationWithOrder[] = [];
    let prevAction = this.orderOperations[0].action;
    this.orderOperations.forEach((it) => {
      if (prevAction === '降' && it.action === '積') {
        splitted.push(subArray);
        subArray = [];
      }
      subArray.push(it);
      prevAction = it.action;
    });
    if (subArray.length > 0) {
      splitted.push(subArray);
    }

    this.operationCycles = splitted.map((it) => new OperationCycle(this, it));
  }

  startAt(): Date {
    return new Date(this.entity.start_at);
  }

  endAt(): Date {
    return new Date(this.entity.end_at);
  }

  driverName(): string {
    return this.driver.name;
  }

  driverPhoneNumber(): string {
    return this.driver.phoneNumber;
  }

  licensePlateValue(): string {
    return this.truck.licensePlateValue;
  }

  maximumLoadingCapacityKg() {
    return this.truck.maximumLoadingCapacityKg;
  }

  loadingPlatformVolumeM3() {
    return this.truck.loadingPlatformVolumeM3;
  }

  placeCount(action: ActionKind): number {
    return this.operationCycles.reduce((acc, it) => acc + it.placeCount(action), 0);
  }

  operationCount(action: ActionKind): number {
    return this.operationCycles.reduce((acc, it) => acc + it.operationCount(action), 0);
  }

  itemCount(action: ActionKind) {
    return this.operationCycles.reduce((acc, it) => acc + it.itemCount(action), 0);
  }

  totalWeightKg(action: ActionKind) {
    return this.operationCycles.reduce((acc, it) => acc + it.itemWeightKg(action), 0);
  }

  totalVolume(action: ActionKind) {
    return this.operationCycles.reduce((acc, it) => acc + it.itemVolumeM3(action), 0);
  }

  initialOrStockedOrders(): OrderModel[] {
    return this
      .orders
      .filter(({ id }) => this.orderOperations.filter(({ orderId }) => orderId === id).length === 1);
  }

  initialStockOrders(): OrderModel[] {
    return this
      .initialOrStockedOrders()
      .filter((order) => this.orderOperations.find(({ orderId }) => order.id === orderId).action === '降');
  }

  finalStockOrders(): OrderModel[] {
    return this
      .initialOrStockedOrders()
      .filter((order) => this.orderOperations.find(({ orderId }) => order.id === orderId).action === '積');
  }

  maximumLoadingWeight() {
    if (this.orderOperations.length === 0) return 0;

    const initialWeight = this.initialStockOrders().reduce((acc, it) => acc + it.itemTotalWeightKg, 0);
    let max = initialWeight;
    let prev = initialWeight;
    this.orderOperations.forEach((it) => {
      const order = this.orders.find(({ id }) => it.orderId === id);
      const weight = order.itemTotalWeightKg * (it.action === '積' ? 1 : -1);
      const current = prev + weight;
      max = Math.max(max, current);
      prev = current;
    });
    return max;
  }

  loadingWeightRate() {
    return Math.round((this.maximumLoadingWeight() / this.maximumLoadingCapacityKg()) * 100);
 }

  loadingWeightRateText() {
    return `${this.loadingWeightRate()}%`;
  }

  maximumLoadingVolumeM3() {
    if (this.orderOperations.length === 0) return 0;

    const initialVolumeM3 = this.initialStockOrders().reduce((acc, it) => acc + it.itemTotalVolumeM3 || 0, 0);
    let max = initialVolumeM3;
    let prev = initialVolumeM3;
    this.orderOperations.forEach((it) => {
      const order = this.orders.find(({ id }) => it.orderId === id);
      const volume = (order.itemTotalVolumeM3 || 0) * (it.action === '積' ? 1 : -1);
      const current = prev + volume;
      max = Math.max(max, current);
      prev = current;
    });
    return max;
  }

  loadingVolumeRate() {
    return Math.round((this.maximumLoadingVolumeM3() / this.loadingPlatformVolumeM3()) * 100);
  }

  loadingVolumeRateText() {
    return (this.maximumLoadingVolumeM3() && this.loadingPlatformVolumeM3()) ? `${this.loadingVolumeRate()}%` : '-%';
  }

  workPlanMinutes() {
    return (this.endAt().getTime() - this.startAt().getTime()) / (60 * 1000);
  }

  workingMinutes(): number {
    if (!this.orderOperations.length) return 0;
    if (![this.departureOperation, this.arrivalOperation].every((maybe) => maybe)) return 0;
    const startAt: Date = this.departureOperation.arrivalAt;
    const endAt: Date = this.arrivalOperation.departureAt;

    const diff = startAt.getTime() - endAt.getTime();

    return Math.abs(diff) / (60 * 1000);
  }

  utilizationRateText() {
    const denominator = this.entity.working_available_duration_hours ? Number(this.entity.working_available_duration_hours) * 60 : this.workPlanMinutes();
    return `${Math.round((this.workingMinutes() / denominator) * 100)}%`;
  }

  cycleCountText() {
    return `${this.operationCycles.length}回転`;
  }

  totalDistanceMm(): number {
    if (!this.orderOperations.length) return 0;

    return [
      ...this.orderOperations.map((it) => it.drivingDistanceMm),
      this.arrivalOperation.drivingDistanceMm
    ].filter((maybe) => !!maybe)
      .reduce((prev, current) => prev + current, 0);
  }

  totalDistanceRoundKm(): number {
    return Math.round((this.totalDistanceMm() || 0) / 1000000);
  }

  allPlaces(action: ActionKind) {
    return this.operationCycles.flatMap((it) => it.places).filter((it) => it.isAction(action));
  }
}

/**
 * - 勤務(ShiftWithCycleModel)
 *   - 回転(OperationCycle)
 *     - 地点(Place)
 *       - 作業時間帯(PlaceOperationWindow)
 *         - 作業(Operation)
 */
export class OperationCycle {
  parent: ShiftWithCycleModel;

  private orderOperations: OrderOperationWithOrder[];

  places: Place[];

  constructor(parent: ShiftWithCycleModel, orderOperations: OrderOperationWithOrder[]) {
    this.parent = parent;
    this.orderOperations = orderOperations;
    this.places = [];

    const map = this.parent.entity.orders.reduce((acc, order) => acc.set(order.id, order), new Map<number, OrderEntity>());

    let index = 0;
    let prevLatLng = null;
    const grouped = this.orderOperations.reduce((acc, ops) => {
      const order = map.get(ops.orderId);
      const latlng = ops.action === '積' ? `${order.loading_lat}-${order.loading_lng}` : `${order.unloading_lat}-${order.unloading_lng}`;
      if (latlng !== prevLatLng) {
        index += 1;
        prevLatLng = latlng;
      }

      const latLngWithIndex = `${latlng}-${index}`;
      const arr = acc.get(latLngWithIndex) || [];
      arr.push(ops);
      acc.set(latLngWithIndex, arr);
      return acc;
    }, new Map<string, OrderOperationWithOrder[]>());

    grouped.forEach((operations, key) => {
      this.places.push(new Place(this, key, operations));
    });
  }

  operationCount(action: ActionKind): number {
    return this.places.reduce((acc, it) => acc + it.operationCount(action), 0);
  }

  placeCount(action: ActionKind): number {
    return this.places.filter((it) => it.isAction(action)).length;
  }

  itemCount(action: ActionKind): number {
    return this.places.reduce((acc, it) => acc + it.itemCount(action), 0);
  }

  itemWeightKg(action: ActionKind): number {
    return this.places.reduce((acc, it) => acc + it.itemWeightKg(action), 0);
  }

  itemVolumeM3(action: ActionKind): number {
    return this.places.reduce((acc, it) => acc + it.itemVolumeM3(action), 0);
  }
}

/**
 * - 勤務(ShiftWithCycleModel)
 *   - 回転(OperationCycle)
 *     - 地点(Place)
 *       - 作業時間帯(PlaceOperationWindow)
 *         - 作業(Operation)
 */
export class Place {
  parent: OperationCycle;

  latlng: string;

  private orderOperations: OrderOperationWithOrder[];

  placeOperationWindows: PlaceOperationWindow[];

  constructor(parent: OperationCycle, latlng: string, orderOperations: OrderOperationWithOrder[]) {
    this.parent = parent;
    this.latlng = latlng;
    this.orderOperations = orderOperations || [];
    this.placeOperationWindows = [];

    // 11:00-12:00, 12:00-12:00, 12:00-12:00, 13:00-14:00
    const grouped = orderOperations.reduce((acc, ops) => {
      const arr = acc.get(ops.departureAt.toISOString()) || [];
      arr.push(ops);
      acc.set(ops.departureAt.toISOString(), arr);
      return acc;
    }, new Map<string, OrderOperationWithOrder[]>());

    grouped.forEach((operations, key) => {
      this.placeOperationWindows.push(new PlaceOperationWindow(this, operations));
    });
  }

  isAction(action: ActionKind) {
    return action === null || this.orderOperations.some((it) => it.action === action);
  }

  operationCount(action: ActionKind): number {
    return this.placeOperationWindows.reduce((acc, it) => acc + it.operationCount(action), 0);
  }

  itemCount(action: ActionKind): number {
    return this.placeOperationWindows.reduce((acc, it) => acc + it.itemCount(action), 0);
  }

  itemWeightKg(action: ActionKind): number {
    return this.placeOperationWindows.reduce((acc, it) => acc + it.itemWeightKg(action), 0);
  }

  itemVolumeM3(action: ActionKind): number {
    return this.placeOperationWindows.reduce((acc, it) => acc + it.itemVolumeM3(action), 0);
  }

  allOperations(action: ActionKind) {
    return this.placeOperationWindows.flatMap((it) => it.allOperations(action));
  }

  arrivalAt() {
    return this.orderOperations.reduce((acc: Date, it) => {
      if (acc === null) {
        return it.arrivalAt;
      }
      return acc.getTime() < it.arrivalAt.getTime() ? acc : it.arrivalAt;
    }, null);
  }

  departureAt() {
    return this.orderOperations.reduce((acc: Date, it) => {
      if (acc === null) {
        return it.departureAt;
      }
      return acc.getTime() > it.departureAt.getTime() ? acc : it.departureAt;
    }, null);
  }
}

export class PlaceOperationWindow {
  parent: Place;

  private orderOperations: OrderOperationWithOrder[];

  operations: Operation[];

  constructor(parent: Place, orderOperations: OrderOperationWithOrder[]) {
    this.parent = parent;
    this.orderOperations = orderOperations;
    this.operations = [];

    orderOperations.forEach((it) => {
      this.operations.push(new Operation(this, it));
    });
  }

  operationCount(action: ActionKind): number {
    return this.operations.reduce((acc, it) => acc + it.operationCount(action), 0);
  }

  itemCount(action: ActionKind): number {
    return this.operations.reduce((acc, it) => acc + it.itemCount(action), 0);
  }

  itemWeightKg(action: ActionKind): number {
    return this.operations.reduce((acc, it) => acc + it.itemWeightKg(action), 0);
  }

  itemVolumeM3(action: ActionKind): number {
    return this.operations.reduce((acc, it) => acc + it.itemVolumeM3(action), 0);
  }

  allOperations(action: ActionKind) {
    return this.operations.filter((it) => it.isAction(action));
  }

  arrivalAt() {
    return this.orderOperations.reduce((acc: Date, it) => {
      if (acc === null) {
        return it.arrivalAt;
      }
      return acc.getTime() < it.arrivalAt.getTime() ? acc : it.arrivalAt;
    }, null);
  }

  departureAt() {
    return this.orderOperations.reduce((acc: Date, it) => {
      if (acc === null) {
        return it.departureAt;
      }
      return acc.getTime() > it.departureAt.getTime() ? acc : it.departureAt;
    }, null);
  }
}

export class Operation {
  parent: PlaceOperationWindow;

  private orderOperation: OrderOperationWithOrder;

  constructor(parent: PlaceOperationWindow, orderOperation: OrderOperationWithOrder) {
    this.parent = parent;
    this.orderOperation = orderOperation;
  }

  getID() {
    return this.orderOperation.id;
  }

  getOrderID() {
    return this.orderOperation.orderId;
  }

  isAction(action: ActionKind): boolean {
    return action === null || this.orderOperation.action === action;
  }

  operationCount(action: ActionKind): number {
    return this.isAction(action) ? 1 : 0;
  }

  itemCount(action: ActionKind): number {
    return this.isAction(action) ? this.orderOperation.order.itemCount : 0;
  }

  itemWeightKg(action: ActionKind): number {
    return this.isAction(action) ? this.orderOperation.order.itemTotalWeightKg : 0;
  }

  itemVolumeM3(action: ActionKind): number {
    return this.isAction(action) ? this.orderOperation.order.itemTotalVolumeM3 || 0 : 0;
  }

  compare(other: Operation):number {
    const thisOrder = this.orderOperation.order;
    const otherOrder = other.orderOperation.order;

    let ret = Operation.localeCompare(thisOrder.code, otherOrder.code);
    if (ret === 0) {
      ret = Operation.localeCompare(thisOrder.shipperName, otherOrder.shipperName);
    }
    if (ret === 0) {
      ret = this.orderOperation.action === '積'
            ? Operation.localeCompare(thisOrder.loadingName, otherOrder.loadingName)
            : Operation.localeCompare(thisOrder.unloadingName, otherOrder.unloadingName);
    }
    if (ret === 0) {
      ret = Operation.localeCompare(thisOrder.itemName, otherOrder.itemName);
    }
    return ret;
  }

  private static localeCompare(a: string, b: string) {
    if (!(a && b)) return 0;
    return a.localeCompare(b);
  }
}
