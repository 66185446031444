import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent, Stack, Typography
} from '@mui/material';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';
import { FC, memo, useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router';
import { fiveZeroZeroErrorMessage } from 'src/constants/messages';
import { defaultMappingsId } from 'src/contexts/ImportContext';
import LicenseContext from 'src/contexts/LicenseContext';
import { PlaceSaveConditions } from 'src/entities/orderEntity';
import useImport from 'src/hooks/useImport';

import Footer from './Footer';
import Header from './Header';

const NewImport: FC = memo(() => {
  const importContext = useImport();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const licenseContext = useContext(LicenseContext);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      importContext.setFile(acceptedFiles[0]);
    },
    [importContext]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleChange = (event: SelectChangeEvent) => {
    importContext.setSelectedRuleId(Number(event.target.value));
  };

  const onClickEditMappingRules = () => {
    navigate('/mapping_rules');
  };

  const [placeSaveCondition, setPlaceSaveCondition] = useState<PlaceSaveConditions>('unloadingPlace');

  const onChangePlaceSaveCondition = (event: SelectChangeEvent) => {
    importContext.setPlaceSaveCondition(event.target.value);
    setPlaceSaveCondition(event.target.value as PlaceSaveConditions);
  };

  const [companyName, setCompanyName] = useState<string>('');
  useEffect(() => {
    if (!licenseContext.config) return;

    if (licenseContext.config.selectable_companies?.length > 1) {
      const selectedCompany = licenseContext.config.selectable_companies.find((company) => company.id === licenseContext.config.selected_company_id);
      setCompanyName(selectedCompany?.name);
    }
  }, [licenseContext.config]);

  const onChangeUsePlaceComplement = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    localStorage.setItem('usePlaceComplement', (checked).toString());
    importContext.setUsePlaceComplement(checked);
  };

  const onChangePrioritizePlaces = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    localStorage.setItem('prioritizePlaces', (checked).toString());
    importContext.setPrioritizePlaces(checked);
  };

  useEffect(() => {
    const usePlaceComplement = localStorage.getItem('usePlaceComplement') !== 'false';
    if (importContext.usePlaceComplement !== usePlaceComplement) {
      importContext.setUsePlaceComplement(usePlaceComplement);
    }
    const prioritizePlaces = localStorage.getItem('prioritizePlaces') === 'true';
    if (importContext.prioritizePlaces !== prioritizePlaces) {
      importContext.setPrioritizePlaces(prioritizePlaces);
    }
  }, [importContext]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box>
        <Header step={1} />
      </Box>
      <Box
        flexGrow={1}
        paddingX={2}
        paddingY={2}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack
          spacing={1}
          sx={{
            width: 600
          }}
        >
          {companyName && (
            <Stack pb={2}>
              <Typography variant="body1">
                {companyName}
                のデータをインポートします。
              </Typography>
            </Stack>
          )}
          <FormControl
            fullWidth
          >
            <InputLabel>登録された変換ルールの呼び出し</InputLabel>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Select
                value={`${importContext.selectedRuleId}`}
                label="登録された変換ルールの呼び出し"
                onChange={handleChange}
                fullWidth
              >
                <MenuItem value={0}>(新規)</MenuItem>
                <MenuItem value={defaultMappingsId}>Log標準フォーマット</MenuItem>
                {importContext.mappingRules.map((rule) => (
                  <MenuItem
                    key={['mappingsRile', rule.id].join('-')}
                    value={rule.id}
                    selected={rule.id === importContext.selectedRuleId}
                  >
                    {rule.name}
                  </MenuItem>
                ))}
              </Select>
              <Button variant="contained" sx={{ whiteSpace: 'nowrap' }} onClick={onClickEditMappingRules}>
                編集
              </Button>
            </Stack>
          </FormControl>
          <Box
            {...getRootProps()}
            sx={{
              width: '100%',
              height: '100%',
              color: 'rgba(110, 119, 129, 1)',
              border: '5px dashed #E2E4E6',
              borderRadius: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 4
            }}
          >
            <input {...getInputProps()} />
            <img
              src="/static/images/import_csv.png"
              alt="import_csv"
              height={80}
            />
            <p>
              {importContext?.file ? importContext.file.name : 'アップロードするファイルをドラッグ'}
            </p>
          </Box>
          <Stack direction="column" spacing={0}>
            <FormControlLabel
              control={<Checkbox checked={importContext.usePlaceComplement} onChange={onChangeUsePlaceComplement} />}
              label="インポート時に地点マスタと紐づける"
            />
            <FormControlLabel
              control={<Checkbox disabled={!importContext.usePlaceComplement} checked={importContext.prioritizePlaces} onChange={onChangePrioritizePlaces} />}
              label="地点名称が一致する場合、地点情報を優先する"
              sx={{ ml: 2, }}
            />
          </Stack>
          <FormControl
            fullWidth
          >
            <RadioGroup
              row
              value={placeSaveCondition}
              onChange={onChangePlaceSaveCondition}
            >
              <FormControlLabel value="loadingPlace" control={<Radio />} label="地点の新規登録時、制約条件を積地に紐づけて保存する" />
              <FormControlLabel value="unloadingPlace" control={<Radio />} label="地点の新規登録時、制約条件を降地に紐づけて保存する" />
            </RadioGroup>
          </FormControl>
        </Stack>
      </Box>
      <Box>
        <Footer
          showFormatDownloadButton
          handleDownloadFormatButton={() => {
            const requestPath = '/api/v3/orders/formats';
            const responseType = 'arraybuffer';

            importContext.setIsLoading(true);
            axios.get(requestPath, { responseType })
              .then((response) => {
                const filename = 'orders.xlsx';
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                const blob = new Blob([response.data], { type: responseType });
                saveAs(blob, filename);
              }).catch((e) => {
                enqueueSnackbar(fiveZeroZeroErrorMessage);
                throw e;
              }).finally(() => { importContext.setIsLoading(false); });
          }}
          handleBackButton={() => {
            navigate(-1);
          }}
          handleNextButton={() => {
            importContext.setIsLoading(true);

            importContext.postImports()
              .then(() => {
                importContext.setStep(2);
              })
              .catch((e) => {
                enqueueSnackbar(fiveZeroZeroErrorMessage);
                throw e;
              })
              .finally(() => {
                importContext.setIsLoading(false);
              });
          }}
          disabled={!importContext.file}
        />
      </Box>
    </Box>
  );
});

export default NewImport;
