import { Button, Dialog, DialogActions, LinearProgress, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { GridColDef, jaJP } from '@mui/x-data-grid';
import {
  DataGridPremium, GridColumnResizeParams,
  GridFilterItem,
  GridFilterModel, GridSelectionModel,
  GridSortModel
} from '@mui/x-data-grid-premium';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FC, memo, useContext, useEffect, useState } from 'react';
import LicenseContext from 'src/contexts/LicenseContext';
import { GroupRequestEntity } from 'src/entities/Group.request.entity';
import { GroupEntity } from 'src/entities/groupEntity';
import { RansackPredicateEntity } from 'src/entities/RansackPredicate.entity';
import { useGroupMutation } from 'src/hooks/useGroup.mutation';
import { queryKey } from 'src/hooks/useGroups.request';
import { useTrucksRequest as usePaginationRequest } from 'src/hooks/useTrucks.request';
import operatorValueUtil from 'src/utils/operatorValue.util';
import stringUtil from 'src/utils/string.util';

import { Columns } from '../V2TrucksPresenter/Columns';

type Props = {
  open: boolean;
  onClose: () => void;
  entity?: GroupEntity;
};

const FormDialog: FC<Props> = memo((
  {
    open,
    onClose,
    entity,
  }
) => {
  const licenseContext = useContext(LicenseContext);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);
  const [groupName, setGroupName] = useState<string>('');
  const [selectedTruckIds, setSelectedTruckIds] = useState<GridSelectionModel>([]);

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(1);
  const [rowCount, setRowCount] = useState<number>(0);
  const [sortCondition, setSortCondition] = useState<string>('');
  const [orConditions, setOrConditions] = useState<RansackPredicateEntity[]>([]);
  const [andConditions, setAndConditions] = useState<RansackPredicateEntity[]>([]);
  const [searchCondition, setSearchCondition] = useState<string>('');
  const [canRequest, setCanRequest] = useState<boolean>(false);
  const [requestEntity, setRequestEntity] = useState<GroupRequestEntity | null>(null);

  const { data: requestData, isLoading: requestIsLoading } = usePaginationRequest(page, sortCondition, searchCondition);
  const { add, update } = useGroupMutation();

  const addGroup = () => {
    if (!canRequest) return;
    if (!requestEntity) return;

    add.mutate(requestEntity, {
      onSuccess: () => {
        enqueueSnackbar('グループを追加しました', { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar('グループの追加に失敗しました', { variant: 'error' });
      },
      onSettled: () => {
        queryClient
          .invalidateQueries([queryKey])
          .finally(() => {
            onClose();
          });
      }
    });
  };

  const updateGroup = () => {
    if (!canRequest) return;
    if (!requestEntity) return;

    update.mutate({
      ...requestEntity,
      id: entity.id
    }, {
      onSuccess: () => {
        enqueueSnackbar('グループを更新しました', { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar('グループの更新に失敗しました', { variant: 'error' });
      },
      onSettled: () => {
        queryClient
          .invalidateQueries([queryKey])
          .finally(() => {
            onClose();
          });
      }
    });
  };

  const onSubmit = () => {
    if (entity) {
      updateGroup();
    } else {
      addGroup();
    }
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (sortModel.length === 0) {
      setSortCondition('');
      return;
    }

    setSortCondition(
      [
        stringUtil.toSnakeCase(sortModel[0].field),
        sortModel[0].sort
      ].join('+')
    );
  };
  const onFilterChange = ((filterModel: GridFilterModel) => {
    const items = filterModel?.items;

    if (!items || items.length === 0) {
      setOrConditions([]);
      setAndConditions([]);
      return;
    }

    const ransackPredicates: RansackPredicateEntity[] = items.flatMap((item: GridFilterItem) => operatorValueUtil.convertToRansackPredicate(
      stringUtil.toSnakeCase(item.columnField),
      item.operatorValue,
      item.value as string | string[] | undefined,
      filterModel.linkOperator
    ));

    setOrConditions(ransackPredicates.filter((predicate) => predicate.link === 'or'));
    setAndConditions(ransackPredicates.filter((predicate) => predicate.link === 'and'));
  });

  const onColumnWidthChange = (params: GridColumnResizeParams) => {
    const columns = dataGridColumns.map((col) => {
      let newDefinition = { field: col.field, width: col.width };
      if (col.field === params.colDef.field) {
        newDefinition = { ...newDefinition, width: params.colDef.width };
      }
      return newDefinition;
    });
    localStorage.setItem('truck-columns-width', JSON.stringify(columns));
  };

  const dataGridColumns: GridColDef[] = Columns().filter((col) => {
    if (licenseContext?.config?.unit_used_for_calculation === '重量と体積') {
      return true;
    }

    return col.field !== 'loading_platform_volume_m3';
  });

  const onSelectionModelChange = (params: GridSelectionModel) => {
    setSelectedTruckIds(params.map((e) => Number(e)));
  };

  const haveSelectableCompanies = licenseContext?.config?.selectable_companies?.length > 1;
  const isNew = !entity;

  useEffect(() => {
    const maybeCompanyPredicateEntity: RansackPredicateEntity | null = selectedCompanyId ? {
      column: 'company_id',
      predicate: 'eq',
      value: `${selectedCompanyId}`,
      link: 'and'
    } : null;

    const modifiedAndConditions: RansackPredicateEntity[] = maybeCompanyPredicateEntity
      ? [
        maybeCompanyPredicateEntity,
        ...andConditions
      ] : andConditions;

    setSearchCondition(
      [
        operatorValueUtil.convertToQueryParams('or_conditions', orConditions),
        operatorValueUtil.convertToQueryParams('and_conditions', modifiedAndConditions),
      ].filter((maybe) => maybe).join('&')
    );
  }, [andConditions, orConditions, selectedCompanyId]);

  useEffect(() => {
    setRowCount(requestData?.totalCount || 0);
    setPageSize(requestData?.perPage || 1);
  }, [requestData]);

  useEffect(() => {
    console.log('entity', entity);
    setSelectedTruckIds([]);

    if (!entity) {
      setGroupName('');
      setSelectedCompanyId(licenseContext?.config?.selected_company_id || 0);
      return;
    }

    setSelectedCompanyId(entity.company_id || 0);
    setGroupName(entity.name);
    setSelectedTruckIds(entity.joined_truck_ids ? entity.joined_truck_ids.split(',').map((it) => Number(it)) : []);
  }, [entity, licenseContext?.config?.selected_company_id, selectedCompanyId]);

  useEffect(() => {
    const truckIds: number[] = selectedTruckIds.map((it) => Number(it));

    setRequestEntity({
      name: groupName,
      company_id: selectedCompanyId,
      truck_ids: truckIds
    });
  }, [selectedCompanyId, groupName, selectedTruckIds]);

  useEffect(() => {
    if (!requestEntity?.name) {
      setCanRequest(false);
      return;
    }

    if (!requestEntity?.company_id) {
      setCanRequest(false);
      return;
    }

    setCanRequest(true);
  }, [requestEntity]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      sx={{
        mt: 8
      }}
    >
      <Stack
        p={2}
      >
        {[haveSelectableCompanies, isNew].every((bool) => bool) && (
          <Stack
            mb={2}
          >
            <Typography
              variant="h4"
              sx={{
                mb: 1
              }}
            >
              事業所を選択
            </Typography>
            <Select
              onChange={(e) => setSelectedCompanyId(Number(e.target.value))}
              value={selectedCompanyId}
              size="small"
              disabled={!haveSelectableCompanies}
            >
              {
                licenseContext
                  .config
                  .selectable_companies
                  .map((company) => (
                    <MenuItem value={company.id} key={company.id}>{company.name}</MenuItem>
                  ))
              }
            </Select>
          </Stack>
        )}
        <Stack>
          <Typography
            variant="h4"
            sx={{
              mb: 1
            }}
          >
            グループ名
          </Typography>
          <TextField
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            size="small"
            variant="standard"
          />
        </Stack>
      </Stack>
      <Typography
        variant="h4"
        sx={{
          pt: 2,
          px: 2
        }}
      >
        トラックを選択
      </Typography>
      <DataGridPremium
        rows={requestData?.data || []}
        columns={dataGridColumns}
        pagination
        checkboxSelection
        disableRowGrouping
        initialState={{
          columns: {
            columnVisibilityModel: {
              garage_address: false,
            },
          },
        }}
        components={{
          LoadingOverlay: LinearProgress,
        }}
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        loading={requestIsLoading}
        onColumnWidthChange={(params) => onColumnWidthChange(params)}
        onSelectionModelChange={(params) => onSelectionModelChange(params)}
        disableSelectionOnClick
        sortingMode="server"
        paginationMode="server"
        filterMode="server"
        page={page}
        pageSize={pageSize}
        rowCount={rowCount}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onSortModelChange={handleSortModelChange}
        onFilterModelChange={onFilterChange}
        sx={{
          mx: 2,
          my: 1
        }}
        keepNonExistentRowsSelected
        selectionModel={selectedTruckIds}
      />
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
        >
          キャンセル
        </Button>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          disabled={!canRequest}
        >
          保存する
        </Button>
      </DialogActions>
    </Dialog>
  );
});
export default FormDialog;
