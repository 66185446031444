import { QueryClient, useInfiniteQuery, useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { ApiResponse } from 'src/constants/apiResponse';
import { ShiftEntity, ShiftRequestEntity } from 'src/entities/shiftEntity';

const getShifts = (queryClient: QueryClient, startOn: string, endOn: string, page: number) => async () => {
  const requestPath = `/api/v2/shifts?start_at_range_start_on=${startOn}&start_at_range_end_on=${endOn}&page=${page}?with_data=1`;
  const res = await axios.get<ApiResponse>(requestPath);
  const list = res.data.data;

  return list.map((entity: ShiftEntity) => {
    queryClient.setQueryData(['shifts', { id: entity.id }], entity);
    return entity;
  });
};

export const useQueryShifts = (startOn: string, endOn: string, page: number) => {
  const queryClient = useQueryClient();

  return useQuery(
    ['shifts', { startOn, endOn, page }],
    getShifts(queryClient, startOn, endOn, page),
    {
      staleTime: Infinity
    }
  );
};

const getShift = (id: number) => async () => {
  const requestPath = `/api/v2/shifts/${id}`;
  const res = await axios.get<ShiftEntity>(requestPath);
  const entity = res.data;
  return entity;
};

export const useQueryShift = (id: number) => {
  const queryClient = useQueryClient();
  return useQuery(['shifts', { id }], getShift(id), {
    staleTime: Infinity,
    enabled: Boolean(id)
  });
};

export const useQueryShiftsByIds = (ids: number[]) => {
  const queryClient = useQueryClient();
  return useQueries({
    queries: ids.map((id) => ({
      queryKey: ['shifts', { id }],
      queryFn: getShift(id),
      staleTime: Infinity,
      enabled: Boolean(id)
    })),
  });
};

export const useMutationShift = () => {
  const queryClient = useQueryClient();

  return {
    addShift: useMutation(
      (shift: ShiftRequestEntity) => axios.post('/api/v2/shifts', shift),
      {
        onSuccess: () => queryClient.invalidateQueries(['shifts'])
      }
    ),
    updateShift: useMutation(
      (shift: ShiftEntity) => axios.put(`/api/v2/shifts/${shift.id}`, shift),
      {
        onSuccess: () => queryClient.invalidateQueries(['shifts'])
      }
    ),
    deleteShift: useMutation(
      (id: number) => axios.delete(`/api/v2/shifts/${id}`),
      {
        onSuccess: () => queryClient.invalidateQueries(['shifts'])
      }
    ),
    deleteOperations: useMutation(
      (id: number) => {
        const requestPath = `/api/v2/shifts/${id}/reset`;
        const response: Promise<AxiosResponse<number[]>> = axios.delete(requestPath);
        return response;
      },
      {
        onSuccess: ((data: AxiosResponse<number[]>, variables: number) => queryClient.invalidateQueries(['shifts', { id: variables }]))
      }
    ),
    bulkResetting: useMutation(
      (deliveryIds: number[]) => {
        const requestPath = '/api/v3/shifts/bulk/resetting';
        const response: Promise<AxiosResponse<number[]>> = axios.post(requestPath, { delivery_ids: deliveryIds });
        return response;
      }
    )
  };
};

const fetchShiftIds = (queryClient: QueryClient) => async ({ pageParam = 1, queryKey }) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const on = queryKey[1] as string;
  const requestPath = `/api/v2/shifts?start_at_range_start_on=${on}&page=${pageParam}&with_data=1`;

  const response: AxiosResponse<{
    ids: number[],
    data: ShiftEntity[],
    total_pages_count: number;
  }> = await axios.get(requestPath).then((res) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const list = res.data.data as ShiftEntity[];
    list.map((entity: ShiftEntity) => {
      queryClient.setQueryData(['shifts', { id: entity.id }], entity);
      return entity;
    });
    return res;
  });

  return response.data;
};

export const useInfiniteQueryShifts = (targetDate) => {
  const queryClient = useQueryClient();
  return useInfiniteQuery(
    ['shifts', targetDate],
    fetchShiftIds(queryClient),
    {
      enabled: !!targetDate,
      staleTime: Infinity,
      getNextPageParam: (lastPage, allPages) => (allPages.length < lastPage.total_pages_count ? allPages.length + 1 : undefined),
    }
  );
};

export const clearShiftsCache = (queryClient: QueryClient) => {
  // eslint-disable-next-line no-void
  void queryClient.resetQueries({
    predicate: (query) => query.queryKey[0] === 'shifts' || query.queryKey[0] === 'unallocatedOrders' || query.queryKey[0] === 'orders',
  });
};
