import { FC, memo } from 'react';

import { PlanningsOperationEntityWithStatsEntity } from '../../../entities/PlanningsOperationEntityWithStats.entity';

import PlanningMapOperationPresenter from './PlanningMapOperation.presenter';

type Props = {
  planningOperations: PlanningsOperationEntityWithStatsEntity[];
  loadCapacityWeightG: number;
  loadCapacityVolumeMm3: number;
  selectedOrderIds: number[];
  addSelectedOrderIds: (orderIds: number[]) => void;
  removeSelectedOrderIds: (orderIds: number[]) => void;
  updateDisplayOrderId: (orderId: number) => void;
  isLoading: boolean;
  mutateRestoreSplittedOrder: (orderId: number) => void;
}

const PlanningMapOperationsPresenter: FC<Props> = memo((
  {
    planningOperations,
    loadCapacityWeightG,
    loadCapacityVolumeMm3,
    selectedOrderIds,
    addSelectedOrderIds,
    removeSelectedOrderIds,
    updateDisplayOrderId,
    isLoading,
    mutateRestoreSplittedOrder,
  }
) => (
  <>
    {planningOperations.map((operation) => (
      <PlanningMapOperationPresenter
        key={[
            'PlanningPositionPresenter',
            'PlanningOperationPresenter',
            operation.id
          ].join('-')}
        entity={operation}
        loadCapacityWeightG={loadCapacityWeightG}
        loadCapacityVolumeMm3={loadCapacityVolumeMm3}
        selectedOrderIds={selectedOrderIds}
        addSelectedOrderIds={addSelectedOrderIds}
        removeSelectedOrderIds={removeSelectedOrderIds}
        updateDisplayOrderId={updateDisplayOrderId}
        isLoading={isLoading}
        mutateRestoreSplittedOrder={mutateRestoreSplittedOrder}
      />
      ))}
  </>
  ));

export default PlanningMapOperationsPresenter;
