import { PlanningsOperationEntityWithStatsEntity } from 'src/entities/PlanningsOperationEntityWithStats.entity';
import arrayUtil from 'src/utils/array.util';

import { PlanningsOperationDelivery } from './PlanningsOperationGroup.model';

export class OperationsWithStatusModel {
  readonly entities: PlanningsOperationEntityWithStatsEntity[];

  readonly planningsOperationDelivery: PlanningsOperationDelivery;

  constructor(entities: PlanningsOperationEntityWithStatsEntity[], planningsOperationDelivery?: PlanningsOperationDelivery) {
    this.entities = entities;

    this.planningsOperationDelivery = planningsOperationDelivery;
  }

  private static getName(ops: PlanningsOperationEntityWithStatsEntity) {
    return ops.action === '積' ? ops.loadingName : ops.unloadingName;
  }

  groupByIdx(): PlanningsOperationEntityWithStatsEntity[][] {
    if (this.planningsOperationDelivery) {
      let index = 0;
      const ret: PlanningsOperationEntityWithStatsEntity[][] = [];
      this.planningsOperationDelivery.cycles.forEach((cycle) => {
        cycle.places.forEach((place) => {
          index++;
          const placeOperations: PlanningsOperationEntityWithStatsEntity[] = [];
          const timeWindows = place.timeWindows.sort((a, b) => new Date(a.arrivalAt).getTime() - new Date(b.arrivalAt).getTime());
          timeWindows.forEach((timeWindow) => {
            const sortByArrivalAtAndName = timeWindow.operations.sort((a, b) => {
              let comp = new Date(a.arrivalAt).getTime() - new Date(b.arrivalAt).getTime();
              if (comp === 0) {
                comp = OperationsWithStatusModel.getName(a).localeCompare(OperationsWithStatusModel.getName(b));
              }
              return comp;
            });
            placeOperations.push(...sortByArrivalAtAndName);
          });
          const firstOperation = placeOperations.find((it) => it.arrivalAt === place.arrivalAt);
          const firstName = OperationsWithStatusModel.getName(firstOperation);
          const sortByArrivalAtAndName = placeOperations.sort((a, b) => {
            const comp = new Date(a.arrivalAt).getTime() - new Date(b.arrivalAt).getTime();
            if (comp !== 0) return comp;

            // 1件目と同じ名前のものを先にする
            if (OperationsWithStatusModel.getName(a) === firstName) {
              return -1;
            }
            if (OperationsWithStatusModel.getName(b) === firstName) {
              return 1;
            }
            // 1件目と同じ名前以外は名前順
            return OperationsWithStatusModel.getName(a).localeCompare(OperationsWithStatusModel.getName(b));
          });

          if (this.planningsOperationDelivery.deliveryListGrouping === 'groupByName') {
            // 同じ場所のオーダーを名前で分割する。
            let arry: PlanningsOperationEntityWithStatsEntity[] = [];
            let prevName = OperationsWithStatusModel.getName(sortByArrivalAtAndName[0]);
            sortByArrivalAtAndName.forEach((ops) => {
              if (OperationsWithStatusModel.getName(ops) !== prevName) {
                arry.forEach((it) => it.idx = index);
                ret.push(arry);
                index++;
                arry = [];
              }
              prevName = OperationsWithStatusModel.getName(ops);
              arry.push(ops);
            });
            if (arry.length > 0) {
              arry.forEach((it) => it.idx = index);
              ret.push(arry);
            }
          } else {
            placeOperations.forEach((it) => it.idx = index);
            ret.push(placeOperations);
          }
        });
      });
      return ret;
    }

    const groups: { [key: number]: PlanningsOperationEntityWithStatsEntity[] } = {};

    for (let i = 0; i < this.entities.length; i++) {
      const entity = this.entities[i];
      const index = entity.idx;
      if (!groups[index]) {
        groups[index] = [];
      }
      groups[index].push(entity);
    }

    return Object.keys(groups)
      .sort((a, b) => Number(a) - Number(b))
      .map((key) => groups[Number(key)]);
  }

  orderCount(): number {
    return arrayUtil.uniq<number>(this.entities.map((it) => it.orderId)).length;
  }

  shuttleCount(): number {
    const unloadIdxes = arrayUtil.uniq<number>(
      this.entities
        .filter((entity) => entity.idx)
        .map((entity) => entity.idx)
    );

    return unloadIdxes.filter((idx) => {
      const prevOperations = this.entities.filter((it) => it.idx === idx - 1);
      const prevPositionActions = prevOperations.map((it) => it.action);

      return prevPositionActions.includes('積');
    }).length;
  }

  maximumLoadingWeightG(): number {
    if (!this.entities.length) return 0;

    return Math.max(
      ...this.entities.map((it) => it.currentWeightG)
    );
  }

  maximumLoadingVolumeMm3(): number {
    if (!this.entities.length) return 0;
    const volumes = this.entities.map((it) => it.currentVolumeMm3).filter((maybe) => maybe);
    if (!volumes.length) return 0;
    return Math.max(...volumes);
  }

  weightUtilizationRate(loadCapacityWeightG: number): number {
    if (![this.maximumLoadingWeightG(), loadCapacityWeightG].every((maybe) => maybe)) return 0;

    return this.maximumLoadingWeightG() / loadCapacityWeightG;
  }

  volumeUtilizationRate(loadCapacityVolumeMm3: number): number {
    if (![this.maximumLoadingVolumeMm3(), loadCapacityVolumeMm3].every((maybe) => maybe)) return 0;

    return this.maximumLoadingVolumeMm3() / loadCapacityVolumeMm3;
  }

  totalLoadWeightG(): number {
    return this.entities
      .filter((it) => it.action === '積')
      .map((it) => it.operationWeightG)
      .reduce((prev, current) => prev + current, 0);
  }

  totalLoadVolumeMm3(): number {
    return this.entities
      .filter((it) => it.action === '積')
      .map((it) => it.operationVolumeMm3)
      .filter((maybe) => maybe)
      .reduce((prev, current) => prev + current, 0);
  }

  totalItemCount(): number {
    return this.entities
      .filter((it) => it.action === '積')
      .map((it) => it.itemCount)
      .reduce((prev, current) => prev + current, 0);
  }

  totalLoadPositionsCount(): number {
    return this.totalPositionsCount('積');
  }

  totalLoadOperationCount(): number {
    return this.totalOperationCount('積');
  }

  totalUnloadPositionsCount(): number {
    return this.totalPositionsCount('降');
  }

  totalUnloadOperationCount(): number {
    return this.totalOperationCount('降');
  }

  private totalPositionsCount(action: '積' | '降') {
    return this.groupByIdx()
      .filter(
        (operations) => operations
          .filter((operation) => operation.action === action)
          .length
      ).length;
  }

  private totalOperationCount(action: '積' | '降'): number {
    return this.entities.filter((entity) => entity.action === action).length;
  }
}
