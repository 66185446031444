import { FC, memo } from 'react';
import { EditPlaceEntity } from 'src/entities/EditPlace.entity';
import { SelectedCycle } from 'src/entities/SelectedCycle.entity';

import { PlanningsDeliveryEntity } from '../../../entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from '../../../entities/PlanningsDriver.entity';
import { PlanningsMapOperationPositionEntity } from '../../../entities/PlanningsMapOperationPosition.entity';
import {
  PlanningsOperationDeliveryByDeliveryIdEntity
} from '../../../entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningsTruckEntity } from '../../../entities/PlanningsTruck.entity';
import { TransferRequestEntity } from '../../../entities/transferRequestEntity';

import PlanningMapOperationMarkerPresenter from './PlanningMapOperationMarker.presenter';

type Props = {
  entities: PlanningsMapOperationPositionEntity[];
  startOn: string;
  endOn: string;
  updateDisplayOrderId: (orderId: number) => void;
  mutateDeleteOrdersOperations: (requestOrderIds: number[]) => void;
  openTransferDialog: (entity: TransferRequestEntity) => void;
  isLoading: boolean;
  deliveryEntities: PlanningsDeliveryEntity[];
  driverEntities: PlanningsDriverEntity[];
  truckEntities: PlanningsTruckEntity[];
  selectedCycleIndexes: SelectedCycle[];
  updateSelectedCycleIndexes: (deliveryId: number, cycleIndexes: number[]) => void;
  planningsOperationDeliveryByDeliveryIdEntity: PlanningsOperationDeliveryByDeliveryIdEntity;
  editPlaces: EditPlaceEntity[];
  scrollToDeliveryElement: (deliveyrId: number) => void;
  mutateRestoreSplittedOrder: (orderId: number) => void;
}

const PlanningMapOperationMarkersPresenter: FC<Props> = memo((
  {
    entities,
    startOn,
    endOn,
    updateDisplayOrderId,
    mutateDeleteOrdersOperations,
    openTransferDialog,
    isLoading,
    deliveryEntities,
    driverEntities,
    truckEntities,
    selectedCycleIndexes,
    updateSelectedCycleIndexes,
    planningsOperationDeliveryByDeliveryIdEntity,
    editPlaces,
    scrollToDeliveryElement,
    mutateRestoreSplittedOrder,
  }
) => (
  <>
    {entities.map((entity) => {
      const driverIds = deliveryEntities.filter((it) => entity.deliveryIds.includes(it.id)).map((it) => it.driverId);
      const driverEntitiesOnPosition = driverIds.map((id) => driverEntities.find((it) => it.id === id));
      const truckIds = deliveryEntities.map((it) => it.truckId);
      const trucks = truckIds.map((id) => truckEntities.find((it) => it.id === id));

      return (
        <PlanningMapOperationMarkerPresenter
          key={[
            'PlanningMapGarageMarkersPresenter',
            'PlanningMapOperationMarkerPresenter',
            ...entity.position,
            ...entity.deliveryIds
          ].join('-')}
          position={entity.position}
          driverEntitiesOnPosition={driverEntitiesOnPosition}
          startOn={startOn}
          endOn={endOn}
          mutateDeleteOrdersOperations={mutateDeleteOrdersOperations}
          openTransferDialog={openTransferDialog}
          updateDisplayOrderId={updateDisplayOrderId}
          isLoading={isLoading}
          deliveryEntitiesOnPosition={deliveryEntities.filter((it) => entity.deliveryIds.includes(it.id))}
          truckEntities={trucks}
          driverEntities={driverEntities}
          deliveryEntities={deliveryEntities}
          selectedCycleIndexes={selectedCycleIndexes}
          updateSelectedCycleIndexes={updateSelectedCycleIndexes}
          planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
          editPlaces={editPlaces}
          scrollToDeliveryElement={scrollToDeliveryElement}
          mutateRestoreSplittedOrder={mutateRestoreSplittedOrder}
        />
      );
    })}
  </>
));

export default PlanningMapOperationMarkersPresenter;
