import { useTheme } from '@mui/material';
import Leaflet from 'leaflet';
import { FC, memo, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Marker } from 'react-leaflet';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import { EditPlaceEntity } from 'src/entities/EditPlace.entity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import {
  PlanningsOperationDeliveryByDeliveryIdEntity
} from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { PositionEntity } from 'src/entities/PositionEntity';
import { SelectedCycle } from 'src/entities/SelectedCycle.entity';
import { TransferRequestEntity } from 'src/entities/transferRequestEntity';
import { PlanningsOperationPlace } from 'src/models/PlanningsOperationGroup.model';

import PlanningMapOperationDetailDialog from './PlanningMapOperationDetailDialog.presenter';

type Props = {
  position: PositionEntity;
  driverEntitiesOnPosition: PlanningsDriverEntity[];
  startOn: string;
  endOn: string;
  updateDisplayOrderId: (orderId: number) => void;
  mutateDeleteOrdersOperations: (requestOrderIds: number[]) => void;
  openTransferDialog: (entity: TransferRequestEntity) => void;
  isLoading: boolean;
  deliveryEntitiesOnPosition: PlanningsDeliveryEntity[];
  truckEntities: PlanningsTruckEntity[];
  deliveryEntities: PlanningsDeliveryEntity[];
  driverEntities: PlanningsDriverEntity[];
  selectedCycleIndexes: SelectedCycle[];
  updateSelectedCycleIndexes: (deliveryId: number, cycleIndexes: number[]) => void;
  planningsOperationDeliveryByDeliveryIdEntity: PlanningsOperationDeliveryByDeliveryIdEntity;
  editPlaces: EditPlaceEntity[];
  scrollToDeliveryElement: (deliveyrId: number) => void;
  mutateRestoreSplittedOrder: (orderId: number) => void;
}

const PlanningMapOperationMarkerPresenter: FC<Props> = memo((
  {
    position,
    driverEntitiesOnPosition,
    startOn,
    endOn,
    updateDisplayOrderId,
    mutateDeleteOrdersOperations,
    openTransferDialog,
    isLoading,
    deliveryEntitiesOnPosition,
    truckEntities,
    deliveryEntities,
    driverEntities,
    selectedCycleIndexes,
    updateSelectedCycleIndexes,
    planningsOperationDeliveryByDeliveryIdEntity,
    editPlaces,
    scrollToDeliveryElement,
    mutateRestoreSplittedOrder,
  }
) => {
  const theme = useTheme();

  const [color, setColor] = useState<string | undefined>(undefined);
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [firstDelivery, setFirstDelivery] = useState<PlanningsDeliveryEntity | undefined>(undefined);
  const [deliveryIds, setDeliveryIds] = useState<number[]>([]);
  const [currentPlaces, setCurrentPlaces] = useState<PlanningsOperationPlace[]>([]);
  const [idx, setIdx] = useState<string>('');
  const selectedDeliveryIds = selectedCycleIndexes.map((it) => it.deliveryId);

  const markerHtmlStyles = `
            background-color: ${color};
            width: 1.3rem;
            height: 1/3rem;
            display: block;
            border-radius: 3rem;
            text-align: center;
            vertical-align: middle;
            display: table;
            color: #fff;`;

  const gaEvent = (onOff: 'ON' | 'OFF') => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING_MAP, button_name: `配車計画ピン ${onOff}` });
  };

  const onClick = () => {
    if (deliveryIds.some((it) => selectedDeliveryIds.includes(it))) {
      ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING_MAP, button_name: '配車計画ピン 地点ダイアログ' });
      setDialogIsOpen(true);
      return;
    }

    gaEvent('ON');
    deliveryEntitiesOnPosition.map((it) => it.id).forEach((it) => {
      const delivery = planningsOperationDeliveryByDeliveryIdEntity[it];
      if (delivery) {
        updateSelectedCycleIndexes(it, delivery.cycles.map((cycl) => cycl.cycleIndex));
      }
    });
    deliveryIds.forEach((it) => {
      scrollToDeliveryElement(it);
    });
  };
  const onContextmenu = () => {
    gaEvent('OFF');
    deliveryEntitiesOnPosition.map((it) => it.id).forEach((it) => {
      updateSelectedCycleIndexes(it, []);
    });
  };

  useEffect(() => {
    if (!deliveryEntitiesOnPosition) return;

    setDeliveryIds(
      deliveryEntitiesOnPosition.map((it) => it.id)
    );
  }, [deliveryEntitiesOnPosition]);

  useEffect(() => {
    if (!planningsOperationDeliveryByDeliveryIdEntity) return;

    const places = deliveryIds.flatMap((deliveryId) => {
      const delivery = planningsOperationDeliveryByDeliveryIdEntity[`${deliveryId}`];
      if (delivery) {
        return delivery.cycles.flatMap((cycle) => {
          if (editPlaces) {
            const editedPlace = editPlaces.find((it) => it.deliveryId === deliveryId && it.cycleIndex === cycle.cycleIndex);
            if (editedPlace) {
              return editedPlace.places;
            }
          }
          return cycle.places;
        }).filter((plc) => [plc.latitude, plc.longitude].join('-') === position.join('-'));
      }
      return null;
    }).filter((it) => it);
    setCurrentPlaces(places);
  }, [deliveryIds, editPlaces, planningsOperationDeliveryByDeliveryIdEntity, position]);

  useEffect(() => {
    if (!currentPlaces) return;

    const firstPlace = currentPlaces[0];
    if (firstPlace) {
      setIdx(`${firstPlace.seriesIndex}`);
    } else {
      setIdx('');
    }
  }, [currentPlaces]);

  useEffect(() => {
    setFirstDelivery(deliveryEntitiesOnPosition[0]);
  }, [deliveryEntitiesOnPosition]);

  useEffect(() => {
    if (!firstDelivery) {
      setColor(
        theme.colors.secondary.main
      );
      return;
    }

    setColor(
      firstDelivery.driverColor
    );
  }, [firstDelivery]);

  return (
    <>
      <Marker
        opacity={deliveryEntitiesOnPosition.map(({ id }) => id).some((it) => selectedDeliveryIds.includes(it)) ? 1 : 0.5}
        position={position}
        icon={
          Leaflet.divIcon({
            className: 'custom-pin',
            iconAnchor: [10, 10],
            popupAnchor: [0, -36],
            html: `<div style="${markerHtmlStyles}">${idx}</div>`
          })
        }
        eventHandlers={{
          click: () => {
            onClick();
          },
          contextmenu: () => {
            onContextmenu();
          }
        }}
      />
      <PlanningMapOperationDetailDialog
        position={position}
        driverEntitiesOnPosition={driverEntitiesOnPosition}
        startOn={startOn}
        endOn={endOn}
        updateDisplayOrderId={updateDisplayOrderId}
        mutateDeleteOrdersOperations={mutateDeleteOrdersOperations}
        openTransferDialog={openTransferDialog}
        isLoading={isLoading}
        deliveryEntitiesOnPosition={deliveryEntitiesOnPosition}
        truckEntities={truckEntities}
        deliveryEntities={deliveryEntities}
        driverEntities={driverEntities}
        dialogIsOpen={dialogIsOpen}
        setDialogIsOpen={setDialogIsOpen}
        editPlaces={editPlaces}
        planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
        mutateRestoreSplittedOrder={mutateRestoreSplittedOrder}
      />
    </>
  );
});

export default PlanningMapOperationMarkerPresenter;
