import * as yup from 'yup';

export const schema = yup.object({
  name: yup.string().required('必須です'),
  default_truck_id: yup.number().required('選択してください'),
  color: yup.string().required('選択してください'),
  email_address: yup.string().email('メールアドレス形式エラー').nullable(),
  shuttle_limit: yup
    .number()
    .integer('整数で入力してください')
    .positive('整数で入力してください')
    .typeError('整数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  orders_limit: yup
    .number()
    .integer('整数で入力してください')
    .positive('整数で入力してください')
    .typeError('整数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  driver_cost_yen_per_hours: yup
    .number()
    .integer('整数で入力してください')
    .positive('整数で入力してください')
    .typeError('整数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
});
