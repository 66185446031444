import { FC, memo, useContext } from 'react';
import LicenseContext from 'src/contexts/LicenseContext';
import { useQueryShiftsByIds } from 'src/hooks/useQueryShifts';
import { OrderOperationWithOrder } from 'src/models/OrderOperationModel';
import { ShiftModel } from 'src/models/ShiftModel';

import LoadingComponent from './LoadingComponent';

type Model = {
  model: ShiftModel;
  colSpan: number;
  operations: Omit<OrderOperationWithOrder, 'merge'>[];
  idx: number;
  page: number;
}

export type PresenterProps = {
  models: Model[];
  operationsLimit: number;
  action: '積' | '降' | null;
  unit: '才' | 'kg';
}

type Props = {
  shiftIds: number[];
  presenter: FC<PresenterProps>;
  action: '積' | '降' | null;
}

const TuckDirectionsComponent: FC<Props> = memo(({ action, shiftIds, presenter: Presenter }) => {
  const licenseContext = useContext(LicenseContext);
  const shiftEntities = useQueryShiftsByIds(shiftIds);

  if (shiftEntities.some((order) => order.isLoading)) {
    return <LoadingComponent />;
  }

  if (shiftEntities.some((order) => order.isError)) {
    return <>Error</>;
  }

  const shiftModels = shiftEntities.map((shiftEntity) => new ShiftModel(shiftEntity.data));

  const operationsLimit = 20;

  const analyzedModels: { colSpan: number; model: ShiftModel }[] = shiftModels.map((model) => ({
    model,
    colSpan: Math.ceil(model.filterIndexedOperations(action).length / operationsLimit)
  }));

  const columnsLimit = 8;

  const models: Model[] = analyzedModels
    .reduce((prev: Model[], current, index) => {
      const startIndexes: number[] = Array.from({ length: current.colSpan }).map((_, idx) => idx * operationsLimit);

      const prevTotalColSpan = prev.map((p) => p.idx === 0 && p.colSpan).filter((maybe) => !!maybe).reduce((p, c) => p + c, 0);
      const withOperations = startIndexes
        .map((num, idx) => (
          {
            idx,
            model: current.model,
            colSpan: current.colSpan,
            operations: current.model.filterIndexedOperations(action).slice(num, num + operationsLimit),
            page: Math.ceil((prevTotalColSpan + current.colSpan) / columnsLimit)
          }
        ));
      return [...prev, ...withOperations];
    }, []);

  const pages = Array.from(new Set(models.map((m) => m.page)));

  return (
    <>
      {pages.map((page) => {
        if (!models.filter((model) => model.page === page)) return <div />;

        return (
          <Presenter
            key={['TruckDirection', models.filter((model) => model.page === page).map((m) => m.model.id).join('-')].join('-')}
            models={models.filter((model) => model.page === page)}
            operationsLimit={operationsLimit}
            action={action}
            unit={licenseContext?.config?.unit}
          />
        );
      })}
    </>
  );
});

export default TuckDirectionsComponent;
