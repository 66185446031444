import { FC, memo, useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import {
  PlanningMapUnallocatedOrderPositionEntity
} from 'src/entities/import/PlanningMapUnallocatedOrderPosition.entity';
import { PlanningsMapGaragePositionEntity } from 'src/entities/PlanningsMapGaragePosition.entity';
import { PlanningsMapOperationPositionEntity } from 'src/entities/PlanningsMapOperationPosition.entity';
import { PositionEntity } from 'src/entities/PositionEntity';
import geocodeUnit from 'src/utils/geocode.unit';

type Props = {
  startOn: string;
  endOn: string;
  isLoading: boolean;
  planningMapUnallocatedOrderPositionEntities: PlanningMapUnallocatedOrderPositionEntity[];
  planningsMapOperationPositionEntities: PlanningsMapOperationPositionEntity[];
  planningsMapGaragePositionEntities: PlanningsMapGaragePositionEntity[];
  flyToPosition: PositionEntity | undefined;
  resetFlyToPosition: () => void;
  fitBoundsPositions: PositionEntity[];
  resetFitBoundsPositions: () => void;
}

const MapFunctionPresenter: FC<Props> = memo((
  {
    startOn,
    endOn,
    isLoading,
    planningMapUnallocatedOrderPositionEntities,
    planningsMapOperationPositionEntities,
    planningsMapGaragePositionEntities,
    flyToPosition,
    resetFlyToPosition,
    fitBoundsPositions,
    resetFitBoundsPositions,
  }
) => {
  const map = useMap();

  const [positions, setPositions] = useState<PositionEntity[]>([]);
  const [centerPosition, setCenterPosition] = useState<PositionEntity | undefined>(undefined);

  useEffect(() => {
    setCenterPosition(undefined);
  }, [startOn, endOn]);

  useEffect(() => {
    if (![planningsMapOperationPositionEntities, planningsMapGaragePositionEntities, planningMapUnallocatedOrderPositionEntities].every((maybe) => maybe)) return;

    const orderPositions = planningMapUnallocatedOrderPositionEntities.map((it) => it.position);
    const operationPositions = planningsMapOperationPositionEntities.map((it) => it.position);
    const garagePositions = planningsMapGaragePositionEntities.map((it) => it.position);

    setPositions(
      [
        ...orderPositions,
        ...operationPositions,
        ...garagePositions,
      ]
    );
  }, [isLoading, planningsMapOperationPositionEntities, planningsMapGaragePositionEntities, planningMapUnallocatedOrderPositionEntities]);

  useEffect(() => {
    if (centerPosition) return;
    if (!positions.length) return;

    setCenterPosition(geocodeUnit.findCenter(positions));
  }, [positions]);

  useEffect(() => {
    if (!centerPosition) return;

    map.flyTo(centerPosition);
  }, [centerPosition]);

  useEffect(() => {
    if (!flyToPosition) return;

    map.flyTo(flyToPosition, 14, {
      animate: false
    });
    resetFlyToPosition();
  }, [flyToPosition]);

  useEffect(() => {
    if (!fitBoundsPositions) return;
    if (!fitBoundsPositions.length) return;

    map.fitBounds(fitBoundsPositions, {
      animate: false
    });

    const currentZoom = map.getZoom();

    map.setZoom(currentZoom - 1, {
      animate: false
    });
    resetFitBoundsPositions();
  }, [fitBoundsPositions]);

  return null;
});

export default MapFunctionPresenter;
