import { Button, Dialog, DialogActions, DialogTitle, LinearProgress, Typography } from '@mui/material';
import { GridColDef, jaJP } from '@mui/x-data-grid';
import {
  DataGridPremium, GridColumnResizeParams,
  GridFilterItem,
  GridFilterModel,
  GridSelectionModel,
  GridSortModel
} from '@mui/x-data-grid-premium';
import { FC, memo, useContext, useEffect, useState } from 'react';
import LicenseContext from 'src/contexts/LicenseContext';
import { RansackPredicateEntity } from 'src/entities/RansackPredicate.entity';
import { TruckEntity } from 'src/entities/truckEntity';
import { useTrucksRequest as usePaginationRequest } from 'src/hooks/useTrucks.request';
import operatorValueUtil from 'src/utils/operatorValue.util';
import stringUtil from 'src/utils/string.util';

import { Columns } from '../V2TrucksPresenter/Columns';

type Props = {
  open: boolean;
  onClose: () => void;
  onSelect: (truckEntities: TruckEntity[]) => void;
  selectedCompanyId: number;
  currentTruckIds: number[];
};

const SelectTruckDialog: FC<Props> = memo((
  {
    open,
    onClose,
    onSelect,
    currentTruckIds,
    selectedCompanyId,
  }
) => {
  const licenseContext = useContext(LicenseContext);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(1);
  const [rowCount, setRowCount] = useState<number>(0);
  const [sortCondition, setSortCondition] = useState<string>('');
  const [orConditions, setOrConditions] = useState<RansackPredicateEntity[]>([]);
  const [andConditions, setAndConditions] = useState<RansackPredicateEntity[]>([]);
  const [searchCondition, setSearchCondition] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const { data: requestData, isLoading: requestIsLoading } = usePaginationRequest(
    0,
    sortCondition,
    searchCondition,
    Number.MAX_SAFE_INTEGER,
  );

  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (sortModel.length === 0) {
      setSortCondition('');
      return;
    }

    setSortCondition(
      [
        stringUtil.toSnakeCase(sortModel[0].field),
        sortModel[0].sort
      ].join('+')
    );
  };

  const onFilterChange = ((filterModel: GridFilterModel) => {
    const items = filterModel?.items;

    if (!items || items.length === 0) {
      setOrConditions([]);
      setAndConditions([]);
      return;
    }

    const ransackPredicates: RansackPredicateEntity[] = items.flatMap((item: GridFilterItem) => operatorValueUtil.convertToRansackPredicate(
      stringUtil.toSnakeCase(item.columnField),
      item.operatorValue,
      item.value as string | string[] | undefined,
      filterModel.linkOperator
    ));

    setOrConditions(ransackPredicates.filter((predicate) => predicate.link === 'or'));
    setAndConditions(ransackPredicates.filter((predicate) => predicate.link === 'and'));
  });

  const dataGridColumns: GridColDef[] = Columns().filter((col) => {
    if (licenseContext?.config?.unit_used_for_calculation === '重量と体積') {
      return true;
    }

    return col.field !== 'loading_platform_volume_m3';
  });

  const onSelectionModelChange = (params: GridSelectionModel) => {
    setSelectedIds(params.map((e) => Number(e)));
  };

  const maybeSelectedTrucks = requestData?.data.filter((e) => selectedIds.includes(e.id));

  const onColumnWidthChange = (params: GridColumnResizeParams) => {
    const columns = dataGridColumns.map((col) => {
      let newDefinition = { field: col.field, width: col.width };
      if (col.field === params.colDef.field) {
        newDefinition = { ...newDefinition, width: params.colDef.width };
      }
      return newDefinition;
    });
    localStorage.setItem('truck-columns-width', JSON.stringify(columns));
  };

  const onSubmit = () => {
    const selectedEntities = requestData?.data.filter((e) => selectedIds.includes(e.id)) || [];
    onSelect(selectedEntities);
    onClose();
  };

  useEffect(() => {
    const maybeCompanyPredicateEntity: RansackPredicateEntity | null = selectedCompanyId ? {
      column: 'company_id',
      predicate: 'eq',
      value: `${selectedCompanyId}`,
      link: 'and'
    } : null;

    const modifiedAndConditions: RansackPredicateEntity[] = maybeCompanyPredicateEntity
      ? [
        maybeCompanyPredicateEntity,
        ...andConditions
      ] : andConditions;

    setSearchCondition(
      [
        operatorValueUtil.convertToQueryParams('or_conditions', orConditions),
        operatorValueUtil.convertToQueryParams('and_conditions', modifiedAndConditions),
      ].filter((maybe) => maybe).join('&')
    );
  }, [andConditions, orConditions, selectedCompanyId]);

  useEffect(() => {
    setRowCount(requestData?.totalCount || 0);
    setPageSize(requestData?.perPage || 1);
  }, [requestData]);

  useEffect(() => {
    if (currentTruckIds) {
      setSelectedIds(currentTruckIds);
      return;
    }

    setSelectedIds([]);
  }, [currentTruckIds]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      sx={{
        mt: 8
      }}
    >
      <DialogTitle>
        <Typography variant="h6">
          トラックを選択してください
        </Typography>
      </DialogTitle>
      <DataGridPremium
        rows={requestData?.data || []}
        columns={dataGridColumns}
        pagination
        checkboxSelection
        disableRowGrouping
        initialState={{
          columns: {
            columnVisibilityModel: {
              garage_address: false,
            },
          },
        }}
        components={{
          LoadingOverlay: LinearProgress,
        }}
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        loading={requestIsLoading}
        onColumnWidthChange={(params) => onColumnWidthChange(params)}
        onSelectionModelChange={(params) => onSelectionModelChange(params)}
        sortingMode="server"
        paginationMode="client"
        filterMode="server"
        page={page}
        pageSize={pageSize}
        rowCount={rowCount}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onSortModelChange={handleSortModelChange}
        onFilterModelChange={onFilterChange}
        selectionModel={selectedIds}
        sx={{
          mb: 2,
          mx: 2,
        }}
        keepNonExistentRowsSelected
      />
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
        >
          キャンセル
        </Button>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
        >
          {
            (() => {
              if (!maybeSelectedTrucks) return '設定する';
              if (maybeSelectedTrucks.length === 0) return '設定する';
              if (maybeSelectedTrucks.length === 1) return `${maybeSelectedTrucks[0].license_plate_value} を設定する`;
              const lastSelectedTruck = [...maybeSelectedTrucks].slice(-1)[0];
              const selectedOtherTrucksLength = maybeSelectedTrucks.length - 1;

              return `${lastSelectedTruck.license_plate_value} 他${selectedOtherTrucksLength}台を設定する`;
            })()
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default SelectTruckDialog;
