import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { AddCircleOutlineOutlined, RemoveCircleOutlineOutlined } from '@mui/icons-material';
import CallSplitRoundedIcon from '@mui/icons-material/CallSplitRounded';
import SaveAsRoundedIcon from '@mui/icons-material/SaveAsRounded';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel,
  IconButton,
  InputLabel, MenuItem,
  Paper, Radio, RadioGroup, Select,
  SelectChangeEvent,
  Stack, Switch,
  TextField,
  Theme,
  ThemeOptions,
  Typography,
  useTheme
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { format } from 'date-fns-tz';
import { useSnackbar } from 'notistack';
import { ChangeEvent, FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useFieldArray, useForm, UseFormReturn } from 'react-hook-form';
import LicenseContext from 'src/contexts/LicenseContext';
import PlansContext from 'src/contexts/PlansContext';
import numberDecorator from 'src/decorators/number.decorator';
import { CompanyEntity } from 'src/entities/admin/companyEntity';
import {
  DesignatedTruckCarModel, DesignatedTruckFeature, DesignatedTruckFloorSpecification, DesignatedTruckKlass, DesignatedTruckLoadingPlatformHeight,
  DesignatedTruckLoadingPlatformLength, DesignatedTruckLoadingPlatformWidth, ItemHandlingOfCargoStyle,
  ItemKlass, ItemPackageStyle, LoadingDateTimeEntity, OrderEntity, OrderRequestEntity, PlaceSaveConditions, TruckDriverIdAndName
} from 'src/entities/orderEntity';
import { PlaceEntity, SpecifiedTimeEntity } from 'src/entities/placeEntity';
import { ShipperEntity } from 'src/entities/shipperEntity';
import { useDriversQuery } from 'src/hooks/useDrivers.query';
import { queryKey } from 'src/hooks/useOrders.request';
import { useQueryCompany } from 'src/hooks/useQueryCompany';
import { useMutationOrder } from 'src/hooks/useQueryOrders';
import { useQueryPlaces } from 'src/hooks/useQueryPlaces';
import { useQueryShippers } from 'src/hooks/useQueryShippers';
import { useTrucksQuery } from 'src/hooks/useTrucks.query';
import * as yup from 'yup';

import MultiCheckbox from '../MultiCheckBox';

type OrderFormProps = {
  dialogIsOpen: boolean;
  dialogOnClose: () => void;
  startOn: string;
  onClose?: () => void;
  onClickSplit?: () => void;
  entity?: OrderRequestEntity | OrderEntity;
  createAfterCallbackFnc?: () => void;
};

function validateLoadUnloadDateTimeRelation(values): boolean {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, react/no-this-in-sfc
  const loadingTimes: LoadingDateTimeEntity[] = this.from[0].value.order_loading_specified_datetimes_attributes;
  const minLoadingStartAt = loadingTimes.map((e) => (e.start_at)).reduce((a, b) => (a < b ? a : b));
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
  const maxUnloadingEndAt = values.map((e) => (e.end_at)).reduce((a, b) => (a < b ? b : a));
  return !(maxUnloadingEndAt < new Date(minLoadingStartAt));
}

const loadingDateTime = yup.object().shape({
  start_at: yup
    .date().typeError('日時を入力してください')
    .required('必須です'),
  end_at: yup
    .date().typeError('日時を入力してください')
    .min(yup.ref('start_at'), '受付開始時間より遅い時間を指定してください')
    .required('必須です')
});

function validateNoOverWrap(values: {start_at: Date, end_at: Date}[]): boolean {
  console.log(values);
  if (values.length === 1) {
    return true;
  }
  const sorted = values.sort((a, b) => a.start_at.getTime() - b.start_at.getTime());
  const results = sorted.map((it, index) => {
    const nextValue = sorted[index + 1];
    if (nextValue === undefined) return true;

    return it.end_at <= nextValue.start_at;
  });
  return results.every((it) => it);
}

const schema = yup.object({
  company_id: yup.number().required('必須です'),
  shipper_name: yup.string().required('必須です'),
  designated_truck_car_models: yup.array().of(yup.string()),
  designated_truck_features: yup.array().of(yup.string()),
  designated_truck_floor_specifications: yup.array().of(yup.string()),
  designated_truck_loading_platform_heights: yup.array().of(yup.string()),
  designated_truck_loading_platform_lengths: yup.array().of(yup.string()),
  designated_truck_loading_platform_widths: yup.array().of(yup.string()),
  designated_truck_klasses: yup.array().of(yup.string()),
  item_count: yup
    .number()
    .typeError('数字を入力してください')
    .moreThan(-1, '0以上の数値を入力してください')
    .required('必須です'),
  item_total_weight_kg: yup
    .number()
    .typeError('数字を入力してください')
    .moreThan(-1, '0以上の数値を入力してください')
    .required('必須です'),
  loading_name: yup.string().required('必須です'),
  loading_address: yup.string().required('必須です'),
  loading_staying_seconds: yup
    .number()
    .integer()
    .nullable()
    .moreThan(-1, '0以上の数値を入力してください')
    .transform((val, originalVal) => ((!!val || val === 0) ? (val * 60) : null)),
  unloading_name: yup.string().required('必須です'),
  unloading_address: yup.string().required('必須です'),
  unloading_staying_seconds: yup
    .number()
    .moreThan(-1, '0以上の数値を入力してください')
    .integer()
    .nullable()
    .transform((val, originalVal) => ((!!val || val === 0) ? (val * 60) : null)),
  order_loading_specified_datetimes_attributes: yup
    .array()
    .of(loadingDateTime)
    .test(
      'timeoverlap',
      '積地受付時間帯に重複する時間帯があります',
      validateNoOverWrap,
    ),
  order_unloading_specified_datetimes_attributes: yup
    .array()
    .of(loadingDateTime)
    .test(
      'timeoverlap',
      '降地受付時間帯に重複する時間帯があります',
      validateNoOverWrap,
    )
    .test(
      'loadUnloadRelation',
      'いずれかの降地受付終了時間は、もっとも早い積地受付開始時間よりもあとの時間を指定してください',
      validateLoadUnloadDateTimeRelation
    )
});

const OrderForm: FC<OrderFormProps> = memo(
  ({
    dialogIsOpen,
    dialogOnClose,
    onClose,
    onClickSplit,
    entity,
    startOn,
    createAfterCallbackFnc
  }) => {
    const context = useContext(PlansContext);
    const licenseContext = useContext(LicenseContext);
    const queryClient = useQueryClient();
    const theme: Theme & ThemeOptions = useTheme();

    const form: UseFormReturn<OrderEntity | OrderRequestEntity> = useForm<OrderEntity | OrderRequestEntity>({
      resolver: yupResolver(schema),
      defaultValues: {
        item_can_be_mixed: true,
        order_loading_specified_datetimes_attributes: (entity && entity.order_loading_specified_datetimes_attributes.length > 0)
          ? entity.order_loading_specified_datetimes_attributes.map((dt) => (
            {
              start_at: format(
                new Date(dt.start_at),
                "yyyy-MM-dd'T'HH:mm",
                {
                  timeZone: 'Asia/Tokyo'
                }
              ),
              end_at: format(
                new Date(dt.end_at),
                "yyyy-MM-dd'T'HH:mm",
                {
                  timeZone: 'Asia/Tokyo'
                }
              )
            }
          )) : [{
            start_at: format(
              startOfDay(new Date(startOn)),
              "yyyy-MM-dd'T'HH:mm",
              {
                timeZone: 'Asia/Tokyo'
              }
            ),
            end_at: format(
              endOfDay(new Date(startOn)),
              "yyyy-MM-dd'T'HH:mm",
              {
                timeZone: 'Asia/Tokyo'
              }
            )
          }],
        order_unloading_specified_datetimes_attributes: (entity && entity.order_unloading_specified_datetimes_attributes.length > 0)
          ? entity.order_unloading_specified_datetimes_attributes.map((dt) => (
            {
              start_at: format(
                new Date(dt.start_at),
                "yyyy-MM-dd'T'HH:mm",
                {
                  timeZone: 'Asia/Tokyo'
                }
              ),
              end_at: format(
                new Date(dt.end_at),
                "yyyy-MM-dd'T'HH:mm",
                {
                  timeZone: 'Asia/Tokyo'
                }
              )
            }
          )) : [{
            start_at: format(
              startOfDay(new Date(startOn)),
              "yyyy-MM-dd'T'HH:mm",
              {
                timeZone: 'Asia/Tokyo'
              }
            ),
            end_at: format(
              endOfDay(new Date(startOn)),
              "yyyy-MM-dd'T'HH:mm",
              {
                timeZone: 'Asia/Tokyo'
              }
            )
          }],
      }
    });

    const { formState, control, reset, register, watch, getValues, setValue } = form;
    const { errors } = formState;
    const [loadingStartTimeError, setLoadingStartTimeError] = useState<string>('');
    const [unloadingStartTimeError, setUnloadingStartTimeError] = useState<string>('');
    const [unloadingEndTimeError, setUnloadingEndTimeError] = useState<string>('');
    useEffect(() => {
      if (errors.order_loading_specified_datetimes_attributes?.type === 'timeoverlap') {
        setLoadingStartTimeError(errors.order_loading_specified_datetimes_attributes.message);
      } else {
        setLoadingStartTimeError('');
      }
      if (errors.order_unloading_specified_datetimes_attributes?.type === 'timeoverlap') {
        setUnloadingStartTimeError(errors.order_unloading_specified_datetimes_attributes.message);
      } else {
        setUnloadingStartTimeError('');
      }
      if (errors.order_unloading_specified_datetimes_attributes?.type === 'loadUnloadRelation') {
        setUnloadingEndTimeError(errors.order_unloading_specified_datetimes_attributes.message);
      } else {
        setUnloadingEndTimeError('');
      }
    }, [errors]);

    const { enqueueSnackbar } = useSnackbar();

    const [companyId, setCompanyId] = useState(0);

    const places = useQueryPlaces(dialogIsOpen);
    const shippers = useQueryShippers(dialogIsOpen);
    const drivers = useDriversQuery(startOn, { enabled: dialogIsOpen });
    const trucks = useTrucksQuery(startOn, { enabled: dialogIsOpen });
    const { data: companyData } = useQueryCompany(companyId);

    const { addOrder, updateOrder } = useMutationOrder();

    const watchFields = watch(['item_count', 'item_packing_style']);

    const [modified, setModified] = useState(false);
    const [itemPackingStyleValue, setItemPackingStyleValue] = useState<string>(null);
    const [itemKlass, setItemKlass] = useState<string>(null);
    const [itemHandlingOfCargoStyle, setItemHandlingOfCargoStyle] = useState<string>(null);
    const [itemCanBeMixed, setItemCanBeMixed] = useState<boolean>(null);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isCopy, setIsCopy] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [designatedTruckKlasses, setDesignatedTruckKlasses] = useState<DesignatedTruckKlass[]>([]);
    const [designatedTruckCarModels, setDesignatedTruckCarModels] = useState<DesignatedTruckCarModel[]>([]);
    const [designatedTruckLoadingPlatformHeights, setDesignatedTruckLoadingPlatformHeights] = useState<DesignatedTruckLoadingPlatformHeight[]>([]);
    const [designatedTruckLoadingPlatformWidths, setDesignatedTruckLoadingPlatformWidths] = useState<DesignatedTruckLoadingPlatformWidth[]>([]);
    const [designatedTruckLoadingPlatformLengths, setDesignatedTruckLoadingPlatformLengths] = useState<DesignatedTruckLoadingPlatformLength[]>([]);
    const [designatedTruckFloorSpecifications, setDesignatedTruckFloorSpecifications] = useState<DesignatedTruckFloorSpecification[]>([]);
    const [designatedTruckFeatures, setDesignatedTruckFeatures] = useState<DesignatedTruckFeature[]>([]);
    const [allowedTrucks, setAllowedTrucks] = useState<TruckDriverIdAndName[]>([]);
    const [deniedDrivers, setDeniedDrivers] = useState<TruckDriverIdAndName[]>([]);
    const [customInputFieldOne, setCustomInputFieldOne] = useState<string>('');
    const updateCustomInputFieldOne = (event: ChangeEvent<HTMLInputElement>) => {
      setCustomInputFieldOne(event.target.value);
    };
    const [customInputFieldTwo, setCustomInputFieldTwo] = useState<string>('');
    const updateCustomInputFieldTwo = (event: ChangeEvent<HTMLInputElement>) => {
      setCustomInputFieldTwo(event.target.value);
    };
    const [customInputFieldThree, setCustomInputFieldThree] = useState<string>('');
    const updateCustomInputFieldThree = (event: ChangeEvent<HTMLInputElement>) => {
      setCustomInputFieldThree(event.target.value);
    };
    const [customInputFieldFour, setCustomInputFieldFour] = useState<string>('');
    const updateCustomInputFieldFour = (event: ChangeEvent<HTMLInputElement>) => {
      setCustomInputFieldFour(event.target.value);
    };
    const [customInputFieldFive, setCustomInputFieldFive] = useState<string>('');
    const updateCustomInputFieldFive = (event: ChangeEvent<HTMLInputElement>) => {
      setCustomInputFieldFive(event.target.value);
    };

    const onChangeSetModified = (event: ChangeEvent<HTMLInputElement>) => {
      setModified(true);
      if (event.target.name === 'item_packing_style') {
        setItemPackingStyleValue(event.target.value);
      } else if (event.target.name === 'item_klass') {
        setItemKlass(event.target.value);
      } else if (event.target.name === 'item_handling_of_cargo_style') {
        setItemHandlingOfCargoStyle(event.target.value);
      } else if (event.target.name === 'item_can_be_mixed') {
        setItemCanBeMixed(event.target.checked);
      }
    };

    const onChangeCopy = (event: ChangeEvent<HTMLInputElement>) => {
      setIsCopy(event.target.checked);
    };

    const onSubmitSave: SubmitHandler<OrderRequestEntity | OrderEntity> = (
      data: OrderEntity
    ): void => {
      const mutate = data.id && !isCopy ? updateOrder : addOrder;
      const resetQueryKeys = [queryKey, 'shifts', 'shift', 'unallocatedOrders', 'order', 'shippers', 'places', 'useQueryPlanningOrderStatistics'];

      if (data.id && isCopy) {
        data.id = null;
        data.uuid = null;
      }
      data.loading_staying_minutes = (data.loading_staying_seconds !== null) ? data.loading_staying_seconds / 60 : null;
      data.unloading_staying_minutes = (data.unloading_staying_seconds !== null) ? data.unloading_staying_seconds / 60 : null;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data.item_packing_style = itemPackingStyleValue as ItemPackageStyle;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data.item_klass = itemKlass as ItemKlass;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data.item_handling_of_cargo_style = itemHandlingOfCargoStyle as ItemHandlingOfCargoStyle;
      data.item_can_be_mixed = itemCanBeMixed;

      data.designated_truck_klasses = designatedTruckKlasses;
      data.designated_truck_car_models = designatedTruckCarModels;
      data.designated_truck_loading_platform_heights = designatedTruckLoadingPlatformHeights;
      data.designated_truck_loading_platform_widths = designatedTruckLoadingPlatformWidths;
      data.designated_truck_loading_platform_lengths = designatedTruckLoadingPlatformLengths;
      data.designated_truck_floor_specifications = designatedTruckFloorSpecifications;
      data.designated_truck_features = designatedTruckFeatures;
      data.place_save_condition = placeSaveCondition;
      data.allowed_truck_ids = allowedTrucks.map((truck) => truck.id);
      data.denied_driver_ids = deniedDrivers.map((driver) => driver.id);

      const customFields = [
        customInputFieldOne,
        customInputFieldTwo,
        customInputFieldThree,
        customInputFieldFour,
        customInputFieldFive,
      ];

      if (companyData && companyData.custom_input_fields) {
        const customInputFields: {
          key: string;
          value: string;
        }[] = companyData
          .custom_input_fields
          .map((it, idx) => {
            if (!customFields[idx]) return null;

            return {
              key: it,
              value: customFields[idx]
            };
          })
          .filter((maybe) => maybe);

        data.custom_input_fields = customInputFields;
      }

      setIsLoading(true);
      context.updateIsLoading(true);
      mutate.mutateAsync(data).then(() => {
        enqueueSnackbar('注文を保存しました');
        if (onClose) onClose();
      }).then(() => {
        if (createAfterCallbackFnc) createAfterCallbackFnc();
      }).catch((error) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, quotes,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-argument
        error.response.data.map((str) => enqueueSnackbar(str));
      })
        .finally(() => {
          setIsLoading(false);
          context.updateIsLoading(false);
          // eslint-disable-next-line no-void
          void queryClient.resetQueries({
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            predicate: (query) => resetQueryKeys.includes(`${query.queryKey[0]}`),
          });
          context.reset();
        });
    };

    const placeData = useMemo(() => (
      places.data ? places.data.pages.flat().flatMap((p) => p.data) : []
    ), [places.data]);

    const shipperData = useMemo(() => (
      shippers.data ? shippers.data.pages.flat().flatMap((p) => p.data) : []
    ), [shippers.data]);

    const complementIfEmpty = (attr, value) => {
      if (!getValues(attr)) {
        setValue(attr, value);
      }
    };

    const calculateStartEndTime = (times: SpecifiedTimeEntity) => {
      const start = startOfDay(new Date(startOn));
      start.setHours(Number(times.start_at.split(':')[0]));
      start.setMinutes(Number(times.start_at.split(':')[1]));
      let end = startOfDay(new Date(startOn));
      end.setHours(Number(times.end_at.split(':')[0]));
      end.setMinutes(Number(times.end_at.split(':')[1]));
      if (end.getTime() < start.getTime()) {
        end = addDays(end, 1);
      }
      return { start, end };
    };

    const loadingNameOnSelect = (_, value: (string | PlaceEntity & { label: string })) => {
      if (typeof value === 'string') return;
      if (!value) return;

      setValue('loading_name', value.name);
      setValue('loading_address', value.address);
      setValue('loading_latitude', value.latitude);
      setValue('loading_longitude', value.longitude);
      setValue('skip_loading_geocode', true);
      complementIfEmpty('shipper_name', value.shipper_name);
      complementIfEmpty('shipper_phone_number', value.shipper_phone_number);
      complementIfEmpty('shipper_email_address', value.shipper_email_address);

      if (value.place_specified_times_attributes && value.place_specified_times_attributes.length > 0) {
        for (let i = (loadingFields.length - 1); i >= 0; i--) {
          loadingRemove(i);
        }
        value.place_specified_times_attributes.forEach((times) => {
          const { start, end } = calculateStartEndTime(times);
          loadingAppend({
            start_at: format(start, "yyyy-MM-dd'T'HH:mm", { timeZone: 'Asia/Tokyo' }),
            end_at: format(end, "yyyy-MM-dd'T'HH:mm", { timeZone: 'Asia/Tokyo' })
          });
        });
      }
      complementIfEmpty('loading_staying_seconds', value.staying_minutes);

      complementPlaceCommon(value);
    };

    const unloadingNameOnSelect = (_, value: (string | PlaceEntity & { label: string })) => {
      if (typeof value === 'string') return;
      if (!value) return;

      setValue('unloading_name', value.name);
      setValue('unloading_address', value.address);
      setValue('unloading_latitude', value.latitude);
      setValue('unloading_longitude', value.longitude);
      setValue('skip_unloading_geocode', true);
      complementIfEmpty('shipper_name', value.shipper_name);
      complementIfEmpty('shipper_phone_number', value.shipper_phone_number);
      complementIfEmpty('shipper_email_address', value.shipper_email_address);

      if (value.place_specified_times_attributes && value.place_specified_times_attributes.length > 0) {
        for (let i = (unloadingFields.length - 1); i >= 0; i--) {
          unloadingRemove(i);
        }
        value.place_specified_times_attributes.forEach((times) => {
          const { start, end } = calculateStartEndTime(times);
          unloadingAppend({
            start_at: format(start, "yyyy-MM-dd'T'HH:mm", { timeZone: 'Asia/Tokyo' }),
            end_at: format(end, "yyyy-MM-dd'T'HH:mm", { timeZone: 'Asia/Tokyo' })
          });
        });
      }
      complementIfEmpty('unloading_staying_seconds', value.staying_minutes);

      complementPlaceCommon(value);
    };

    const complementPlaceCommon = useCallback((value: PlaceEntity) => {
      if (value.designated_truck_klasses) {
        setDesignatedTruckKlasses(Array.from(new Set([...designatedTruckKlasses, ...value.designated_truck_klasses])));
      }
      if (value.designated_truck_car_models) {
        setDesignatedTruckCarModels(Array.from(new Set([...designatedTruckCarModels, ...value.designated_truck_car_models])));
      }
      if (value.designated_truck_loading_platform_heights) {
        setDesignatedTruckLoadingPlatformHeights(Array.from(new Set([...designatedTruckLoadingPlatformHeights, ...value.designated_truck_loading_platform_heights])));
      }
      if (value.designated_truck_loading_platform_widths) {
        setDesignatedTruckLoadingPlatformWidths(Array.from(new Set([...designatedTruckLoadingPlatformWidths, ...value.designated_truck_loading_platform_widths])));
      }
      if (value.designated_truck_loading_platform_lengths) {
        setDesignatedTruckLoadingPlatformLengths(Array.from(new Set([...designatedTruckLoadingPlatformLengths, ...value.designated_truck_loading_platform_lengths])));
      }
      if (value.designated_truck_floor_specifications) {
        setDesignatedTruckFloorSpecifications(Array.from(new Set([...designatedTruckFloorSpecifications, ...value.designated_truck_floor_specifications])));
      }
      if (value.designated_truck_features) {
        setDesignatedTruckFeatures(Array.from(new Set([...designatedTruckFeatures, ...value.designated_truck_features])));
      }
      if (value.allowed_trucks) {
        setAllowedTrucks(Array.from(new Set([...allowedTrucks, ...value.allowed_trucks])));
      }
      if (value.denied_drivers) {
        setDeniedDrivers(Array.from(new Set([...deniedDrivers, ...value.denied_drivers])));
      }
    }, [
      designatedTruckKlasses, designatedTruckCarModels, designatedTruckLoadingPlatformHeights,
      designatedTruckLoadingPlatformWidths, designatedTruckLoadingPlatformLengths, designatedTruckFloorSpecifications,
      designatedTruckFeatures, allowedTrucks, deniedDrivers
    ]);

    const shipperNameOnSelect = (_, value: (string | ShipperEntity & { label: string })) => {
      if (typeof value === 'string') return;
      setValue('shipper_name', value.name);
      setValue('shipper_email_address', value.email_address);
      setValue('shipper_phone_number', value.phone_number);
    };

    useEffect(() => {
      if (places.data) {
        // eslint-disable-next-line no-void
        void places.fetchNextPage();
      }
    }, [places]);

    useEffect(() => {
      if (shippers.data) {
        // eslint-disable-next-line no-void
        void shippers.fetchNextPage();
      }
    }, [shippers]);

    useEffect(() => {
      if (!entity) {
        reset({
          company_id: licenseContext.config?.selected_company_id || licenseContext.config?.company_id,
          order_loading_specified_datetimes_attributes: [{
              start_at: format(
                startOfDay(new Date(startOn)),
                "yyyy-MM-dd'T'HH:mm",
                {
                  timeZone: 'Asia/Tokyo'
                }
              ),
              end_at: format(
                endOfDay(new Date(startOn)),
                "yyyy-MM-dd'T'HH:mm",
                {
                  timeZone: 'Asia/Tokyo'
                }
              )
            }],
          order_unloading_specified_datetimes_attributes: [{
              start_at: format(
                startOfDay(new Date(startOn)),
                "yyyy-MM-dd'T'HH:mm",
                {
                  timeZone: 'Asia/Tokyo'
                }
              ),
              end_at: format(
                endOfDay(new Date(startOn)),
                "yyyy-MM-dd'T'HH:mm",
                {
                  timeZone: 'Asia/Tokyo'
                }
              )
            }],
        });
        setItemCanBeMixed(true);
        setCompanyId(licenseContext.config?.selected_company_id || licenseContext.config?.company_id);
        return;
      }

      reset({
        ...entity,
        loading_staying_seconds: (entity.loading_staying_seconds !== null && entity.loading_staying_seconds !== undefined ? entity.loading_staying_seconds / 60 : null),
        unloading_staying_seconds: (entity.unloading_staying_seconds !== null && entity.unloading_staying_seconds !== undefined ? entity.unloading_staying_seconds / 60 : null),
        order_loading_specified_datetimes_attributes: entity.order_loading_specified_datetimes_attributes.map((dt) => (
          {
            start_at: format(
              new Date(dt.start_at),
              "yyyy-MM-dd'T'HH:mm",
              {
                timeZone: 'Asia/Tokyo'
              }
            ),
            end_at: format(
              new Date(dt.end_at),
              "yyyy-MM-dd'T'HH:mm",
              {
                timeZone: 'Asia/Tokyo'
              }
            )
          }
        )),
        order_unloading_specified_datetimes_attributes: entity.order_unloading_specified_datetimes_attributes.map((dt) => (
          {
            start_at: format(
              new Date(dt.start_at),
              "yyyy-MM-dd'T'HH:mm",
              {
                timeZone: 'Asia/Tokyo'
              }
            ),
            end_at: format(
              new Date(dt.end_at),
              "yyyy-MM-dd'T'HH:mm",
              {
                timeZone: 'Asia/Tokyo'
              }
            )
          }
        ))
      });

      setModified(false);
      setCompanyId(entity.company_id);
      setItemPackingStyleValue(entity.item_packing_style);
      setItemKlass(entity.item_klass);
      setItemHandlingOfCargoStyle(entity.item_handling_of_cargo_style);
      setItemCanBeMixed(entity.item_can_be_mixed);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setIsEdit(Object.prototype.hasOwnProperty.call(entity, 'id'));

      setDesignatedTruckKlasses(entity.designated_truck_klasses || []);
      setDesignatedTruckCarModels(entity.designated_truck_car_models || []);
      setDesignatedTruckLoadingPlatformHeights(entity.designated_truck_loading_platform_heights || []);
      setDesignatedTruckLoadingPlatformWidths(entity.designated_truck_loading_platform_widths || []);
      setDesignatedTruckLoadingPlatformLengths(entity.designated_truck_loading_platform_lengths || []);
      setDesignatedTruckFloorSpecifications(entity.designated_truck_floor_specifications || []);
      setDesignatedTruckFeatures(entity.designated_truck_features || []);
    }, [entity, licenseContext.config, dialogIsOpen, reset, startOn]);

    useEffect(() => {
      if (!companyData) return;
      if (!entity) return;

      if (companyData.custom_input_fields[0]) {
        setCustomInputFieldOne(
          entity.custom_input_fields?.find((field) => field.key === companyData.custom_input_fields[0])?.value || ''
        );
      }
      if (companyData.custom_input_fields[1]) {
        setCustomInputFieldTwo(
          entity.custom_input_fields?.find((field) => field.key === companyData.custom_input_fields[1])?.value || ''
        );
      }
      if (companyData.custom_input_fields[2]) {
        setCustomInputFieldThree(
          entity.custom_input_fields?.find((field) => field.key === companyData.custom_input_fields[2])?.value || ''
        );
      }
      if (companyData.custom_input_fields[3]) {
        setCustomInputFieldFour(
          entity.custom_input_fields?.find((field) => field.key === companyData.custom_input_fields[3])?.value || ''
        );
      }
      if (companyData.custom_input_fields[4]) {
        setCustomInputFieldFive(
          entity.custom_input_fields?.find((field) => field.key === companyData.custom_input_fields[4])?.value || ''
        );
      }
    }, [companyData, entity]);

    useEffect(() => {
      if (!watchFields.every((maybe) => maybe)) return;
      if (!companyData) return;

      const count = watchFields[0];
      const name = watchFields[1];
      const maybe = companyData
        .packing_style_and_weight_volumes
        .find((style) => style.name === name);

      if (!maybe) return;

      if (maybe.volume) form.setValue('item_total_volume_m3', maybe.volume * count);
      if (maybe.weight) form.setValue('item_total_weight_kg', maybe.weight * count);
    }, [watchFields, companyData, form]);

    const {
      fields: loadingFields,
      append: loadingAppend,
      remove: loadingRemove
    } = useFieldArray({
      control,
      name: 'order_loading_specified_datetimes_attributes'
    });

    const {
      fields: unloadingFields,
      append: unloadingAppend,
      remove: unloadingRemove
    } = useFieldArray({
      control,
      name: 'order_unloading_specified_datetimes_attributes'
    });

    const [placeSaveCondition, setPlaceSaveCondition] = useState<PlaceSaveConditions>('unloadingPlace');

    const onChangePlaceSaveCondition = (event: SelectChangeEvent) => {
      setPlaceSaveCondition(event.target.value as PlaceSaveConditions);
    };

    const [truckData, setTruckData] = useState<TruckDriverIdAndName[]>([]);
    const [driverData, setDriverData] = useState<TruckDriverIdAndName[]>([]);

    useEffect(() => {
      if (trucks.data) {
        const list = trucks.data.filter((it) => it.companyId === companyId).map((truck) => ({ id: truck.id, name: truck.licensePlateValue }));
        setTruckData(list);
        if (entity && entity.allowed_trucks) {
          setAllowedTrucks(entity.allowed_trucks.filter((it) => list.some((truck) => truck.id === it.id)));
        }
      }
    }, [trucks.data, entity, companyId]);

    useEffect(() => {
      if (drivers.data) {
        const list = drivers.data.filter((it) => it.companyId === companyId).map((driver) => ({ id: driver.id, name: driver.name }));
        setDriverData(list);
        if (entity && entity.denied_drivers) {
          setDeniedDrivers(entity.denied_drivers.filter((it) => list.some((driver) => driver.id === it.id)));
        }
      }
    }, [companyId, drivers.data, entity]);

    const onChangeAllowedTrucks = useCallback((event: ChangeEvent<HTMLInputElement>, value: (TruckDriverIdAndName)[]) => {
      setAllowedTrucks(value);
      setModified(true);
    }, []);

    const onChangeDeniedDrivers = useCallback((event: ChangeEvent<HTMLInputElement>, value: (TruckDriverIdAndName)[]) => {
      setDeniedDrivers(value);
      setModified(true);
    }, []);

    const onChangeCompany = useCallback((e: SelectChangeEvent) => {
      setCompanyId(Number(e.target.value));
      setValue('company_id', Number(e.target.value));
    }, [setValue]);

    const [selectableCompanies, setSelectableCompanies] = useState<CompanyEntity[]>([]);
    useEffect(() => {
      setSelectableCompanies(licenseContext.config?.selectable_companies ?? []);
    }, [licenseContext.config]);

    const [filteredPlaceData, setFilteredPlaceData] = useState<PlaceEntity[]>([]);
    useEffect(() => {
      setFilteredPlaceData(
        placeData.filter((place) => place.company_id === companyId)
      );
    }, [placeData, companyId]);

    const [wasSplit, setWasSplit] = useState(false);
    const [originalWeightKg, setOriginalWeightKg] = useState('');
    const [originalVolumeM3, setOriginalVolumeM3] = useState('');
    const [originalItemCount, setOriginalItemCount] = useState('');

    useEffect(() => {
      if (!entity) { return; }
      const data = entity as OrderEntity;

      if (data.was_split) {
        setWasSplit(data.was_split);
        const orderSplit = data.order_split;
        setOriginalItemCount(orderSplit.original_item_count.toLocaleString());
        setOriginalWeightKg(
          numberDecorator.toRoundedUnit(
            orderSplit.original_item_total_weight_kg,
            'kg',
            1,
            ''
          )
        );
        setOriginalVolumeM3(
          numberDecorator.toRoundedUnit(
            orderSplit.original_item_total_volume_m3,
            'm3',
            1,
            ''
          )
        );
      } else {
        setWasSplit(false);
        setOriginalItemCount('');
        setOriginalWeightKg('');
        setOriginalItemCount('');
      }
    }, [entity]);

    return (
      <Dialog
        open={dialogIsOpen}
        maxWidth="md"
        fullWidth
        onClose={dialogOnClose}
      >
        <DialogContent>
          <Stack
            component="form"
            noValidate
            spacing={1}
          >
            {selectableCompanies.length > 1 && (
              <Paper>
                <Stack
                  pt={1}
                  pb={2}
                  px={2}
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      marginTop: theme.spacing(1),
                    }}
                  >
                    <Controller
                      control={control}
                      name="company_id"
                      render={({ field }) => (
                        <>
                          <InputLabel>事業所</InputLabel>
                          <Select
                            id="company_id"
                            margin="dense"
                            label="事業所"
                            type="number"
                            fullWidth
                            variant="standard"
                            value={`${companyId}`}
                            onChange={onChangeCompany}
                          >
                            {selectableCompanies.map((company) => (
                              <MenuItem value={company.id} key={company.id}>{company.name}</MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    />
                    <FormHelperText error={'company_id' in errors}>{errors.company_id?.message}</FormHelperText>
                  </FormControl>
                </Stack>
              </Paper>
            )}
            <Paper>
              <Stack
                pt={1}
                pb={2}
                px={2}
              >
                <TextField
                  margin="dense"
                  type="text"
                  fullWidth
                  variant="standard"
                  id="code"
                  label="注文番号"
                  {...register('code')}
                  error={'code' in errors}
                  helperText={errors.code?.message}
                  InputLabelProps={{ shrink: true }}
                  onChange={onChangeSetModified}
                />
                <TextField
                  margin="dense"
                  type="text"
                  fullWidth
                  variant="standard"
                  id="memo"
                  label="備考"
                  {...register('memo')}
                  error={'memo' in errors}
                  helperText={errors.memo?.message}
                  multiline
                  rows={4}
                  InputLabelProps={{ shrink: true }}
                  onChange={onChangeSetModified}
                />
                {
                  companyData
                  && companyData.custom_input_fields
                  && companyData.custom_input_fields.length > 0
                  && (
                    <Stack
                      direction={{
                        sm: 'column',
                        md: 'row'
                      }}
                      gap={1}
                      spacing={1}
                    >
                      {
                        companyData.custom_input_fields[0] && (
                          <TextField
                            label={companyData.custom_input_fields[0]}
                            value={customInputFieldOne}
                            onChange={updateCustomInputFieldOne}
                            size="small"
                            variant="standard"
                          />
                        )
                      }
                      {
                        companyData.custom_input_fields[1] && (
                          <TextField
                            label={companyData.custom_input_fields[1]}
                            value={customInputFieldTwo}
                            onChange={updateCustomInputFieldTwo}
                            size="small"
                            variant="standard"
                          />
                        )
                      }
                      {
                        companyData.custom_input_fields[2] && (
                          <TextField
                            label={companyData.custom_input_fields[2]}
                            value={customInputFieldThree}
                            onChange={updateCustomInputFieldThree}
                            size="small"
                            variant="standard"
                          />
                        )
                      }
                      {
                        companyData.custom_input_fields[3] && (
                          <TextField
                            label={companyData.custom_input_fields[3]}
                            value={customInputFieldFour}
                            onChange={updateCustomInputFieldFour}
                            size="small"
                            variant="standard"
                          />
                        )
                      }
                      {
                        companyData.custom_input_fields[4] && (
                          <TextField
                            label={companyData.custom_input_fields[4]}
                            value={customInputFieldFive}
                            onChange={updateCustomInputFieldFive}
                            size="small"
                            variant="standard"
                          />
                        )
                      }
                    </Stack>
                  )
                }
              </Stack>
            </Paper>
            <Paper>
              <Stack
                pt={1}
                pb={2}
                px={2}
              >
                <Autocomplete
                  freeSolo
                  options={shipperData.map((d) => ({
                    ...d, label: d.name
                  }))}
                  value={getValues('shipper_name') ?? null}
                  onChange={shipperNameOnSelect}
                  multiple={false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      type="text"
                      fullWidth
                      variant="standard"
                      label="荷主名"
                      id="shipper_name"
                      {...register('shipper_name')}
                      error={'shipper_name' in errors}
                      helperText={errors.shipper_name?.message}
                      required
                      InputLabelProps={{ shrink: true }}
                      onChange={onChangeSetModified}
                    />
                  )}
                />
                <Stack direction="row" gap={1}>
                  <TextField
                    margin="dense"
                    type="text"
                    fullWidth
                    label="電話番号"
                    variant="standard"
                    id="shipper_phone_number"
                    {...register('shipper_phone_number')}
                    error={'shipper_phone_number' in errors}
                    helperText={errors.shipper_phone_number?.message}
                    InputLabelProps={{ shrink: true }}
                    onChange={onChangeSetModified}
                  />
                  <TextField
                    margin="dense"
                    type="text"
                    fullWidth
                    label="メールアドレス"
                    variant="standard"
                    id="shipper_email_address"
                    {...register('shipper_email_address')}
                    error={'shipper_email_address' in errors}
                    helperText={errors.shipper_email_address?.message}
                    InputLabelProps={{ shrink: true }}
                    onChange={onChangeSetModified}
                  />
                </Stack>
              </Stack>
            </Paper>

            <Paper>
              <Stack
                pt={1}
                pb={2}
                px={2}
              >
                <Autocomplete
                  freeSolo
                  options={filteredPlaceData.map((d) => ({
                    ...d, label: d.name
                  }))}
                  onChange={loadingNameOnSelect}
                  multiple={false}
                  value={getValues('loading_name') ?? null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      type="text"
                      fullWidth
                      variant="standard"
                      label="積地名"
                      id="loading_name"
                      {...register('loading_name')}
                      error={'loading_name' in errors}
                      helperText={errors.loading_name?.message}
                      required
                      InputLabelProps={{ shrink: true }}
                      onChange={onChangeSetModified}
                    />
                  )}
                />
                <TextField
                  margin="dense"
                  type="text"
                  fullWidth
                  variant="standard"
                  label="住所"
                  id="loading_address"
                  {...register('loading_address')}
                  error={'loading_address' in errors}
                  helperText={errors.loading_address?.message}
                  required
                  InputLabelProps={{ shrink: true }}
                  onChange={onChangeSetModified}
                />
                {
                  loadingFields.map((field, idx) => (
                    <Stack
                      direction="row"
                      gap={1}
                      alignItems="baseline"
                      key={['loading_address', field, idx].join('-')}
                    >
                      <TextField
                        margin="dense"
                        type="datetime-local"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={field.start_at}
                        label="受付開始時間"
                        id={`order_loading_specified_datetimes_attributes[${idx}].start_at`}
                        {...register(`order_loading_specified_datetimes_attributes.${idx}.start_at`)}
                        error={!!errors.order_loading_specified_datetimes_attributes?.[idx]?.start_at || !!loadingStartTimeError}
                        helperText={errors.order_loading_specified_datetimes_attributes?.[idx]?.start_at?.message || loadingStartTimeError}
                        required
                        onChange={onChangeSetModified}
                      />
                      <TextField
                        margin="dense"
                        type="datetime-local"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={field.end_at}
                        label="受付終了時間"
                        id={`order_loading_specified_datetimes_attributes.${idx}.end_at`}
                        {...register(`order_loading_specified_datetimes_attributes.${idx}.end_at`)}
                        error={!!errors.order_loading_specified_datetimes_attributes?.[idx]?.end_at}
                        helperText={errors.order_loading_specified_datetimes_attributes?.[idx]?.end_at?.message}
                        required
                        onChange={onChangeSetModified}
                      />
                      <IconButton
                        disabled={loadingFields.length >= 3}
                        onClick={() => loadingAppend({
                          start_at: format(
                            startOfDay(new Date(startOn)),
                            "yyyy-MM-dd'T'HH:mm",
                            {
                              timeZone: 'Asia/Tokyo'
                            }
                          ),
                          end_at: format(
                            endOfDay(new Date(startOn)),
                            "yyyy-MM-dd'T'HH:mm",
                            {
                              timeZone: 'Asia/Tokyo'
                            }
                          )
                        })}
                      >
                        <AddCircleOutlineOutlined />
                      </IconButton>
                      <IconButton
                        disabled={loadingFields.length < 2}
                        onClick={() => loadingRemove(idx)}
                      >
                        <RemoveCircleOutlineOutlined />
                      </IconButton>
                    </Stack>
                  ))
                }
                <Stack
                  direction="row"
                  gap={1}
                  alignItems="baseline"
                >
                  <TextField
                    margin="dense"
                    type="number"
                    variant="standard"
                    id="loading_staying_seconds"
                    label="滞在時間(分)"
                    {...register('loading_staying_seconds')}
                    error={'loading_staying_seconds' in errors}
                    helperText={errors.loading_staying_seconds?.message}
                    InputLabelProps={{ shrink: true }}
                    onChange={onChangeSetModified}
                  />
                </Stack>
              </Stack>
            </Paper>

            <Paper>
              <Stack
                pt={1}
                pb={2}
                px={2}
              >
                <Autocomplete
                  freeSolo
                  options={filteredPlaceData.map((d) => ({
                    ...d, label: d.name
                  }))}
                  onChange={unloadingNameOnSelect}
                  multiple={false}
                  value={getValues('unloading_name') ?? null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      type="text"
                      fullWidth
                      variant="standard"
                      label="降地名"
                      id="unloading_name"
                      {...register('unloading_name')}
                      error={'unloading_name' in errors}
                      helperText={errors.unloading_name?.message}
                      required
                      InputLabelProps={{ shrink: true }}
                      onChange={onChangeSetModified}
                    />
                  )}
                />
                <TextField
                  margin="dense"
                  type="text"
                  fullWidth
                  variant="standard"
                  label="住所"
                  id="unloading_address"
                  {...register('unloading_address')}
                  error={'unloading_address' in errors}
                  helperText={errors.unloading_address?.message}
                  required
                  InputLabelProps={{ shrink: true }}
                  onChange={onChangeSetModified}
                />
                {
                  unloadingFields.map((field, idx) => (
                    <Stack
                      direction="row"
                      gap={1}
                      alignItems="baseline"
                      key={['unloading_address', field, idx].join('-')}
                    >
                      <TextField
                        margin="dense"
                        type="datetime-local"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={field.start_at}
                        label="受付開始時間"
                        id={`order_unloading_specified_datetimes_attributes.${idx}.start_at`}
                        {...register(`order_unloading_specified_datetimes_attributes.${idx}.start_at`)}
                        error={!!errors.order_unloading_specified_datetimes_attributes?.[idx]?.start_at || !!unloadingStartTimeError}
                        helperText={errors.order_unloading_specified_datetimes_attributes?.[idx]?.start_at?.message || unloadingStartTimeError}
                        required
                        onChange={onChangeSetModified}
                      />
                      <TextField
                        margin="dense"
                        type="datetime-local"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={field.end_at}
                        label="受付終了時間"
                        id={`order_unloading_specified_datetimes_attributes.${idx}.end_at`}
                        {...register(`order_unloading_specified_datetimes_attributes.${idx}.end_at`)}
                        error={!!errors.order_unloading_specified_datetimes_attributes?.[idx]?.end_at || !!unloadingEndTimeError}
                        helperText={errors.order_unloading_specified_datetimes_attributes?.[idx]?.end_at?.message || unloadingEndTimeError}
                        required
                        onChange={onChangeSetModified}
                      />
                      <IconButton
                        disabled={unloadingFields.length >= 3}
                        onClick={() => unloadingAppend({
                          start_at: format(
                            startOfDay(new Date(startOn)),
                            "yyyy-MM-dd'T'HH:mm",
                            {
                              timeZone: 'Asia/Tokyo'
                            }
                          ),
                          end_at: format(
                            endOfDay(new Date(startOn)),
                            "yyyy-MM-dd'T'HH:mm",
                            {
                              timeZone: 'Asia/Tokyo'
                            }
                          )
                        })}
                      >
                        <AddCircleOutlineOutlined />
                      </IconButton>
                      <IconButton
                        disabled={unloadingFields.length < 2}
                        onClick={() => unloadingRemove(idx)}
                      >
                        <RemoveCircleOutlineOutlined />
                      </IconButton>
                    </Stack>
                  ))
                }
                <Stack
                  direction="row"
                  gap={1}
                  alignItems="baseline"
                >
                  <TextField
                    margin="dense"
                    type="number"
                    variant="standard"
                    id="unloading_staying_seconds"
                    label="滞在時間(分)"
                    {...register('unloading_staying_seconds')}
                    error={'unloading_staying_seconds' in errors}
                    helperText={errors.unloading_staying_seconds?.message}
                    InputLabelProps={{ shrink: true }}
                    onChange={onChangeSetModified}
                  />
                </Stack>
              </Stack>
            </Paper>

            <Paper>
              <Stack
                pt={1}
                pb={2}
                px={2}
              >
                <Stack
                  direction="row"
                  gap={1}
                  alignItems="baseline"
                  justifyContent="space-between"
                >
                  <Stack width="100%">
                    <FormControl
                      variant="standard"
                      fullWidth
                      size="small"
                    >
                      <InputLabel>荷姿</InputLabel>
                      <Controller
                        control={control}
                        name="item_packing_style"
                        render={({ field }) => (
                          <Select
                            id="item_packing_style"
                            margin="dense"
                            label="荷姿"
                            type="text"
                            fullWidth
                            variant="standard"
                            {...field}
                            defaultValue={entity && entity.item_packing_style ? entity.item_packing_style : ''}
                            onChange={onChangeSetModified}
                          >
                            <MenuItem value="">&nbsp;</MenuItem>
                            <MenuItem value="ケース">ケース</MenuItem>
                            <MenuItem value="袋">袋</MenuItem>
                            <MenuItem value="はだか">はだか</MenuItem>
                            <MenuItem value="フレコンバック">フレコンバック</MenuItem>
                            <MenuItem value="ドラム類">ドラム類</MenuItem>
                            <MenuItem value="缶類">缶類</MenuItem>
                            <MenuItem value="パレット">パレット</MenuItem>
                            <MenuItem value="ラック">ラック</MenuItem>
                            <MenuItem value="ばら">ばら</MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Stack>
                  <Stack width="100%">
                    <TextField
                      margin="dense"
                      type="number"
                      variant="standard"
                      fullWidth
                      id="item_count"
                      label="数量"
                      {...register('item_count')}
                      error={'item_count' in errors}
                      helperText={errors.item_count?.message}
                      required
                      InputLabelProps={{ shrink: true }}
                      onChange={onChangeSetModified}
                    />
                    {wasSplit && (
                      <Stack direction="row" gap={1} sx={{ fontSize: '80%', color: '#777' }}>
                        <Typography>元の数量</Typography>
                        <Typography>{originalItemCount}</Typography>
                      </Stack>
                    )}
                  </Stack>
                  <Stack width="100%">
                    <TextField
                      margin="dense"
                      type="number"
                      variant="standard"
                      fullWidth
                      id="item_total_weight_kg"
                      label={`総重量(${licenseContext?.config?.unit})`}
                      {...register('item_total_weight_kg')}
                      error={'item_total_weight_kg' in errors}
                      helperText={errors.item_total_weight_kg?.message}
                      required
                      InputLabelProps={{ shrink: true }}
                      onChange={onChangeSetModified}
                    />
                    {wasSplit && (
                      <Stack direction="row" gap={1} sx={{ fontSize: '80%', color: '#777' }}>
                        <Typography>元の総重量</Typography>
                        <Typography>{originalWeightKg}</Typography>
                      </Stack>
                    )}
                  </Stack>
                  {licenseContext?.config?.unit_used_for_calculation === '重量と体積' && (
                    <Stack width="100%">
                      <TextField
                        margin="dense"
                        type="number"
                        variant="standard"
                        fullWidth
                        label="総体積(m3)"
                        id="item_total_volume_m3"
                        {...register('item_total_volume_m3')}
                        error={'item_total_volume_m3' in errors}
                        helperText={errors.item_total_volume_m3?.message}
                        InputLabelProps={{ shrink: true }}
                        onChange={onChangeSetModified}
                      />
                      {wasSplit && (
                        <Stack direction="row" gap={1} sx={{ fontSize: '80%', color: '#777' }}>
                          <Typography>元の総体積</Typography>
                          <Typography>{originalVolumeM3}</Typography>
                        </Stack>
                      )}
                    </Stack>
                  )}
                </Stack>
                <Stack direction="row" gap={1} alignItems="baseline">
                  <FormControl
                    variant="standard"
                    fullWidth
                  >
                    <InputLabel>輸送区分</InputLabel>
                    <Controller
                      control={control}
                      name="item_klass"
                      render={({ field }) => (
                        <Select
                          id="item_klass"
                          margin="dense"
                          label="輸送区分"
                          type="text"
                          fullWidth
                          variant="standard"
                          {...field}
                          value={itemKlass ?? ''}
                          onChange={onChangeSetModified}
                        >
                          <MenuItem value="">&nbsp;</MenuItem>
                          <MenuItem value="農産物">農産物</MenuItem>
                          <MenuItem value="畜産物">畜産物</MenuItem>
                          <MenuItem value="水産物">水産物</MenuItem>
                          <MenuItem value="食料品">食料品</MenuItem>
                          <MenuItem value="飲料品">飲料品</MenuItem>
                          <MenuItem value="木材">木材</MenuItem>
                          <MenuItem value="砂利・砂等">砂利・砂等</MenuItem>
                          <MenuItem value="金属製品">金属製品</MenuItem>
                          <MenuItem value="銅材">銅材</MenuItem>
                          <MenuItem value="建材">建材</MenuItem>
                          <MenuItem value="電気製品">電気製品</MenuItem>
                          <MenuItem value="機械・装置">機械・装置</MenuItem>
                          <MenuItem value="セメント">セメント</MenuItem>
                          <MenuItem value="セメント製品">セメント製品</MenuItem>
                          <MenuItem value="紙・パルプ製品">紙・パルプ製品</MenuItem>
                          <MenuItem value="石油製品">石油製品</MenuItem>
                          <MenuItem value="化学製品">化学製品</MenuItem>
                          <MenuItem value="その他危険物">その他危険物</MenuItem>
                          <MenuItem value="衣料・雑貨">衣料・雑貨</MenuItem>
                          <MenuItem value="引越貨物">引越貨物</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                  <TextField
                    margin="dense"
                    type="text"
                    fullWidth
                    variant="standard"
                    label="品名"
                    id="item_name"
                    {...register('item_name')}
                    error={'item_name' in errors}
                    helperText={errors.item_name?.message}
                    InputLabelProps={{ shrink: true }}
                    onChange={onChangeSetModified}
                  />

                  <FormControl
                    variant="standard"
                    fullWidth
                  >
                    <InputLabel>荷扱い</InputLabel>
                    <Controller
                      control={control}
                      name="item_handling_of_cargo_style"
                      render={({ field }) => (
                        <Select
                          id="item_handling_of_cargo_style"
                          margin="dense"
                          label="荷扱い"
                          type="text"
                          fullWidth
                          variant="standard"
                          {...field}
                          // defaultValue={entity && entity.item_handling_of_cargo_style ? entity.item_handling_of_cargo_style : ''}
                          value={itemHandlingOfCargoStyle ?? ''}
                          onChange={onChangeSetModified}
                        >
                          <MenuItem value="">&nbsp;</MenuItem>
                          <MenuItem value="手積">手積</MenuItem>
                          <MenuItem value="機械積">機械積</MenuItem>
                          <MenuItem value="機械積（フォーク）">機械積（フォーク）</MenuItem>
                          <MenuItem value="機械積（クレーン）">機械積（クレーン）</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>

                  <FormControl
                    variant="standard"
                    fullWidth
                  >
                    <Controller
                      control={control}
                      name="item_can_be_mixed"
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          control={(
                            <Switch
                              defaultChecked={entity ? entity.item_can_be_mixed : getValues('item_can_be_mixed')}
                            />
                          )}
                          label="積み合わせ可"
                          labelPlacement="start"
                          onChange={onChangeSetModified}
                        />
                      )}
                    />
                  </FormControl>
                </Stack>
              </Stack>
            </Paper>
            <Paper>
              <Stack
                pt={1}
                pb={2}
                px={2}
              >
                <FormControl component="fieldset">
                  <FormLabel component="legend">指定車両タイプ</FormLabel>
                  <FormGroup row>
                    <MultiCheckbox<OrderRequestEntity | OrderEntity, DesignatedTruckKlass>
                      form={form}
                      control={control}
                      name="designated_truck_klasses"
                      options={[
                        '軽貨物',
                        'トラック',
                        'セミトレーラー',
                        'ポールトレーラー',
                        'フルトレーラー'
                      ]}
                      defaultCheckedValues={designatedTruckKlasses}
                      onChange={onChangeSetModified}
                      valueSetter={setDesignatedTruckKlasses}
                    />
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">指定車種</FormLabel>
                  <FormGroup row>
                    <MultiCheckbox<OrderRequestEntity | OrderEntity, DesignatedTruckCarModel>
                      form={form}
                      control={control}
                      name="designated_truck_car_models"
                      options={[
                        '平ボディ',
                        'バンボディ',
                        'ウィングボディ',
                        '保冷車',
                        '冷凍車',
                        '車載車',
                        '重機運搬車',
                        '危険物運搬車',
                        'ダンプ',
                        '幌',
                        'ユニック',
                        '海上コンテナー用'
                      ]}
                      defaultCheckedValues={designatedTruckCarModels}
                      onChange={onChangeSetModified}
                      valueSetter={setDesignatedTruckCarModels}
                    />
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">荷台高さ</FormLabel>
                  <FormGroup row>
                    <MultiCheckbox<OrderRequestEntity | OrderEntity, DesignatedTruckLoadingPlatformHeight>
                      form={form}
                      control={control}
                      name="designated_truck_loading_platform_heights"
                      options={[
                        '標準',
                        '低床',
                        '中低床'
                      ]}
                      defaultCheckedValues={designatedTruckLoadingPlatformHeights}
                      onChange={onChangeSetModified}
                      valueSetter={setDesignatedTruckLoadingPlatformHeights}
                    />
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">荷台幅</FormLabel>
                  <FormGroup row>
                    <MultiCheckbox<OrderRequestEntity | OrderEntity, DesignatedTruckLoadingPlatformWidth>
                      form={form}
                      control={control}
                      name="designated_truck_loading_platform_widths"
                      options={[
                        '標準',
                        'セミワイド',
                        'ワイド',
                      ]}
                      defaultCheckedValues={designatedTruckLoadingPlatformWidths}
                      onChange={onChangeSetModified}
                      valueSetter={setDesignatedTruckLoadingPlatformWidths}
                    />
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">荷台長さ</FormLabel>
                  <FormGroup row>
                    <MultiCheckbox<OrderRequestEntity | OrderEntity, DesignatedTruckLoadingPlatformLength>
                      form={form}
                      control={control}
                      name="designated_truck_loading_platform_lengths"
                      options={[
                        '大型',
                        '中型',
                        '小型',
                        'ショート',
                        'ロング'
                      ]}
                      defaultCheckedValues={designatedTruckLoadingPlatformLengths}
                      onChange={onChangeSetModified}
                      valueSetter={setDesignatedTruckLoadingPlatformLengths}
                    />
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">床仕様</FormLabel>
                  <FormGroup row>
                    <MultiCheckbox<OrderRequestEntity | OrderEntity, DesignatedTruckFloorSpecification>
                      form={form}
                      control={control}
                      name="designated_truck_floor_specifications"
                      options={[
                        '鉄板',
                        'ステンレス',
                        'ジョルダー',
                        '縞板'
                      ]}
                      defaultCheckedValues={designatedTruckFloorSpecifications}
                      onChange={onChangeSetModified}
                      valueSetter={setDesignatedTruckFloorSpecifications}
                    />
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">装置</FormLabel>
                  <FormGroup row>
                    <MultiCheckbox<OrderRequestEntity | OrderEntity, DesignatedTruckFeature>
                      form={form}
                      control={control}
                      name="designated_truck_features"
                      options={[
                        'パワーゲート',
                        '空調',
                        'エアサス',
                        'スタンション'
                      ]}
                      defaultCheckedValues={designatedTruckFeatures}
                      onChange={onChangeSetModified}
                      valueSetter={setDesignatedTruckFeatures}
                    />
                  </FormGroup>
                </FormControl>
              </Stack>
            </Paper>

            <Paper>
              <Stack
                pt={1}
                pb={2}
                px={2}
              >
                <Stack
                  direction="row"
                  gap={1}
                >
                  <TextField
                    margin="dense"
                    type="number"
                    variant="standard"
                    fullWidth
                    label="基本運賃"
                    id="charge_basic_fee_yen"
                    {...register('charge_basic_fee_yen')}
                    error={'charge_basic_fee_yen' in errors}
                    helperText={errors.charge_basic_fee_yen?.message}
                    InputLabelProps={{ shrink: true }}
                    onChange={onChangeSetModified}
                  />
                  <TextField
                    margin="dense"
                    type="number"
                    variant="standard"
                    fullWidth
                    label="高速代"
                    id="charge_highway_fee_yen"
                    {...register('charge_highway_fee_yen')}
                    error={'charge_highway_fee_yen' in errors}
                    helperText={errors.charge_highway_fee_yen?.message}
                    InputLabelProps={{ shrink: true }}
                    onChange={onChangeSetModified}
                  />
                  <TextField
                    margin="dense"
                    type="number"
                    variant="standard"
                    fullWidth
                    label="積込料"
                    id="charge_loading_fee_yen"
                    {...register('charge_loading_fee_yen')}
                    error={'charge_loading_fee_yen' in errors}
                    helperText={errors.charge_loading_fee_yen?.message}
                    InputLabelProps={{ shrink: true }}
                    onChange={onChangeSetModified}
                  />
                  <TextField
                    margin="dense"
                    type="number"
                    variant="standard"
                    fullWidth
                    label="付帯業務料"
                    id="charge_ancillary_fee_yen"
                    {...register('charge_ancillary_fee_yen')}
                    error={'charge_ancillary_fee_yen' in errors}
                    helperText={errors.charge_ancillary_fee_yen?.message}
                    InputLabelProps={{ shrink: true }}
                    onChange={onChangeSetModified}
                  />
                  <TextField
                    margin="dense"
                    type="number"
                    variant="standard"
                    fullWidth
                    label="待機時間料"
                    id="charge_waiting_time_fee_yen"
                    {...register('charge_waiting_time_fee_yen')}
                    error={'charge_waiting_time_fee_yen' in errors}
                    helperText={errors.charge_waiting_time_fee_yen?.message}
                    InputLabelProps={{ shrink: true }}
                    onChange={onChangeSetModified}
                  />
                  <TextField
                    margin="dense"
                    type="number"
                    variant="standard"
                    fullWidth
                    label="取卸料"
                    id="charge_unloading_fee_yen"
                    {...register('charge_unloading_fee_yen')}
                    error={'charge_unloading_fee_yen' in errors}
                    helperText={errors.charge_unloading_fee_yen?.message}
                    InputLabelProps={{ shrink: true }}
                    onChange={onChangeSetModified}
                  />
                  <TextField
                    margin="dense"
                    type="number"
                    variant="standard"
                    fullWidth
                    label="諸経費"
                    id="charge_expenses_fee_yen"
                    {...register('charge_expenses_fee_yen')}
                    error={'charge_expenses_fee_yen' in errors}
                    helperText={errors.charge_expenses_fee_yen?.message}
                    InputLabelProps={{ shrink: true }}
                    onChange={onChangeSetModified}
                  />
                </Stack>
                <TextField
                  {...register('charge_ancillary_content')}
                  error={'charge_ancillary_content' in formState.errors}
                  helperText={formState.errors.charge_ancillary_content?.message}
                  label="付帯業務内容"
                  size="small"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={onChangeSetModified}
                />
              </Stack>
            </Paper>
            <Paper>
              <Stack
                pt={1}
                pb={2}
                px={2}
              >
                <Autocomplete
                  multiple
                  id="allowd_trucks"
                  options={truckData}
                  getOptionLabel={(option: TruckDriverIdAndName) => option.name}
                  isOptionEqualToValue={(option, v) => option.id === v.id}
                  onChange={onChangeAllowedTrucks}
                  value={allowedTrucks}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="指定トラック"
                    />
                  )}
                />
                <Autocomplete
                  multiple
                  id="denied_drivers"
                  options={driverData}
                  getOptionLabel={(option: TruckDriverIdAndName) => option.name}
                  isOptionEqualToValue={(option, v) => option.id === v.id}
                  onChange={onChangeDeniedDrivers}
                  value={deniedDrivers}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="NGドライバー"
                    />
                  )}
                />
              </Stack>
            </Paper>
            <Paper>
              <Stack
                pt={1}
                pb={1}
                px={2}
              >
                <FormControl
                  fullWidth
                >
                  <RadioGroup
                    row
                    value={placeSaveCondition}
                    onChange={onChangePlaceSaveCondition}
                  >
                    <FormControlLabel value="loadingPlace" control={<Radio />} label="地点の新規登録時、制約条件を積地に紐づけて保存する" />
                    <FormControlLabel value="unloadingPlace" control={<Radio />} label="地点の新規登録時、制約条件を降地に紐づけて保存する" />
                  </RadioGroup>
                </FormControl>
              </Stack>
            </Paper>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', marginLeft: 1, marginRight: 3 }}>
          <Stack direction="row">
            {onClose && (
              <LoadingButton
                loading={isLoading}
                onClick={() => {
                  onClose();
                }}
              >
                キャンセル
              </LoadingButton>
            )}
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <LoadingButton startIcon={<SaveAsRoundedIcon />} onClick={form.handleSubmit(onSubmitSave)} variant="contained" loading={isLoading} loadingPosition="start">
              保存する
            </LoadingButton>
            {isEdit && <FormControlLabel control={<Checkbox name="copy" onChange={onChangeCopy} />} label="新規案件として保存する" sx={{ ml: 1 }} />}
          </Stack>
          <Stack>
            {onClickSplit && entity && (
              <LoadingButton
                disabled={modified}
                startIcon={<CallSplitRoundedIcon />}
                variant="contained"
                loading={isLoading}
                loadingPosition="start"
                onClick={() => {
                  onClickSplit();
                }}
              >
                荷物分割
              </LoadingButton>
            )}
          </Stack>
        </DialogActions>
      </Dialog>
    );
  }
);

export default OrderForm;
