import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { GridValueFormatterParams } from '@mui/x-data-grid-premium';
import { useContext } from 'react';
import LicenseContext from 'src/contexts/LicenseContext';
import { TruckEntity } from 'src/entities/truckEntity';
import { dataGridUtil } from 'src/utils/dataGrid.util';

export const Columns: (
  openEditDialog?: (truckEntity: TruckEntity) => void
) => GridColDef[] = (
  openEditDialog
) => {
  const licenseContext = useContext(LicenseContext);

  const getWidth = (name: string, width: number) => {
    const maybeWidth: number = dataGridUtil.getSavedColumnWidth('truck-columns', name);

    return maybeWidth || width;
  };

  const editColumn: GridColDef | null = openEditDialog ? {
    field: 'edit',
    headerName: '編集',
    disableExport: true,
    renderCell: (params: { row: TruckEntity }) => (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      <IconButton onClick={() => openEditDialog(params.row)}>
        <EditOutlinedIcon />
      </IconButton>
    ),
    width: 70,
    sortable: false,
    filterable: false,
  } : null;

  return [
    editColumn,
    {
      field: 'company_name',
      headerName: '事業所',
      width: getWidth('company_name', 200),
    },
    {
      field: 'garage_name',
      headerName: '車庫',
      width: getWidth('garage', 200),
    },
    {
      field: 'garage_address',
      headerName: '車庫住所',
      width: getWidth('garage_address', 100),
    },
    { field: 'license_plate_value', headerName: 'トラック', width: getWidth('license_plate_value', 320) },
    {
      field: 'maximum_loading_capacity_kg',
      headerName: `最大積載量(${licenseContext?.config?.unit})`,
      width: getWidth('maximum_loading_capacity_kg', 130),
      type: 'number',
    },
    {
      field: 'maximum_loading_rate',
      headerName: '積載率上限',
      width: getWidth('maximum_loading_rate', 100),
      type: 'number',
    },
    {
      field: 'minimum_loading_rate',
      headerName: '積載率下限',
      width: getWidth('minimum_loading_rate', 100),
      type: 'number',
    },
    {
      field: 'loading_platform_volume_m3',
      headerName: '荷台の積載容量(立米)',
      width: getWidth('loading_platform_volume_m3', 160),
      type: 'number',
    },
    { field: 'car_model', headerName: '車種', width: getWidth('car_model', 160) },
    { field: 'klass', headerName: '車両タイプ', width: getWidth('klass', 160) },
    { field: 'floor_specification', headerName: '床仕様', width: getWidth('floor_specification', 150) },
    { field: 'loading_platform_height', headerName: '荷台高の種別', width: getWidth('loading_platform_height', 150) },
    { field: 'loading_platform_length', headerName: '荷台長の種別', width: getWidth('loading_platform_length', 150) },
    { field: 'loading_platform_width', headerName: '荷台幅の種別', width: getWidth('loading_platform_width', 150) },
    {
      field: 'features',
      headerName: '装置',
      width: getWidth('features', 300),
      valueFormatter: (params: GridValueFormatterParams<string[]>) => {
        if (!params.value || params.value.length === 0 || params.value.length === 1) { // CSVに出力する場合
          return params.value;
        }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: TruckEntity }) => { // 画面に表示する場合
        if (!params.row.features) { return ''; }
        return params.row.features.join(',');
      }
    },
    {
      field: 'truck_fuel_cost_yen_per_km',
      headerName: '1kmあたりの燃料費',
      width: getWidth('truck_fuel_cost_yen_per_km', 150),
      type: 'number',
    },
    {
      field: 'truck_insurance_fee_yen_per_day',
      headerName: '1日あたりの保険料',
      width: getWidth('truck_insurance_fee_yen_per_day', 150),
      type: 'number',
    },
    {
      field: 'truck_repair_cost_yen_per_day',
      headerName: '1日あたりの修繕費',
      width: getWidth('truck_repair_cost_yen_per_day', 150),
      type: 'number',
    },
    {
      field: 'truck_expressway_fee_yen_per_shift',
      headerName: '1日あたりの高速代',
      width: getWidth('truck_expressway_fee_yen_per_shift', 150),
      type: 'number',
    },
    {
      field: 'max_num_pistons',
      headerName: '上限ピストン回数',
      width: getWidth('max_num_pistons', 150),
      type: 'number',
    },
    {
      field: 'max_num_locations_per_pistons',
      headerName: '１回転あたりの上限納品先件数',
      width: getWidth('max_num_locations_per_pistons', 150),
      type: 'number',
    },
  ].filter((maybe) => !!maybe);
};
