import { Stack } from '@mui/material';
import { FC, memo } from 'react';
import { EditPlaceEntity } from 'src/entities/EditPlace.entity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import {
  PlanningsOperationDeliveryByDeliveryIdEntity
} from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { PositionEntity } from 'src/entities/PositionEntity';

import PlanningMapDriverPresenter from './PlanningMapDriver.presenter';

type Props = {
  truckEntities: PlanningsTruckEntity[];
  driverEntitiesOnPosition: PlanningsDriverEntity[];
  deliveryEntitiesOnPosition: PlanningsDeliveryEntity[];
  position: PositionEntity;
  startOn: string;
  endOn: string;
  selectedOrderIds: number[];
  addSelectedOrderIds: (orderIds: number[]) => void;
  removeSelectedOrderIds: (orderIds: number[]) => void;
  updateDisplayOrderId: (orderId: number) => void;
  isLoading: boolean;
  updateTransferFromDeliveryIds?: (ids: number[]) => void;
  editPlaces: EditPlaceEntity[];
  planningsOperationDeliveryByDeliveryIdEntity: PlanningsOperationDeliveryByDeliveryIdEntity;
  mutateRestoreSplittedOrder: (orderId: number) => void;
}

const PlanningMapDriversPresenter: FC<Props> = memo((
  {
    driverEntitiesOnPosition,
    deliveryEntitiesOnPosition,
    position,
    startOn,
    endOn,
    selectedOrderIds,
    addSelectedOrderIds,
    removeSelectedOrderIds,
    updateDisplayOrderId,
    isLoading,
    updateTransferFromDeliveryIds,
    truckEntities,
    editPlaces,
    planningsOperationDeliveryByDeliveryIdEntity,
    mutateRestoreSplittedOrder,
  }
) => (
  <Stack>
    {driverEntitiesOnPosition.map((driverEntity) => (
      <PlanningMapDriverPresenter
        key={[
            'PlanningMapDeliveriesPresenter',
            'PlanningMapDriverPresenter',
            driverEntity.id
          ].join('-')}
        truckEntities={truckEntities}
        driverEntity={driverEntity}
        deliveryEntitiesOnPosition={deliveryEntitiesOnPosition}
        startOn={startOn}
        endOn={endOn}
        position={position}
        selectedOrderIds={selectedOrderIds}
        addSelectedOrderIds={addSelectedOrderIds}
        removeSelectedOrderIds={removeSelectedOrderIds}
        updateDisplayOrderId={updateDisplayOrderId}
        isLoading={isLoading}
        updateTransferFromDeliveryIds={updateTransferFromDeliveryIds}
        editPlaces={editPlaces}
        planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
        mutateRestoreSplittedOrder={mutateRestoreSplittedOrder}
      />
      ))}
  </Stack>
  ));

export default PlanningMapDriversPresenter;
