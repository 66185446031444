import { FC, memo, useEffect, useState } from 'react';
import { DriverEntity } from 'src/entities/Driver.entity';
import { PlanningsGroupEntity } from 'src/entities/PlanningsGroup.entity';
import { SelectedShiftCell } from 'src/entities/SelectedShiftCell.entity';
import { TruckEntity } from 'src/entities/Truck.entity';

import BodyRowPresenter from './BodyRow.presenter';
import { EditPropsBase } from './EditDialog.presenter';

type Props = {
  daysOfWeek: Date[];
  truckData: TruckEntity[];
  driverData: DriverEntity[];
  selectedGroup: PlanningsGroupEntity | undefined;
  isLoading: boolean;
  expandAll: boolean;
  updateEditPropsBase: (props: EditPropsBase) => void;
  defaultStartAt: string;
  defaultEndAt: string;
  defaultWorkingAvailableDurationHours: number | undefined;
  selectedShiftCell: SelectedShiftCell[];
  updateSelectedShiftCell: (cell: SelectedShiftCell[], checked: boolean) => void;
  appendDeliveryExistingCells: (cells: SelectedShiftCell[]) => void;
}

const BodyRowsPresenter: FC<Props> = memo((
  {
    daysOfWeek,
    truckData,
    driverData,
    selectedGroup,
    isLoading,
    expandAll,
    updateEditPropsBase,
    defaultStartAt,
    defaultEndAt,
    defaultWorkingAvailableDurationHours,
    selectedShiftCell,
    updateSelectedShiftCell,
    appendDeliveryExistingCells,
  }
) => {
  const [selectedDrivers, setSelectedDrivers] = useState<DriverEntity[]>([]);

  useEffect(() => {
    if (selectedGroup === undefined) {
      setSelectedDrivers(driverData);
    } else {
      const filtered = driverData.filter((it) => selectedGroup.truckIds.includes(it.defaultTruckId));
      setSelectedDrivers(filtered);
    }
  }, [driverData, selectedGroup]);

  useEffect(() => {
    const selectedDriverIds = selectedDrivers.map((it) => it.id);
    const idsToRemove = selectedShiftCell.filter((it) => !selectedDriverIds.includes(it.driver_id));
    updateSelectedShiftCell(idsToRemove, false);
  }, [selectedDrivers, selectedShiftCell, updateSelectedShiftCell]);

  if (!driverData) return null;

  return (
    <>
      {
        selectedDrivers.map((driver) => (
          <BodyRowPresenter
            key={[
              'BodyRowsPresenter',
              'BodyRowPresenter',
              driver.id
            ].join('-')}
            driver={driver}
            truckData={truckData}
            daysOfWeek={daysOfWeek}
            isLoading={isLoading}
            expandAll={expandAll}
            updateEditPropsBase={updateEditPropsBase}
            defaultStartAt={defaultStartAt}
            defaultEndAt={defaultEndAt}
            defaultWorkingAvailableDurationHours={defaultWorkingAvailableDurationHours}
            selectedShiftCell={selectedShiftCell.filter((it) => (it.driver_id === driver.id))}
            updateSelectedShiftCell={updateSelectedShiftCell}
            appendDeliveryExistingCells={appendDeliveryExistingCells}
          />
        ))
      }
    </>
  );
});

export default BodyRowsPresenter;
