import 'leaflet/dist/leaflet.css';

import { differenceInDays } from 'date-fns';
import { FC, memo, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import FourZeroFourComponent from 'src/components/FourZeroFourComponent';
import LicenseContext from 'src/contexts/LicenseContext';
import { DateModel } from 'src/models/DateModel';

import Component from './V2Plans/Component';

const V2Plans: FC = memo(() => {
  const { startOn, endOn } = useParams();
  const context = useContext(LicenseContext);
  const navigate = useNavigate();

  const [unit, setUnit] = useState<string | undefined>(undefined);
  const [driverCostYenPerHours, setDriverCostYenPerHours] = useState<number | undefined>(undefined);
  const [truckFuelCostYenPerKm, setTruckFuelCostYenPerKm] = useState<number | undefined>(undefined);
  const [truckInsuranceFeeYenPerDay, setTruckInsuranceFeeYenPerDay] = useState<number | undefined>(undefined);
  const [truckRepairCostYenPerDay, setTruckRepairCostYenPerDay] = useState<number | undefined>(undefined);
  const [truckExpresswayFeeYenPerShift, setTruckExpresswayFeeYenPerShift] = useState<number | undefined>(undefined);
  const [customInputFields, setCustomInputFields] = useState<string[]>([]);
  const [activateConstraintLoadAfterNotCleared, setActivateConstraintLoadAfterNotCleared] = useState<boolean>(false);

  useEffect(() => {
    if (!context.config?.on) return;
    if (!context.config?.unit) return;

    setUnit(context.config.unit);
    setDriverCostYenPerHours(context.config.driver_cost_yen_per_hours);
    setTruckFuelCostYenPerKm(context.config.truck_fuel_cost_yen_per_km);
    setTruckInsuranceFeeYenPerDay(context.config.truck_insurance_fee_yen_per_day);
    setTruckRepairCostYenPerDay(context.config.truck_repair_cost_yen_per_day);
    setTruckExpresswayFeeYenPerShift(context.config.truck_expressway_fee_yen_per_shift);
    setCustomInputFields(context.config.custom_input_fields);
    setActivateConstraintLoadAfterNotCleared(context.config.activate_constraint_load_after_not_cleared);

    if (startOn) {
      const paramsOnModel = new DateModel(startOn);
      let paramsEndOnModel = new DateModel(endOn);
      const dateDiff = differenceInDays(new Date(endOn), new Date(startOn));
      if (dateDiff < 0 || dateDiff > 9) {
        paramsEndOnModel = new DateModel(startOn);
      } else {
        return;
      }

      if (paramsOnModel.isValid()) {
        navigate(`/plans/${paramsOnModel.toString()}/${paramsEndOnModel.toString()}`);
        return;
      }
    }

    const configOnModel = new DateModel(context.config.on);

    if (configOnModel.isValid()) {
      navigate(`/plans/${configOnModel.toString()}/${configOnModel.toString()}`);
      return;
    }

    navigate('/404');
  }, [startOn, endOn, context]);

  if (!startOn) return <FourZeroFourComponent />;

  return (
    <Component
      startOn={startOn}
      endOn={endOn}
      unit={unit}
      driverCostYenPerHours={driverCostYenPerHours}
      truckFuelCostYenPerKm={truckFuelCostYenPerKm}
      truckInsuranceFeeYenPerDay={truckInsuranceFeeYenPerDay}
      truckRepairCostYenPerDay={truckRepairCostYenPerDay}
      truckExpresswayFeeYenPerShift={truckExpresswayFeeYenPerShift}
      customInputFields={customInputFields}
      activateConstraintLoadAfterNotCleared={activateConstraintLoadAfterNotCleared}
    />
  );
});

export default V2Plans;
