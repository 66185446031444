import { Checkbox, Paper, Stack, Typography } from '@mui/material';
import { FC, memo, Reducer, useCallback, useEffect, useReducer, useState } from 'react';
import ReactGA from 'react-ga4';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import { EditPlaceEntity } from 'src/entities/EditPlace.entity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import {
  PlanningsOperationDeliveryByDeliveryIdEntity
} from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { PositionEntity } from 'src/entities/PositionEntity';
import { SelectedStatusVo } from 'src/vo/SelectedStatus.vo';

import PlanningMapDeliveryPresenter from './PlanningMapDelivery.presenter';

type SelectedStatusesActions = {
  type: 'update';
  payload: {
    idx: number;
    deliveryId: number;
    status: SelectedStatusVo;
  };
}

const selectedStatusesReducer: Reducer<{
  idx: number;
  deliveryId: number;
  status: SelectedStatusVo;
}[], SelectedStatusesActions> = (state, action) => {
  switch (action.type) {
    case 'update': {
      return [
        ...state.filter((it) => it.idx !== action.payload.idx && it.deliveryId !== action.payload.deliveryId),
        action.payload
      ];
    }
    default: {
      return state;
    }
  }
};

type Props = {
  truckEntities: PlanningsTruckEntity[];
  driverEntity: PlanningsDriverEntity;
  deliveryEntitiesOnPosition: PlanningsDeliveryEntity[];
  position: PositionEntity;
  startOn: string;
  endOn: string;
  selectedOrderIds: number[];
  addSelectedOrderIds: (orderIds: number[]) => void;
  removeSelectedOrderIds: (orderIds: number[]) => void;
  updateDisplayOrderId: (orderId: number) => void;
  isLoading: boolean;
  updateTransferFromDeliveryIds?: (ids: number[]) => void;
  editPlaces: EditPlaceEntity[];
  planningsOperationDeliveryByDeliveryIdEntity: PlanningsOperationDeliveryByDeliveryIdEntity;
  mutateRestoreSplittedOrder: (orderId: number) => void;
}

const PlanningMapDriverPresenter: FC<Props> = memo((
  {
    truckEntities,
    driverEntity,
    deliveryEntitiesOnPosition,
    position,
    selectedOrderIds,
    addSelectedOrderIds,
    removeSelectedOrderIds,
    updateDisplayOrderId,
    isLoading,
    updateTransferFromDeliveryIds,
    editPlaces,
    planningsOperationDeliveryByDeliveryIdEntity,
    mutateRestoreSplittedOrder,
  }
) => {
  const [name, setName] = useState<string>('');
  const [deliveryEntities, setDeliveryEntities] = useState<PlanningsDeliveryEntity[]>([]);
  const [forceSelect, setForceSelect] = useState(false);
  const [forceUnselect, setForceUnselect] = useState(false);
  const [selectedStatuses, dispatchSelectedStatuses] = useReducer(selectedStatusesReducer, []);
  const [selectedStatus, setSelectedStatus] = useState<SelectedStatusVo>('none');
  const [transferFromDeliveryIds, setTransferFromDeliveryIds] = useState<number[]>([]);

  const addTransferFromDeliveryId = (id: number) => {
    setTransferFromDeliveryIds(
      [
        ...transferFromDeliveryIds.filter((it) => it !== id),
        id
      ]
    );
  };

  const removeTransferFromDeliveryId = (id: number) => {
    setTransferFromDeliveryIds(
      transferFromDeliveryIds.filter((it) => it !== id)
    );
  };

  const updateSelectedStatus = useCallback((idx: number, deliveryId: number, status: SelectedStatusVo) => {
    dispatchSelectedStatuses({
      type: 'update',
      payload: {
        idx,
        deliveryId,
        status
      }
    });

    setForceUnselect(false);
    setForceSelect(false);
  }, []);

  const checkboxOnClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING_PLACE_DIALOG, button_name: `ドライバーチェックボックス ${selectedStatus === 'every' ? 'OFF' : 'ON'}` });

    if (selectedStatus === 'every') {
      setForceUnselect(true);

      return;
    }

    setForceSelect(true);
  };

  useEffect(() => {
    if (updateTransferFromDeliveryIds) {
      updateTransferFromDeliveryIds(transferFromDeliveryIds);
    }
  }, [transferFromDeliveryIds, updateTransferFromDeliveryIds]);

  useEffect(() => {
    const statuses = selectedStatuses.map((it) => it.status);

    if (statuses.every((it) => it === 'every')) {
      setSelectedStatus('every');

      return;
    }

    if (statuses.every((it) => it === 'none')) {
      setSelectedStatus('none');

      return;
    }

    setSelectedStatus('some');
  }, [selectedStatuses]);

  useEffect(() => {
    if (!driverEntity) return;

    setName(
      driverEntity.name
    );
  }, [driverEntity]);

  useEffect(() => {
    if (!driverEntity) return;
    if (!deliveryEntitiesOnPosition) return;

    setName(
      driverEntity.name
    );
    setDeliveryEntities(
      deliveryEntitiesOnPosition.filter((it) => it.driverId === driverEntity.id)
    );
  }, [driverEntity, deliveryEntitiesOnPosition]);

  return (
    <Paper
      square
    >
      <Stack
        p={1}
        pr={0}
      >
        <Stack
          direction="row"
          gap={1}
          onClick={checkboxOnClick}
          style={{
            cursor: 'pointer'
          }}
        >
          <Checkbox
            indeterminate={selectedStatus === 'some'}
            checked={selectedStatus === 'every'}
            onClick={checkboxOnClick}
            disabled={isLoading}
            size="small"
            sx={{
              m: 0,
              p: 0.3,
            }}
          />
          <Typography>
            {name}
          </Typography>
        </Stack>
        <Stack>
          {
            deliveryEntities.map((deliveryEntity) => (
              <PlanningMapDeliveryPresenter
                key={[
                  'PlanningMapDriverPresenter',
                  'PlanningMapDeliveryPresenter',
                  deliveryEntity.id
                ].join('-')}
                truckEntities={truckEntities}
                deliveryEntity={deliveryEntity}
                position={position}
                selectedOrderIds={selectedOrderIds}
                addSelectedOrderIds={addSelectedOrderIds}
                removeSelectedOrderIds={removeSelectedOrderIds}
                updateDisplayOrderId={updateDisplayOrderId}
                isLoading={isLoading}
                forceUnselect={forceUnselect}
                forceSelect={forceSelect}
                updateSelectedStatus={updateSelectedStatus}
                addTransferFromDeliveryId={addTransferFromDeliveryId}
                removeTransferFromDeliveryId={removeTransferFromDeliveryId}
                editPlaces={editPlaces}
                planningsOperationDelivery={planningsOperationDeliveryByDeliveryIdEntity[`${deliveryEntity.id}`]}
                mutateRestoreSplittedOrder={mutateRestoreSplittedOrder}
              />
            ))
          }
        </Stack>
      </Stack>
    </Paper>
  );
});

export default PlanningMapDriverPresenter;
