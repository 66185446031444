import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  DragEndEvent,
  DragStartEvent,
  DragOverlay,
  DragOverEvent,
  useDroppable,
  pointerWithin,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Stack, TextField, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import LicenseContext from 'src/contexts/LicenseContext';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import { OrderPropertyEntity } from 'src/entities/OrderProperty.entity';
import { PlanningSetting, PlanningScenario, PlanningScenarioRequest, PlanningScenarioItem } from 'src/entities/PlanningHistory.entity';
import { useMutationPlanningScenario } from 'src/hooks/useQueryPlanningScenarios';
import { useQueryPlanningSettings } from 'src/hooks/useQueryPlanningSettings';

import { SearchOrderProperties } from './OrdersPresenter';

const DroppableContainer: FC<{
  children: React.ReactNode,
  id: string,
  isSortable: boolean,
}> = memo(({
  children,
  id,
  isSortable,
}) => {
  const { setNodeRef } = useDroppable({ id });

  const style = {
    padding: '5px',
    margin: '2px',
    border: '1px solid #ccc',
    width: '49%',
  };

  return (
    <div ref={setNodeRef} style={style}>
      {isSortable ? children : (<div>{children}</div>)}
    </div>
  );
});

const SortableItem: FC<{
    id: string,
    item: PlanningSetting,
    orderSearchProperties: OrderPropertyEntity[],
    changedItemId?: string,
    isUsing?: boolean,
}> = memo(({
  id,
  item,
  orderSearchProperties,
  changedItemId,
  isUsing,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const [isFlashing, setIsFlashing] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#fff');

  useEffect(() => {
    if (id === changedItemId) {
      setIsFlashing(true);
      const timer = setTimeout(() => {
        setIsFlashing(false);
      }, 1000);
      // return () => clearTimeout(timer);
    }
  }, [id, changedItemId]);

  useEffect(() => {
    if (isUsing) {
      setBackgroundColor('#eee');
    } else {
      setBackgroundColor(isFlashing ? '#ffd' : '#fff');
    }
  }, [isFlashing, isUsing]);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    padding: '8px',
    margin: '4px',
    border: '1px solid #ccc',
    backgroundColor,
    cursor: 'grab',
  };

  const { truckDisplayStatus, selectedGroups, allDeliverySelected, selectedDeliveries, selectedCycleIndexes } = item.deliveryConditions;
  let groupCondition = 'すべて表示する';
  if (!selectedGroups.map((it) => it.id).includes(0)) {
    groupCondition = selectedGroups.filter((it) => it.id).length > 0 ? selectedGroups.filter((it) => it.id).map((it) => it.name).join(',') : 'グループ選択なし';
  }
  let truckSelection = '';
  if (allDeliverySelected) {
    truckSelection = '全選択';
  } else {
    const cycles: { [key: number]: number[] } = selectedCycleIndexes.reduce((prev, it) => {
      prev[it.deliveryId] = it.cycleIndexes;
      return prev;
    }, {});
    truckSelection = selectedDeliveries?.map((it) => [
      '【',
      it.licensePlateValue,
      ['(', it.driverName, ')'].join(''),
      cycles[it.deliveryId].map((c) => [c, '回転'].join('')).join(','),
      '】',
    ].join(' ')).join(', ');
  }
  const truckCondition = [
    ['表示条件: ', truckDisplayStatus].join(''),
    ['グループ条件: ', groupCondition].join(''),
    ['選択状態: ', truckSelection].join('')
  ].join(', ');
  const { orderSearchKw, orderSearchConditions } = item.orderConditions;
  const noOrderCondition = !orderSearchKw && orderSearchConditions.length === 0;
  const translateKey = (key: string) => (orderSearchProperties.find((it) => it.key === key)?.value);
  const orderCondition = orderSearchConditions.map((it) => ({ ...it, key: translateKey(it.key) }))
                          .filter((it) => it.key) // なくなった検索要素(keyを変換できなかったもの)を除外する
                          .map((it, idx) => [idx === 0 ? '' : it.logicalOperator, it.key, it.operator, it.value].join(' ')).join(' ');

  const {
    howToPlanning, planningPriority, priorityLargeTrucks, availabilityOfExpressway, includeFutureDeliveries,
    respectRecentPlan, respectType, respectOn, expandLoadStartMinutes, expandLoadEndMinutes, expandUnloadStartMinutes, expandUnloadEndMinutes,
    expandMaxLoadCapacityRate, expandMaxVolumeRate, selectedRelaxedRules,
  } = item.planningConditions;

  const txthowToPlanning = howToPlanning !== '最適化' ? ['計算方法:', howToPlanning].join('') : '';
  const txtPlanningPriority = planningPriority !== '指定なし' ? planningPriority : '';
  const txtPriorityLargeTrucks = priorityLargeTrucks !== 0.5 ? ['小型/大型優先:', (priorityLargeTrucks * 100)].join('') : '';
  const txtAvailabilityOfExpressway = availabilityOfExpressway ? '有料道路使用: ON' : '';
  const txtIncludeFutureDeliveries = includeFutureDeliveries ? '宵積み有効: ON' : '';
  let txtRespectRecentPlan = '';
  if (respectRecentPlan) {
    txtRespectRecentPlan = ['過去の計画: ', respectType === 'day_of_week' ? '日付指定なし' : respectOn].join('');
  }
  const txtExpandLoadStartMinutes = expandLoadStartMinutes > 0 ? ['集荷開始: ', expandLoadStartMinutes, '分緩和'].join('') : '';
  const txtExpandLoadEndMinutes = expandLoadEndMinutes > 0 ? ['集荷締切: ', expandLoadEndMinutes, '分緩和'].join('') : '';
  const txtExpandUnloadStartMinutes = expandUnloadStartMinutes > 0 ? ['納品開始: ', expandUnloadStartMinutes, '分緩和'].join('') : '';
  const txtExpandUnloadEndMinutes = expandUnloadEndMinutes > 0 ? ['納品締切: ', expandUnloadEndMinutes, '分緩和'].join('') : '';
  const txtExpandMaxLoadCapacityRate = expandMaxLoadCapacityRate > 0 ? ['重量: ', expandMaxLoadCapacityRate, '%緩和'].join('') : '';
  const txtExpandMaxVolumeRate = expandMaxVolumeRate > 0 ? ['体積: ', expandMaxVolumeRate, '%緩和'].join('') : '';
  const txtRelacedRules = selectedRelaxedRules.length > 0 ? ['無効にする制約: ', selectedRelaxedRules.join(', ')].join('') : '';

  const planningCondition = [
    txthowToPlanning, txtPlanningPriority, txtPriorityLargeTrucks, txtAvailabilityOfExpressway, txtIncludeFutureDeliveries,
    txtRespectRecentPlan, txtExpandLoadStartMinutes, txtExpandLoadEndMinutes, txtExpandUnloadStartMinutes, txtExpandUnloadEndMinutes,
    txtExpandMaxLoadCapacityRate, txtExpandMaxVolumeRate, txtRelacedRules,
  ].filter((it) => it).join(', ');

  const createdAt = ['(', datetimeDecorator.toYyyyMmDdHhMm(new Date(item.createdAt)), ')'].join('');

  const [expanded, setExpanded] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const boxHeight = 140 as const;

  useEffect(() => {
    if (contentRef.current.scrollHeight > boxHeight) {
      setShowExpandButton(true);
    } else {
      setShowExpandButton(false);
    }
  }, []);

  const [truckConditionExpanded, setTruckConditionExpanded] = useState(false);
  const truckConditionMaxLength = 100;

  const handleExpandClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setTruckConditionExpanded(true);
  };

  const handleCollapseClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setTruckConditionExpanded(false);
  };

  return (
    <div style={style} ref={setNodeRef} {...attributes} {...listeners}>
      <div
        ref={contentRef}
        style={{
          height: expanded ? 'auto' : `${showExpandButton ? (boxHeight - 24) : boxHeight}px`,
          overflow: 'hidden',
          transition: 'height 0.3s ease',
        }}
      >
        <Stack>
          <Stack spacing={1}>
            <Stack>
              <div>
                <Stack direction="row" justifyContent="space-between">
                  <Stack><Typography variant="h5">トラック条件</Typography></Stack>
                  <Stack><Typography variant="body2">{createdAt}</Typography></Stack>
                </Stack>
              </div>
              <Stack>
                {truckCondition.length > truckConditionMaxLength ? (
                  <Typography>
                    {truckConditionExpanded ? truckCondition : (
                      <>
                        {truckCondition.slice(0, truckConditionMaxLength)}
                        ...
                        <Button
                          onClick={handleExpandClick}
                          size="small"
                          sx={{ minWidth: 'auto', ml: 1, p: 0, textTransform: 'none' }}
                        >
                          [&gt;&gt;]
                        </Button>
                      </>
                    )}
                    {truckConditionExpanded && (
                      <Button
                        onClick={handleCollapseClick}
                        size="small"
                        sx={{ minWidth: 'auto', ml: 1, p: 0, textTransform: 'none' }}
                      >
                        [&lt;&lt;]
                      </Button>
                    )}
                  </Typography>
                ) : (
                  <Typography>{truckCondition}</Typography>
                )}
              </Stack>
            </Stack>
            <Stack>
              <Stack><Typography variant="h5">案件条件</Typography></Stack>
              <Stack direction="row">
                {noOrderCondition && '検索条件なし'}
                {orderCondition && ['詳細検索: ', orderCondition].join('')}
                {orderSearchKw && ['フリーワード: ', orderSearchKw].join('')}
              </Stack>
            </Stack>
            <Stack>
              <Stack>
                <Stack><Typography variant="h5">配車設定</Typography></Stack>
                <Stack>
                  {planningCondition === '' ? 'デフォルト設定' : planningCondition}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </div>
      {showExpandButton && (
        <Button fullWidth sx={{ p: 0, m: 0 }} onClick={() => setExpanded(!expanded)}>
          {expanded ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />}
        </Button>
      )}
    </div>
  );
});

type SortableContent = { id: string, content: PlanningSetting };

type Props = {
  dialogIsOpen: boolean;
  setDialogIsOpen: (open: boolean) => void;
  customInputFields: string[];
  planningScenario: PlanningScenario | null;
}

const ScenarioPlanningEditDialog: FC<Props> = memo(({
  dialogIsOpen,
  setDialogIsOpen,
  customInputFields,
  planningScenario,
}) => {
  const licenseContext = useContext(LicenseContext);
  const { enqueueSnackbar } = useSnackbar();
  const { addScenario, updateScenario } = useMutationPlanningScenario();
  const customInputProperties: OrderPropertyEntity[] = useMemo(() => (
    customInputFields ? customInputFields.map((it) => ({
      key: it,
      value: it,
      type: 'string'
    })) : []
  ), [customInputFields]);

  const orderSearchProperties: OrderPropertyEntity[] = useMemo(() => (
    [
      ...customInputProperties,
      ...SearchOrderProperties
    ]
  ), [customInputProperties]);

  const gaClickEvent = useCallback((buttonName: string) => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.SCENARIO_EDIT, button_name: buttonName, });
  }, []);

  const onClose = () => {
    gaClickEvent('キャンセル');
    setDialogIsOpen(false);
  };

  const [scenarioNameError, setScenarioNameError] = useState<string>(null);
  const [scenarioLengthError, setScenarioLengthError] = useState<string>(null);

  const onSubmit = () => {
    gaClickEvent('保存');
    let error = false;
    setScenarioNameError(null);
    setScenarioLengthError(null);

    if (!scenarioName) {
      error = true;
      setScenarioNameError('シナリオ名を設定してください。');
    }
    if (rightItems.length === 0) {
      error = true;
      setScenarioLengthError('シナリオを設定してください。');
    }

    if (error) {
      return;
    }

    const scenarios = rightItems.map((it) => it.content).filter((it) => it);
    if (planningScenario) {
      planningScenario.name = scenarioName;
      planningScenario.planningScenarioItemsAttributes = scenarios.map((it, idx) => (
        { planningSettingId: it.id, planningSetting: it, position: idx }
      ));
      updateScenario.mutate(planningScenario, {
        onSuccess: () => {
          setDialogIsOpen(false);
          enqueueSnackbar('シナリオを更新しました', { variant: 'success' });
        },
        onError: (e: AxiosError<{errors: string[]}>) => {
          enqueueSnackbar(`シナリオの更新に失敗しました。${e.response.data.errors.join(', ')}`, { variant: 'error' });
        },
      });
    } else {
      const companyId = licenseContext.config.selected_company_id;
      const companyName = licenseContext.config.selectable_companies.find((it) => it.id === companyId).name;
      const newScenario: PlanningScenarioRequest = {
        companyId,
        companyName,
        name: scenarioName,
        planningScenarioItemsAttributes: scenarios.map((it, idx) => (
          { planningSettingId: it.id, planningSetting: it, position: idx }
        )),
      };
      addScenario.mutate(newScenario, {
        onSuccess: () => {
          setDialogIsOpen(false);
          enqueueSnackbar('シナリオを追加しました', { variant: 'success' });
        },
        onError: (e: AxiosError<{errors: string[]}>) => {
          enqueueSnackbar(`シナリオの追加に失敗しました。${e.response.data.errors.join(', ')}`, { variant: 'error' });
        },
      });
    }
  };

  const { data: planningSettings, isLoading } = useQueryPlanningSettings(dialogIsOpen);
  const [leftItems, setLeftItems] = useState<SortableContent[]>([]);
  const [rightItems, setRightItems] = useState<SortableContent[]>([]);
  const [tempRightItems, setTempRightItems] = useState<SortableContent[]>(null);
  const [activeId, setActiveId] = useState<string>(null);
  const [activeItem, setActiveItem] = useState<PlanningSetting>(null);
  const [scenarioName, setScenarioName] = useState<string>('');
  const [changedItemId, setChangedItemId] = useState<string>(null);

  useEffect(() => {
    setScenarioNameError(null);
    setScenarioLengthError(null);
  }, [dialogIsOpen]);

  useEffect(() => {
    if (!planningSettings) return;

    if (planningScenario) {
      setScenarioName(planningScenario.name);
      const right: SortableContent[] = planningScenario.planningScenarioItemsAttributes.map((it: PlanningScenarioItem, index) => (
        { id: `right-${index}-${it.planningSetting.id}`, content: it.planningSetting }
      ));
      setRightItems(right);
    } else {
      setScenarioName('');
      setRightItems([]);
    }
    setLeftItems(planningSettings.map((it) => (
      { id: `left-${it.id}`, content: it }
    )));
  }, [dialogIsOpen, planningScenario, planningSettings]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  const findContainer = (id: string) => {
    if (leftItems.some((item) => item.id === id)) return 'left';
    if (rightItems.some((item) => item.id === id)) return 'right';
    return id;
  };

  const handleDragStart = (event: DragStartEvent) => {
    const aId = `${event.active.id}`;
    const aItem = leftItems.find((it) => it.id === aId) || rightItems.find((it) => it.id === aId);
    if (aId && aItem && aItem.content) {
      setActiveId(aId);
      setActiveItem(aItem.content);
      setTempRightItems(rightItems);
    }
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    const aId = `${active.id}`;
    const oId = `${over?.id}`;

    const activeContainer = findContainer(aId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    const overContainer = findContainer(oId || over?.data?.current?.sortable?.containerId);

    if (!overContainer) return;

    if (activeContainer === overContainer) {
      if (activeContainer === 'right') {
        setTempRightItems((items) => {
          const activeIndex = items.findIndex((it) => it.id === aId);
          const overIndex = items.findIndex((it) => it.id === oId);
          return arrayMove(items, activeIndex, overIndex);
        });
      }
      return;
    }

    if (overContainer === 'right') {
      setTempRightItems((items) => {
        if ((items.findIndex((it) => it.id === aId) >= 0)) {
          // 左から持ってきてすでに右に入っているものは並べ替える
          const activeIndex = items.findIndex((it) => it.id === aId);
          const overIndex = items.findIndex((it) => it.id === oId);
          return arrayMove(items, activeIndex, overIndex);
        }
        const newIndex = items.findIndex((it) => it.id === oId);
        const newContent = leftItems.find((item) => item.id === active.id).content;
        const newItem: SortableContent = { id: aId, content: newContent };

        if (newIndex === -1) {
          return [...items, newItem];
        }
        return [...items.slice(0, newIndex), newItem, ...items.slice(newIndex)];
      });
    } else if (overContainer === 'left') {
      setTempRightItems((items) => items.filter((item) => item.id !== aId));
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) {
      setActiveId(null);
      setActiveItem(null);
      setTempRightItems(null);
      return;
    }

    const aId = `${active.id}`;
    const oId = `${over?.id}`;

    const activeContainer = findContainer(aId);
    const overContainer = findContainer(oId);

    let rItems = tempRightItems;

    if (activeContainer === overContainer) {
      if (activeContainer === 'right') {
        // 右側の並べ替え
        const oldIndex = tempRightItems.findIndex((it) => it.id === aId);
        const newIndex = tempRightItems.findIndex((it) => it.id === oId);
        rItems = arrayMove(tempRightItems, oldIndex, newIndex);
      }
    } else if (overContainer === 'left') {
      // 右から左に移動した場合は削除
      rItems = tempRightItems.filter((it) => it.id !== aId);
    }
    let cId = '';
    setRightItems((items) => rItems.map((it, idx) => {
        const newId = `right-${idx}-${it.content.id}`;
        if (it.id === aId) {
          cId = newId;
        }
        return { id: newId, content: it.content };
      }));
    setChangedItemId(cId);

    // IDを変えないとドラッグできなくなってしまうので変える
    const time = new Date().getTime();
    setLeftItems((items) => items.map((it) => ({ id: `left-${time}-${it.id}`, content: it.content })));

    setActiveId(null);
    setActiveItem(null);
    setTempRightItems(null);
    setScenarioLengthError(null);
  };

  const handleDragCancel = () => {
    setActiveId(null);
    setActiveItem(null);
    setTempRightItems(null);
  };

  const onChangeScenarioName = (name: string) => {
    setScenarioName(name);
    setScenarioNameError(null);
  };

  if (!dialogIsOpen || isLoading) {
    return null;
  }

  return (
    <Dialog
      fullScreen
      fullWidth
      open={dialogIsOpen}
      sx={{ mt: 8 }}
    >
      <DialogTitle>シナリオ編集</DialogTitle>
      <DialogContent>
        <Stack sx={{ mb: 2 }}>
          <Typography
            variant="h4"
            sx={{
              mb: 1
            }}
          >
            シナリオ名
          </Typography>
          <TextField
            value={scenarioName}
            onChange={(e) => onChangeScenarioName(e.target.value)}
            size="small"
            variant="standard"
          />
          <FormHelperText error>{scenarioNameError}</FormHelperText>
        </Stack>
        <DndContext
          sensors={sensors}
          collisionDetection={pointerWithin}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DroppableContainer id="left" isSortable={false}>
              <h3>操作履歴</h3>
              <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 355px)' }}>
                {leftItems.map((it) => (
                  <SortableItem
                    key={it.id}
                    id={it.id}
                    item={it.content}
                    orderSearchProperties={orderSearchProperties}
                    isUsing={rightItems.find((itm) => itm.content.id === it.content.id) !== undefined}
                  />
                ))}
              </div>
            </DroppableContainer>
            <DroppableContainer id="right" isSortable>
              <h3>シナリオ</h3>
              <FormHelperText error>{scenarioLengthError}</FormHelperText>
              <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 355px)' }}>
                <SortableContext items={(tempRightItems ?? rightItems).map((it) => it.id)} strategy={verticalListSortingStrategy}>
                  {(tempRightItems ?? rightItems).map((it) => (
                    <SortableItem
                      key={it.id}
                      id={it.id}
                      item={it.content}
                      orderSearchProperties={orderSearchProperties}
                      changedItemId={changedItemId}
                    />
                  ))}
                </SortableContext>
              </div>
            </DroppableContainer>
            <DragOverlay>
              {activeId ? (
                <SortableItem id={activeId} item={activeItem} orderSearchProperties={orderSearchProperties} />
              ) : null}
            </DragOverlay>
          </div>
        </DndContext>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
        >
          キャンセル
        </Button>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
        >
          保存する
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default ScenarioPlanningEditDialog;
