import { format } from 'date-fns';
import React, { FC, memo, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { PresenterProps } from 'src/components/OperationDirectionsComponent';
import LicenseContext from 'src/contexts/LicenseContext';
import numberDecorator from 'src/decorators/number.decorator';
import { DirectionDisplaySettingEntity } from 'src/entities/DirectionDisplaySetting.entity';
import { DisplayOperationRowEntity } from 'src/entities/DisplayOperationRow.entity';
import { ShiftWithCycleModel } from 'src/models/ShiftModel';

import style from './print.module.css';

export type ActionKind = '積' | '降' | null;

const OperationDirectionPresenter: FC<PresenterProps> = memo((
  {
    shiftWithCycleModel,
    action,
    unit,
    operations,
    isYC,
    operationDiffs,
    shiftWithCycleModels,
  }
) => {
  const displayVolume = unit !== '才';
  const displayUnit = unit !== '才' ? '重量' : '才数';
  const licenseContext = useContext(LicenseContext);
  const settings: DirectionDisplaySettingEntity[] = licenseContext?.displayDirectionConditions?.find((it) => it.name === 'default')?.settings || [];
  const headers: string[] = settings.map((it: DirectionDisplaySettingEntity) => it.title);
  const act: ActionKind = action === '降' ? '降' : null;

  const propertyNamesThatCanBeGrouped = [
    '運転手名',
    '車両番号',
    '拠点名',
    '到着予定時刻',
    '出発予定時刻',
    '作業場所住所',
    '作業場所緯度',
    '作業場所経度',
  ];

  const propertyNamesThatCanBeSummed = [
    '合計重量',
    '合計体積',
    '数量'
  ];

  const propertyNamesThatCanBeSummedWithIndex = propertyNamesThatCanBeSummed.map((it) => {
    const idx = headers.indexOf(it);

    if (idx === -1) return null;

    return {
      idx,
      name: it
    };
  }).filter((maybe) => maybe)
    .sort((a, b) => a.idx - b.idx);

  const deletedOrders: { toShift: ShiftWithCycleModel, deletedOperations: DisplayOperationRowEntity[] }[] = [];
  const displayKyes = ['指定トラック', '作業場所', '作業場所住所', '合計重量', '合計体積', '数量', '備考'].concat(licenseContext.config?.custom_input_fields || []);
  if (isYC) {
    const operationDiff = operationDiffs[shiftWithCycleModel.driver.id];

    if (operationDiff?.deleted && operationDiff.deleted.length > 0) {
      operationDiff.deleted.forEach((it) => {
        const toShift = shiftWithCycleModels.find((r) => r.entity.id === it.to_shift_id);
        const operationIds = toShift.allPlaces('降').flatMap((place) => place.allOperations('降'))
                                    .filter((ops) => it.order_ids.includes(ops.getOrderID())).map((ops) => ops.getID());
        const deletedOperations = operations.filter((ops) => operationIds.includes(Number(ops.find((r) => r.key === 'id').value)));
        deletedOrders.push({ toShift, deletedOperations });
      });
    }
  }

  const shiftId = shiftWithCycleModel.entity.id;

  return (
    <>
      <Helmet>
        <title>配送指示書</title>
      </Helmet>
      <div className={style.page}>
        <h1>
          {format(shiftWithCycleModel.startAt(), 'yyyy-MM-dd')}
          {' '}
          配送指示書
        </h1>
        <div className={style.flex}>
          <div className={style.box}>
            <table>
              <tbody>
                <tr>
                  <th>ドライバー</th>
                  <td style={{ whiteSpace: 'nowrap' }}>{shiftWithCycleModel.driverName()}</td>
                </tr>
                <tr>
                  <th>電話番号</th>
                  <td style={{ whiteSpace: 'nowrap' }}>{shiftWithCycleModel.driverPhoneNumber() || '-'}</td>
                </tr>
                <tr>
                  <th>車両番号</th>
                  <td style={{ whiteSpace: 'nowrap' }}>{shiftWithCycleModel.licensePlateValue()}</td>
                </tr>
                <tr>
                  <th>{`最大積載${displayUnit}`}</th>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {shiftWithCycleModel.maximumLoadingCapacityKg()}
                  </td>
                </tr>
                {displayVolume && (
                  <tr>
                    <th>最大積載立米</th>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      {shiftWithCycleModel.loadingPlatformVolumeM3() ? `${shiftWithCycleModel.loadingPlatformVolumeM3()} m3` : ''}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {licenseContext.config?.operation_direction_memo && (
            <div className={style.flex}>
              <div className={style.box}>
                {
                  (licenseContext.config?.operation_direction_memo || '').split('\n').map((it) => (
                    <div>{it}</div>
                  ))
                }
              </div>
            </div>
          )}
          <div className={[style.box, style.flex].join(' ')}>
            <table>
              <tbody>
                <tr>
                  <th>積込先数</th>
                  <td className={style.textRight}>{shiftWithCycleModel.placeCount('積')}</td>
                </tr>
                <tr>
                  <th>積込件数</th>
                  <td style={{ whiteSpace: 'nowrap' }} className={style.textRight}>{shiftWithCycleModel.operationCount('積')}</td>
                </tr>
                <tr>
                  <th>総数量</th>
                  <td style={{ whiteSpace: 'nowrap' }} className={style.textRight}>{shiftWithCycleModel.itemCount('積')}</td>
                </tr>
                <tr>
                  <th>{`総${displayUnit}`}</th>
                  <td style={{ whiteSpace: 'nowrap' }} className={style.textRight}>{`${numberDecorator.round(shiftWithCycleModel.totalWeightKg('積'), 2)}`}</td>
                </tr>
                {displayVolume && (
                  <tr>
                    <th>総立米</th>
                    <td style={{ whiteSpace: 'nowrap' }} className={style.textRight}>{numberDecorator.round(shiftWithCycleModel.totalVolume('積'), 2)}</td>
                  </tr>
                )}
              </tbody>
            </table>
            <table>
              <tbody>
                <tr>
                  <th>納品先数</th>
                  <td style={{ whiteSpace: 'nowrap' }} className={style.textRight}>{shiftWithCycleModel.placeCount('降')}</td>
                </tr>
                <tr>
                  <th>納品件数</th>
                  <td style={{ whiteSpace: 'nowrap' }} className={style.textRight}>{shiftWithCycleModel.operationCount('降')}</td>
                </tr>
                <tr>
                  <th>総数量</th>
                  <td style={{ whiteSpace: 'nowrap' }} className={style.textRight}>{shiftWithCycleModel.itemCount('降')}</td>
                </tr>
                <tr>
                  <th>{`総${displayUnit}`}</th>
                  <td style={{ whiteSpace: 'nowrap' }} className={style.textRight}>{numberDecorator.round(shiftWithCycleModel.totalWeightKg('降'), 2)}</td>
                </tr>
                {displayVolume && (
                  <tr>
                    <th>総立米</th>
                    <td style={{ whiteSpace: 'nowrap' }} className={style.textRight}>{numberDecorator.round(shiftWithCycleModel.totalVolume('降'), 2)}</td>
                  </tr>
                )}
              </tbody>
            </table>
            <table>
              <tbody>
                <tr>
                  <th>重量積載率</th>
                  <td style={{ whiteSpace: 'nowrap' }} className={style.textRight}>{shiftWithCycleModel.loadingWeightRateText()}</td>
                </tr>
                <tr>
                  <th>体積積載率</th>
                  <td style={{ whiteSpace: 'nowrap' }} className={style.textRight}>{shiftWithCycleModel.loadingVolumeRateText()}</td>
                </tr>
                <tr>
                  <th>稼働率</th>
                  <td style={{ whiteSpace: 'nowrap' }} className={style.textRight}>{shiftWithCycleModel.utilizationRateText()}</td>
                </tr>
                <tr>
                  <th>回転数</th>
                  <td style={{ whiteSpace: 'nowrap' }} className={style.textRight}>{shiftWithCycleModel.cycleCountText()}</td>
                </tr>
                <tr>
                  <th>総距離</th>
                  <td style={{ whiteSpace: 'nowrap' }} className={style.textRight}>
                    {shiftWithCycleModel.totalDistanceRoundKm()}
                    km
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <table style={{ width: '100%', margin: '0' }} key={['OperationDirectionPresenter', 'table'].join('-')}>
          <thead style={{ border: '1px solid #000' }} key={['OperationDirectionPresenter', 'headers', 'thead'].join('-')}>
            <tr key={['OperationDirectionPresenter', 'headers', 'tr'].join('-')}>
              {
              headers.map((header) => (
                <th
                  key={
                    [
                      'OperationDirectionPresenter',
                      'headers',
                      header
                    ].join('-')
                  }
                >
                  {header}
                </th>
              ))
            }
            </tr>
          </thead>
          {shiftWithCycleModel.allPlaces(act).map((place, placeIndex) => {
            const map = operations.reduce((acc, it) => {
              const id = it.find((r) => r.key === 'id').value;
              return acc.set(Number(id), it);
            }, new Map<number, DisplayOperationRowEntity>());
            const placeOperations = place.allOperations(act).sort((a, b) => a.compare(b));
            const currentOperationAttributes = placeOperations.map((ops) => map.get(ops.getID()));
            const displayOperationAttributes = currentOperationAttributes
              .map((currentOperationAttribute) => headers.map((header) => currentOperationAttribute.find((r) => r.key === header)));
            return (
              <React.Fragment key={[shiftId, placeIndex, place.latlng, 'fragment'].join('-')}>
                <tbody
                  style={{ border: '1px solid #000' }}
                  key={[shiftId, placeIndex, place.latlng].join('-')}
                >
                  {displayOperationAttributes.map((it, index) => (
                    <tr key={[shiftId, placeIndex, place.latlng, index, 'tr'].join('-')}>
                      {it.map((attr) => (
                        ((index === 0 || !propertyNamesThatCanBeGrouped.includes(attr.key)) && (
                          <td
                            key={[shiftId, placeIndex, place.latlng, index, attr.key].join('-')}
                            rowSpan={index === 0 && propertyNamesThatCanBeGrouped.includes(attr.key) ? place.allOperations(act).length : 1}
                            style={{ textAlign: ['Integer', 'Float'].includes(attr.klass) ? 'right' : 'left' }}
                          >
                            {
                              (() => {
                                if (attr.key === '到着予定時刻') {
                                  return format(place.arrivalAt(), 'HH:mm');
                                }
                                if (['Integer', 'Float'].includes(attr.klass)) {
                                  return Number(attr.value).toLocaleString();
                                }
                                return attr.value;
                              })()
                            }
                          </td>
                        ))
                      ))}
                    </tr>
                  ))}
                </tbody>
                {(place.allOperations(act).length > 1 && !!propertyNamesThatCanBeSummedWithIndex.length) ? (
                  <tbody
                    style={{ border: '1px solid #000' }}
                    key={[shiftId, placeIndex, place.latlng, 'subtotal'].join('-')}
                  >
                    <tr className={style.summery} key={[shiftId, placeIndex, place.latlng, 'subtotal', 'tr'].join('-')}>
                      {
                        headers.map((header, index) => {
                          const sumCurrent = propertyNamesThatCanBeSummed.includes(header);
                          const isLast = index === headers.length - 1;
                          const hasBorder = propertyNamesThatCanBeSummedWithIndex.map((it) => it.idx - 1)
                            .includes(index) || isLast || sumCurrent;
                          const sumValue = () => {
                            if (header === '合計重量') return numberDecorator.round(place.itemWeightKg(act), 2);
                            if (header === '数量') return place.itemCount(act);
                            if (header === '合計体積' && place.itemVolumeM3(act) > 0) return numberDecorator.round(place.itemVolumeM3(act), 2);

                            return '';
                          };
                          return (
                            <td
                              key={[placeIndex, place.latlng, index, header, 'sum'].join('-')}
                              style={{
                                borderRightWidth: hasBorder ? '1px' : '0',
                                textAlign: ['数量', '合計重量', '合計体積'].includes(header) ? 'right' : 'left'
                              }}
                            >
                              {
                                (() => {
                                  if (index === 0 && sumValue() === '') {
                                    return '小計';
                                  }
                                  if (['数量', '合計重量', '合計体積'].includes(header)) {
                                    return sumValue().toLocaleString();
                                  }
                                  return sumValue();
                                })()
                              }
                            </td>
                          );
                        })
                      }
                    </tr>
                  </tbody>
                ) : (
                  <tbody
                    style={{ border: '1px solid #000' }}
                    key={[shiftId, placeIndex, place.latlng, 'subtotal'].join('-')}
                  >
                    <tr className={style.summery} key={[shiftId, placeIndex, place.latlng, 'subtotal', 'tr'].join('-')}>
                      <td colSpan={headers.length} className="text-center" key={[shiftId, placeIndex, place.latlng, 'subtotal', 'td'].join('-')}> </td>
                    </tr>
                  </tbody>
                )}
              </React.Fragment>
            );
          })}
          {
            !!propertyNamesThatCanBeSummedWithIndex.length && (
              <tbody style={{ border: '1px solid #000' }} key={[shiftId, 'total', 'tbody'].join('-')}>
                <tr className={style.summery} key={[shiftId, 'total', 'tr'].join('-')}>
                  {
                    headers.map((header, index) => {
                      const sumCurrent = propertyNamesThatCanBeSummed.includes(header);
                      const isLast = index === headers.length - 1;
                      const hasBorder = propertyNamesThatCanBeSummedWithIndex.map((it) => it.idx - 1)
                        .includes(index) || isLast || sumCurrent;

                      const sumValue = () => {
                        if (header === '合計重量') return shiftWithCycleModel.totalWeightKg(act).toLocaleString();
                        if (header === '数量') return shiftWithCycleModel.itemCount(act).toLocaleString();
                        if (header === '合計体積' && shiftWithCycleModel.totalVolume(act) > 0) return shiftWithCycleModel.totalVolume(act).toLocaleString();

                        return '';
                      };

                      return (
                        <td
                          key={
                            [shiftId, 'sum', header, index].join('-')
                          }
                          style={{
                            borderRightWidth: hasBorder ? '1px' : '0',
                            textAlign: ['数量', '合計重量', '合計体積'].includes(header) ? 'right' : 'left',
                          }}
                        >
                          {index === 0 && sumValue() === '' ? '合計' : sumValue()}
                        </td>
                      );
                    })
                  }
                </tr>
              </tbody>
            )
          }
          {isYC && (
            deletedOrders.map((it) => (
              <tbody style={{ borderLeft: 'none' }}>
                <tr style={{ borderLeft: 'none' }}>
                  <td colSpan={headers.length} style={{ borderLeft: '0px none #fff', borderRight: 'none' }}>
                    <h3>{`${shiftWithCycleModel.truck.licensePlateValue} → ${it.toShift.truck.licensePlateValue}`}</h3>
                  </td>
                </tr>
                {it.deletedOperations.map((ops) => (
                  <tr style={{ border: '1px solid #000' }}>
                    {
                      headers.map((header) => {
                        const attr = ops.find((r) => r.key === header);
                        const value = displayKyes.includes(header) ? attr.value : '';
                        return (
                          <td>{value}</td>
                        );
                      })
                    }
                  </tr>
                ))}
              </tbody>
            ))
          )}
        </table>
      </div>
    </>
  );
});

export default OperationDirectionPresenter;
