import {
  Box,
  LinearProgress,
  Paper,
  Theme,
  ThemeOptions, Backdrop, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { jaJP, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import {
  DataGridPremium,
  GridColumnResizeParams, GridFilterItem, GridFilterModel,
  GridRowParams,
  GridSortModel,
  useGridApiRef
} from '@mui/x-data-grid-premium';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { format } from 'date-fns-tz';
import { useSnackbar } from 'notistack';
import { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { SubmitHandler } from 'react-hook-form';
import { appBarHeight } from 'src/constants/layout';
import LicenseContext from 'src/contexts/LicenseContext';
import { PlaceEntity, PlaceRequestEntity } from 'src/entities/placeEntity';
import { RansackPredicateEntity } from 'src/entities/RansackPredicate.entity';
import { useDeleteAllPlacesMutation } from 'src/hooks/useDeleteAllPlaces.mutation';
import { queryKey, usePlacesRequest as usePaginationRequest } from 'src/hooks/usePlaces.request';
import { useMutationPlace } from 'src/hooks/useQueryPlaces';
import { dataGridUtil } from 'src/utils/dataGrid.util';
import operatorValueUtil from 'src/utils/operatorValue.util';
import stringUtil from 'src/utils/string.util';

import { SelectAllSnackbars } from '../common/SelectAllSnackbars';

import { Columns } from './Columns';
import CustomToolbar from './CustomToolbar';
import FormDialog from './formDialog';
import CoordinationEditDialog from './PositionEditDialog';

const V2PlacesPresenter: FC = memo(() => {
  const localStorageItemPrefix = 'place-columns';
  const theme: Theme & ThemeOptions = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const licenseContext = useContext(LicenseContext);
  const queryClient = useQueryClient();

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(1);
  const [rowCount, setRowCount] = useState<number>(0);
  const [sortCondition, setSortCondition] = useState<string>('');
  const [orConditions, setOrConditions] = useState<RansackPredicateEntity[]>([]);
  const [andConditions, setAndConditions] = useState<RansackPredicateEntity[]>([]);
  const [searchCondition, setSearchCondition] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [entity, setEntity] = useState<PlaceRequestEntity | PlaceEntity>(null);
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [coordinationDialogIsOpen, setCoodinationDialogIsOpen] = useState<boolean>(false);
  const [canDeleteAll, setCanDeleteAll] = useState<boolean>(false);
  const apiRef = useGridApiRef();

  const { data: requestData, isFetching: requestIsFetching } = usePaginationRequest(page, sortCondition, searchCondition);
  const { data: allRequestData } = usePaginationRequest(0, sortCondition, searchCondition, Number.MAX_SAFE_INTEGER);
  const { post } = useDeleteAllPlacesMutation();

  const ONLY_EXPORT_COLUMNS = { latitude: false, longitude: false } as const;

  const deleteAll = (settledFnk: () => void) => {
    if (window.confirm(`選択された ${rowCount} 件の地点を削除します。よろしいですか？`)) {
      setIsLoading(true);

      post.mutate(undefined, {
        onSuccess: () => {
          enqueueSnackbar('すべて削除しました');
        },
        onError: (error: AxiosError<{ message: string; }>) => {
          enqueueSnackbar(error.response?.data?.message);
        },
        onSettled: () => {
          queryClient
            .invalidateQueries([queryKey])
            .finally(() => {
              settledFnk();
              setIsLoading(false);
            });
        }
      });
    }
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (sortModel.length === 0) {
      setSortCondition('');
      return;
    }

    setSortCondition(
      [
        stringUtil.toSnakeCase(sortModel[0].field),
        sortModel[0].sort
      ].join('+')
    );
  };

  const onFilterChange = ((filterModel: GridFilterModel) => {
    const items = filterModel?.items;

    if (!items || items.length === 0) {
      setOrConditions([]);
      setAndConditions([]);
      return;
    }

    const ransackPredicates: RansackPredicateEntity[] = items.flatMap((item: GridFilterItem) => operatorValueUtil.convertToRansackPredicate(
      stringUtil.toSnakeCase(item.columnField),
      item.operatorValue,
      item.value as string | string[] | undefined,
      filterModel.linkOperator
    ));

    setOrConditions(ransackPredicates.filter((predicate) => predicate.link === 'or'));
    setAndConditions(ransackPredicates.filter((predicate) => predicate.link === 'and'));
  });

  const { deletePlaces } = useMutationPlace();

  const toggleDialogIsOpen = useCallback(() => {
    setEntity(null);
    setDialogIsOpen(!dialogIsOpen);
  }, [dialogIsOpen]);

  const closeCoordinationDialog = useCallback(() => {
    setEntity(null);
    setCoodinationDialogIsOpen(!coordinationDialogIsOpen);
  }, [coordinationDialogIsOpen]);

  const onClickDeletePlaces = () => {
    if (selectedIds.length === 0) {
      enqueueSnackbar('削除する地点を選択してください');
      return;
    }

    const confirmMsg = `選択された ${selectedIds.length} 件の地点を削除します。よろしいですか？`;

    // eslint-disable-next-line no-alert
    if (window.confirm(confirmMsg)) {
      setIsLoading(true);
      deletePlaces.mutateAsync(selectedIds)
        .catch((e: AxiosError<{ message: string; }>) => {
          enqueueSnackbar(e.response.data?.message);
          setIsLoading(false);
        })
        .finally(() => {
          queryClient.invalidateQueries([queryKey]).finally(() => {
            setIsLoading(false);
          });
        });
    }
  };

  const openEdit: SubmitHandler<PlaceEntity> = async (
    data
    // eslint-disable-next-line @typescript-eslint/require-await
  ): Promise<void> => {
    setEntity(data);
    setDialogIsOpen(true);
  };

  const openMapEdit = (data: PlaceEntity) => {
    setEntity(data);
    setCoodinationDialogIsOpen(true);
  };

  const dataGridColumns: GridColDef[] = Columns(openEdit, openMapEdit)
    .filter((col) => {
      if (licenseContext?.config?.selectable_companies.length > 1) {
        return true;
      }
      return col.field !== 'company_name';
    });

  const onSelectionModelChange = (placeIds: number[]) => {
    if (placeIds.length === requestData.totalCount) {
      setSelectedIds(placeIds);
    } else {
      // 全選択以外は画面上の項目のみ選択できる
      const set = new Set(requestData.data.map((it) => it.id));
      setSelectedIds(placeIds.filter((id) => set.has(id)));
    }
  };

  const onRowDoubleClick = (params: GridRowParams) => {
    setEntity(params.row as unknown as PlaceEntity);
    setDialogIsOpen(true);
  };

  const columnVisibilityModel = () => {
    const savedColumnVisibilities = dataGridUtil.getSavedColumnVisibilities(localStorageItemPrefix);
    if (Object.keys(savedColumnVisibilities).length === 0) return ONLY_EXPORT_COLUMNS;

    return { ...savedColumnVisibilities, ...ONLY_EXPORT_COLUMNS };
  };

  useEffect(() => {
    setSearchCondition(
      [
        operatorValueUtil.convertToQueryParams('or_conditions', orConditions),
        operatorValueUtil.convertToQueryParams('and_conditions', andConditions),
      ].filter((maybe) => maybe).join('&')
    );
  }, [andConditions, orConditions]);

  useEffect(() => {
    setRowCount(requestData?.totalCount || 0);
    setPageSize(requestData?.perPage || 1);
  }, [requestData]);

  useEffect(() => {
    const canPaginate = rowCount > pageSize;

    if (!canPaginate) {
      setCanDeleteAll(false);
    }

    const selectedAll = selectedIds?.length === pageSize;

    setCanDeleteAll(selectedAll);
  }, [selectedIds, pageSize, rowCount]);

  const placeEditDialog = useMemo(() => (
    <FormDialog
      key="FormDialog"
      dialogIsOpen={dialogIsOpen}
      toggleDialogIsOpen={toggleDialogIsOpen}
      entity={entity}
    />
  ), [dialogIsOpen, entity, toggleDialogIsOpen]);

  const { updatePlace } = useMutationPlace();

  const [loading, setLoading] = useState<boolean>(false);
  const updateLatLng = useCallback((lat: string, lng: string) => {
    entity.latitude = lat;
    entity.longitude = lng;

    setLoading(true);
    updatePlace.mutate(entity as PlaceEntity, {
      onSuccess: () => { closeCoordinationDialog(); },
      onError: (e: AxiosError<{ message: string; }>) => { enqueueSnackbar(e.response.data.message); },
      onSettled: () => {
        queryClient.invalidateQueries([queryKey]).finally(() => {
          setLoading(false);
        });
      }
    });
  }, [enqueueSnackbar, entity, queryClient, closeCoordinationDialog, updatePlace]);

  const conditionEditDialog = useMemo(() => (
    (entity && 'id' in entity)
    && (
      <CoordinationEditDialog
        key="conditionEditDialog"
        dialogIsOpen={coordinationDialogIsOpen}
        closeDialog={closeCoordinationDialog}
        loading={loading}
        lat={entity.latitude}
        lng={entity.longitude}
        updateLatLng={updateLatLng}
      />
    )
  ), [coordinationDialogIsOpen, entity, loading, closeCoordinationDialog, updateLatLng]);

  const handleDownloadCSV = () => {
    const options = {
      utf8WithBom: true,
      fileName: `LOG-地点一覧-${format(new Date(), 'yyyyMMddHHmmss', { timeZone: 'Asia/Tokyo' })}`
    };

    apiRef.current.setColumnVisibilityModel({ ...columnVisibilityModel(), ...Object.keys(ONLY_EXPORT_COLUMNS).reduce((acc, v) => { acc[v] = true; return acc; }, {}) });
    apiRef.current.setRows(allRequestData.data);
    apiRef.current.exportDataAsCsv(options);
    apiRef.current.setRows(requestData.data);
    apiRef.current.setColumnVisibilityModel({ ...columnVisibilityModel(), ...ONLY_EXPORT_COLUMNS });
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setSelectedIds([]);
  };

  return (
    <>
      <Helmet>
        <title>地点管理</title>
      </Helmet>
      <Box p={2}>
        {dialogIsOpen && placeEditDialog}
        {coordinationDialogIsOpen && conditionEditDialog}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <SelectAllSnackbars
            totalPageCount={requestData?.totalPageCount || 1}
            totalCount={requestData?.totalCount || 0}
            perPage={pageSize}
            allIds={allRequestData?.data.map((it) => it.id) || requestData?.allIds || []}
            selectedIds={selectedIds}
            apiRef={apiRef}
            setSelectedIds={setSelectedIds}
          />
          <Box flexGrow={1}>
            <Paper
              style={{
                width: '100%',
                height: `calc(100vh - ${theme.spacing(4)} - ${appBarHeight}px)`
              }}
            >
              <DataGridPremium
                apiRef={apiRef}
                rows={requestData?.data || []}
                columns={dataGridColumns}
                pagination
                checkboxSelection
                disableRowGrouping
                initialState={{
                  columns: { columnVisibilityModel: columnVisibilityModel() }
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: CustomToolbar
                }}
                componentsProps={{
                  toolbar: {
                    toggleDialogIsOpen,
                    onClickDeletePlaces,
                    setIsLoading,
                    canDeleteAll,
                    deleteAll,
                    handleDownloadCSV,
                  }
                }}
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                loading={requestIsFetching || isLoading}
                onSelectionModelChange={onSelectionModelChange}
                selectionModel={selectedIds}
                onRowDoubleClick={onRowDoubleClick}
                disableSelectionOnClick
                sortingMode="server"
                paginationMode="server"
                filterMode="server"
                page={page}
                pageSize={pageSize}
                rowCount={rowCount}
                onPageChange={onPageChange}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onSortModelChange={handleSortModelChange}
                onFilterModelChange={onFilterChange}
                onColumnWidthChange={(params: GridColumnResizeParams) => {
                  dataGridUtil.setSavedColumnWidth(localStorageItemPrefix, params);
                }}
                onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) => {
                  dataGridUtil.setSavedColumnVisibilities(localStorageItemPrefix, newModel);
                }}
                keepNonExistentRowsSelected
              />
            </Paper>
          </Box>
        </Box>
        <Backdrop
          sx={{ color: '#fff' }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  );
});

export default V2PlacesPresenter;
