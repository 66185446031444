import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { TruckEntity } from 'src/entities/Truck.entity';

const requestPathBase = '/api/v3/trucks';
const queryPrefix = 'useTrucksQuery';

const getAll = (queryClient: QueryClient, on: string, allVisible?: boolean) => async () => {
  const requestPath = `${requestPathBase}?on=${on}&all_visible=${String(!!allVisible)}`;
  const axiosResponse = await axios.get<TruckEntity[]>(requestPath);
  const entities = axiosResponse.data;

  return entities.map((it) => {
    queryClient.setQueryData([queryPrefix, { id: it.id }], it);

    return it;
  });
};

const getRecord = (id: number) => async () => {
  const requestPath = `${requestPathBase}/${id}`;
  const axiosResponse = await axios.get<TruckEntity>(requestPath);

  return axiosResponse.data;
};

export const useTrucksQuery = (on: string, params: { enabled: boolean, allVisible?: boolean }) => {
  const queryClient = useQueryClient();

  return useQuery(
    [queryPrefix, on, params.allVisible],
    getAll(queryClient, on, params.allVisible),
    {
      enabled: params.enabled,
      staleTime: Infinity
    }
  );
};

export const useTruckQuery = (id: number) => useQuery(
  [queryPrefix, { id }],
  getRecord(id),
  {
    staleTime: Infinity
  }
);
