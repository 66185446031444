import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  List, ListItem, ListItemText,
  Stack, Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC, memo, MouseEvent, useEffect, useMemo, useState } from 'react';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import numberDecorator from 'src/decorators/number.decorator';
import { OrderEntity, TruckDriverIdAndName } from 'src/entities/orderEntity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsNotAllocReason } from 'src/entities/PlanningsNotAllocReasons.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';

import { NotAllocReasonPresenter } from './NotAllocReasonPresenter';

type Props = {
  data: OrderEntity;
  customInputFields: string[];
  hideOrderProperties: string[];
  onClickMenu: (event: MouseEvent<HTMLElement>) => void;
  notAllocReasons: PlanningsNotAllocReason[];
  truckEntities: PlanningsTruckEntity[];
  driverEntities: PlanningsDriverEntity[];
  deliveryEntities: PlanningsDeliveryEntity[];
}

const DisplayPropertiesSelectableUnallocatedOrderPresenter : FC<Props> = memo((
  {
    data,
    customInputFields,
    hideOrderProperties,
    onClickMenu,
    notAllocReasons,
    truckEntities,
    driverEntities,
    deliveryEntities,
  }
) => {
  const theme = useTheme();

  const [code, setCode] = useState('-');
  const [note, setNote] = useState('-');
  const [shipperName, setShipperName] = useState('-');
  const [phoneNumber, setPhoneNumber] = useState('-');
  const [emailAddress, setEmailAddress] = useState('-');
  const [loadingName, setLoadingName] = useState('');
  const [loadingAddress, setLoadingAddress] = useState('');
  const [loadingStartAt, setLoadingStartAt] = useState('');
  const [loadingEndAt, setLoadingEndAt] = useState('');
  const [loadingStayMinutes, setLoadingStayMinutes] = useState('- 分');
  const [unloadingName, setUnloadingName] = useState('');
  const [unloadingAddress, setUnloadingAddress] = useState('');
  const [unloadingStartAt, setUnloadingStartAt] = useState('');
  const [unloadingEndAt, setUnloadingEndAt] = useState('');
  const [unloadingStayMinutes, setUnloadingStayMinutes] = useState('- 分');
  const [itemPackingStyle, setItemPackingStyle] = useState('-');
  const [itemCount, setItemCount] = useState('-');
  const [itemTotalWeightKg, setItemTotalWeightKg] = useState('- kg');
  const [itemTotalVolumeM3, setItemTotalVolumeM3] = useState('- m3');
  const [itemKlass, setItemKlass] = useState('-');
  const [itemName, setItemName] = useState('-');
  const [itemHandlingOfCargoStyle, setItemHandlingOfCargoStyle] = useState('-');
  const [itemCanBeMixed, setItemCanBeMixed] = useState('-');
  const [designatedTruckKlass, setDesignatedTruckKlass] = useState('無し');
  const [designatedTruckCarModel, setDesignatedTruckCarModel] = useState('無し');
  const [designatedTruckLoadingPlatformHeight, setDesignatedTruckLoadingPlatformHeight] = useState('無し');
  const [designatedTruckLoadingPlatformWidth, setDesignatedTruckLoadingPlatformWidth] = useState('無し');
  const [designatedTruckLoadingPlatformLength, setDesignatedTruckLoadingPlatformLength] = useState('無し');
  const [designatedTruckFloorSpecification, setDesignatedTruckFloorSpecification] = useState('無し');
  const [designatedTruckFeature, setDesignatedTruckFeature] = useState('無し');
  const [chargeBasicFeeYen, setChargeBasicFeeYen] = useState('￥ -');
  const [chargeHighwayFeeYen, setChargeHighwayFeeYen] = useState('￥ -');
  const [chargeLoadingFeeYen, setChargeLoadingFeeYen] = useState('￥ -');
  const [chargeAncillaryFeeYen, setChargeAncillaryFeeYen] = useState('￥ -');
  const [chargeWaitingTimeFeeYen, setChargeWaitingTimeFeeYen] = useState('￥ -');
  const [chargeUnloadingFeeYen, setChargeUnloadingFeeYen] = useState('￥ -');
  const [chargeExpensesFeeYen, setChargeExpensesFeeYen] = useState('￥ -');
  const [chargeAncillaryContent, setChargeAncillaryContent] = useState('-');
  const [showNotAllocReasons, setShowNotAllocReasons] = useState(false);
  const [filteredNotAllocReasons, setFilteredNotAllocReasons] = useState<PlanningsNotAllocReason[]>([]);
  const [notAllocDeliveries, setNotAllocDeliveries] = useState<PlanningsDeliveryEntity[]>([]);
  const [notAllocTrucks, setNotAllocTrucks] = useState<PlanningsTruckEntity[]>([]);
  const [notAllocDrivers, setNotAllocDrivers] = useState<PlanningsDriverEntity[]>([]);
  const [allowedTrucks, setAllowedTrucks] = useState<TruckDriverIdAndName[]>([]);
  const [deniedDrivers, setDeniedDrivers] = useState<TruckDriverIdAndName[]>([]);

  useEffect(() => {
    if (!data) return;

    if (data.code) {
      setCode(
        data.code
      );
    }

    if (data.memo) {
      setNote(
        data.memo
      );
    }

    if (data.shipper_name) {
      setShipperName(
        data.shipper_name
      );
    }

    if (data.shipper_phone_number) {
      setPhoneNumber(
        data.shipper_phone_number
      );
    }

    if (data.shipper_email_address) {
      setEmailAddress(
        data.shipper_email_address
      );
    }

    if (data.loading_name) {
      setLoadingName(
        data.loading_name
      );
    }

    if (data.loading_address) {
      setLoadingAddress(
        data.loading_address
      );
    }

    if (data.loading_start_at) {
      setLoadingStartAt(
        datetimeDecorator.toDayAndHourMinutes(
          new Date(data.loading_start_at)
        )
      );
    }

    if (data.loading_end_at) {
      setLoadingEndAt(
        datetimeDecorator.toDayAndHourMinutes(
          new Date(data.loading_end_at)
        )
      );
    }

    if (data.loading_staying_minutes) {
      setLoadingStayMinutes(
        numberDecorator.toMinutes(
          data.loading_staying_minutes
        )
      );
    }

    if (data.unloading_name) {
      setUnloadingName(
        data.unloading_name
      );
    }

    if (data.unloading_address) {
      setUnloadingAddress(
        data.unloading_address
      );
    }

    if (data.unloading_start_at) {
      setUnloadingStartAt(
        datetimeDecorator.toDayAndHourMinutes(
          new Date(data.unloading_start_at)
        )
      );
    }

    if (data.unloading_end_at) {
      setUnloadingEndAt(
        datetimeDecorator.toDayAndHourMinutes(
          new Date(data.unloading_end_at)
        )
      );
    }

    if (data.unloading_staying_minutes) {
      setUnloadingStayMinutes(
        numberDecorator.toMinutes(
          data.unloading_staying_minutes
        )
      );
    }

    if (data.item_packing_style) {
      setItemPackingStyle(
        data.item_packing_style
      );
    }

    if (data.item_count || data.item_count === 0) {
      setItemCount(
        data.item_count.toLocaleString()
      );
    }

    if (data.item_total_weight_kg) {
      setItemTotalWeightKg(
        numberDecorator.toRoundedUnit(
          data.item_total_weight_kg,
          'kg',
          1,
          ''
        )
      );
    } else if (data.item_total_weight_kg === 0) {
      setItemTotalWeightKg('0 kg');
    }

    if (data.item_total_volume_m3) {
      setItemTotalVolumeM3(
        numberDecorator.toRoundedUnit(
          data.item_total_volume_m3,
          'm3',
          1,
          ''
        )
      );
    }

    if (data.item_klass) {
      setItemKlass(
        data.item_klass
      );
    }

    if (data.item_name) {
      setItemName(
        data.item_name
      );
    }

    if (data.item_handling_of_cargo_style) {
      setItemHandlingOfCargoStyle(
        data.item_handling_of_cargo_style
      );
    }

    setItemCanBeMixed(
      data.item_can_be_mixed ? '可' : '不可'
    );

    if ([data.designated_truck_klasses, data.designated_truck_klasses.length].every((maybe) => maybe)) {
      setDesignatedTruckKlass(
        data.designated_truck_klasses.join(', ')
      );
    }

    if ([data.designated_truck_car_models, data.designated_truck_car_models.length].every((maybe) => maybe)) {
      setDesignatedTruckCarModel(
        data.designated_truck_car_models.join('-')
      );
    }

    if ([data.designated_truck_loading_platform_heights, data.designated_truck_loading_platform_heights.length].every((maybe) => maybe)) {
      setDesignatedTruckLoadingPlatformHeight(
        data.designated_truck_loading_platform_heights.join('-')
      );
    }

    if ([data.designated_truck_loading_platform_widths, data.designated_truck_loading_platform_widths.length].every((maybe) => maybe)) {
      setDesignatedTruckLoadingPlatformWidth(
        data.designated_truck_loading_platform_widths.join('-')
      );
    }

    if ([data.designated_truck_loading_platform_lengths, data.designated_truck_loading_platform_lengths.length].every((maybe) => maybe)) {
      setDesignatedTruckLoadingPlatformLength(
        data.designated_truck_loading_platform_lengths.join('-')
      );
    }

    if ([data.designated_truck_floor_specifications, data.designated_truck_floor_specifications.length].every((maybe) => maybe)) {
      setDesignatedTruckFloorSpecification(
        data.designated_truck_floor_specifications.join('-')
      );
    }

    if ([data.designated_truck_features, data.designated_truck_features.length].every((maybe) => maybe)) {
      setDesignatedTruckFeature(
        data.designated_truck_features.join('-')
      );
    }

    if (data.charge_basic_fee_yen) {
      setChargeBasicFeeYen(
        numberDecorator.toYen(
          data.charge_basic_fee_yen
        )
      );
    }

    if (data.charge_highway_fee_yen) {
      setChargeHighwayFeeYen(
        numberDecorator.toYen(
          data.charge_highway_fee_yen
        )
      );
    }

    if (data.charge_loading_fee_yen) {
      setChargeLoadingFeeYen(
        numberDecorator.toYen(
          data.charge_loading_fee_yen
        )
      );
    }

    if (data.charge_ancillary_fee_yen) {
      setChargeAncillaryFeeYen(
        numberDecorator.toYen(
          data.charge_ancillary_fee_yen
        )
      );
    }

    if (data.charge_waiting_time_fee_yen) {
      setChargeWaitingTimeFeeYen(
        numberDecorator.toYen(
          data.charge_waiting_time_fee_yen
        )
      );
    }

    if (data.charge_unloading_fee_yen) {
      setChargeUnloadingFeeYen(
        numberDecorator.toYen(
          data.charge_unloading_fee_yen
        )
      );
    }

    if (data.charge_expenses_fee_yen) {
      setChargeExpensesFeeYen(
        numberDecorator.toYen(
          data.charge_expenses_fee_yen
        )
      );
    }

    if (data.charge_ancillary_content) {
      setChargeAncillaryContent(
        data.charge_ancillary_content
      );
    }

    if (data.allowed_trucks) {
      setAllowedTrucks(data.allowed_trucks);
    }

    if (data.denied_drivers) {
      setDeniedDrivers(data.denied_drivers);
    }
  }, [data]);

  const customFields = useMemo(() => (data
    ? customInputFields.map((it) => ({
        key: it,
        value: data.custom_input_fields.find(({ key }) => key === it)?.value || '',
      }))
    : []
  ), [customInputFields, data]);

  const customFieldsElement = useMemo(() => (
    <List
      sx={{
        pb: 0.3
      }}
    >
      {
        !!customFields.length
        && customFields
          .filter((it) => !hideOrderProperties.includes(it.key))
          .map((it) => (
            <ListItem
              key={[
                'PlanningOrder',
                it.key
              ].join('-')}
              disablePadding
            >
              <ListItemText
                primary={it.value || '-'}
              />
            </ListItem>
          ))
      }
    </List>
  ), [customFields, hideOrderProperties]);

  const [displayBasicInfo, setDisplayBasicInfo] = useState(false);
  const [displayCode, setDisplayCode] = useState(false);
  const [displayNote, setDisplayNote] = useState(false);
  useEffect(() => {
    setDisplayCode(!hideOrderProperties?.includes('code'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayNote(!hideOrderProperties?.includes('note'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayBasicInfo(displayCode || displayNote);
  }, [displayCode, displayNote]);

  const basicElement = useMemo(() => (
    <List
      sx={{
        py: 0
      }}
    >
      {
        displayCode && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary={code}
            />
          </ListItem>
        )
      }
      {
        displayNote && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary={note}
            />
          </ListItem>
        )
      }
    </List>
  ), [displayCode, code, displayNote, note]);

  const [displayShipperInfo, setDisplayShipperInfo] = useState(false);
  const [displayShipperName, setDisplayShipperName] = useState(false);
  const [displayPhoneNumber, setDisplayPhoneNumber] = useState(false);
  const [displayEmailAddress, setDisplayEmailAddress] = useState(false);
  useEffect(() => {
    setDisplayShipperName(!hideOrderProperties?.includes('shipperName'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayPhoneNumber(!hideOrderProperties?.includes('phoneNumber'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayEmailAddress(!hideOrderProperties?.includes('emailAddress'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayShipperInfo(displayShipperName || displayPhoneNumber || displayEmailAddress);
  }, [displayEmailAddress, displayPhoneNumber, displayShipperName]);

  const shipperElement = useMemo(() => (
    <List
      sx={{
        py: 0
      }}
    >
      {
        displayShipperName && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary={shipperName}
            />
          </ListItem>
        )
      }
      {
        displayPhoneNumber && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary={phoneNumber}
            />
          </ListItem>
        )
      }
      {
        displayEmailAddress && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary={emailAddress}
            />
          </ListItem>
        )
      }
    </List>
  ), [displayShipperName, shipperName, displayPhoneNumber, phoneNumber, displayEmailAddress, emailAddress]);

  const [displayLoadingInfo, setDisplayLoadingInfo] = useState(false);
  const [displayLoadingName, setDisplayLoadingName] = useState(false);
  const [displayLoadingAddress, setDisplayLoadingAddress] = useState(false);
  const [displayLoadingSpecifiedTime, setDisplayLoadingSpecifiedTime] = useState(false);
  useEffect(() => {
    setDisplayLoadingName(!hideOrderProperties?.includes('loadingName'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayLoadingAddress(!hideOrderProperties?.includes('loadingAddress'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayLoadingSpecifiedTime(!hideOrderProperties?.includes('loadingSpecifiedTime'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayLoadingInfo(displayLoadingName || displayLoadingAddress || displayLoadingSpecifiedTime);
  }, [displayLoadingName, displayLoadingAddress, displayLoadingSpecifiedTime]);

  const loadingElement = useMemo(() => (
    <List
      sx={{
        py: 0
      }}
    >
      <ListItem
        disablePadding
      >
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
        >
          <ArrowCircleUpRoundedIcon color="success" fontSize="small" />
          <ListItemText
            primary={(
              <>
                {
                  displayLoadingName && (
                    <Typography>
                      {loadingName}
                    </Typography>
                  )
                }
                {
                  displayLoadingAddress && (
                    <Typography>
                      {loadingAddress}
                    </Typography>
                  )
                }
                {
                  displayLoadingSpecifiedTime && (
                    <Typography>
                      {
                        [
                          [
                            loadingStartAt,
                            loadingEndAt,
                          ].join(' - '),
                          loadingStayMinutes
                        ].join(' ')
                      }
                    </Typography>
                  )
                }
              </>
            )}
          />
        </Stack>
      </ListItem>
    </List>
  ), [displayLoadingName, loadingName, displayLoadingAddress, loadingAddress, displayLoadingSpecifiedTime, loadingStartAt, loadingEndAt, loadingStayMinutes]);

  const [displayUnloadingInfo, setDisplayUnloadingInfo] = useState(false);
  const [displayUnloadingName, setDisplayUnloadingName] = useState(false);
  const [displayUnloadingAddress, setDisplayUnloadingAddress] = useState(false);
  const [displayUnloadingSpecifiedTime, setDisplayUnloadingSpecifiedTime] = useState(false);
  useEffect(() => {
    setDisplayUnloadingName(!hideOrderProperties?.includes('unloadingName'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayUnloadingAddress(!hideOrderProperties?.includes('unloadingAddress'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayUnloadingSpecifiedTime(!hideOrderProperties?.includes('unloadingSpecifiedTime'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayUnloadingInfo(displayUnloadingName || displayUnloadingAddress || displayUnloadingSpecifiedTime);
  }, [displayUnloadingName, displayUnloadingAddress, displayUnloadingSpecifiedTime]);

  const unloadingElement = useMemo(() => (
    <List
      sx={{
        py: 0
      }}
    >
      <ListItem
        disablePadding
      >
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
        >
          <ArrowCircleDownRoundedIcon color="error" fontSize="small" />
          <ListItemText
            primary={(
              <>
                {
                  displayUnloadingName && (
                    <Typography>
                      {unloadingName}
                    </Typography>
                  )
                }
                {
                  displayUnloadingAddress && (
                    <Typography>
                      {unloadingAddress}
                    </Typography>
                  )
                }
                {
                  displayUnloadingSpecifiedTime && (
                    <Typography>
                      {
                        [
                          [
                            unloadingStartAt,
                            unloadingEndAt,
                          ].join(' - '),
                          unloadingStayMinutes
                        ].join(' ')
                      }
                    </Typography>
                  )
                }
              </>
            )}
          />
        </Stack>
      </ListItem>
    </List>
  ), [displayUnloadingName, unloadingName, displayUnloadingAddress, unloadingAddress, displayUnloadingSpecifiedTime, unloadingStartAt, unloadingEndAt, unloadingStayMinutes]);

  const [displayItemInfo, setDisplayItemInfo] = useState(false);
  const [displayItemCount, setDisplayItemtCount] = useState(false);
  const [displayiIemTotalWeightKg, setDisplayiIemTotalWeightKg] = useState(false);
  const [displayItemTotalVolumeM3, setDisplayItemTotalVolumeM3] = useState(false);
  const [displayItemPackingStyle, setDisplayItemPackingStyle] = useState(false);
  const [displayItemKlass, setDisplayItemKlass] = useState(false);
  const [displayItemName, setDisplayItemName] = useState(false);
  const [displayItemHandlingOfCargoStyle, setDisplayItemHandlingOfCargoStyle] = useState(false);
  const [displayItemCanBeMixed, setDisplayItemCanBeMixed] = useState(false);
  useEffect(() => {
    setDisplayItemtCount(!hideOrderProperties?.includes('itemCount'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayiIemTotalWeightKg(!hideOrderProperties?.includes('itemTotalWeightKg'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayItemTotalVolumeM3(!hideOrderProperties?.includes('itemTotalVolumeM3'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayItemPackingStyle(!hideOrderProperties?.includes('itemPackingStyle'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayItemKlass(!hideOrderProperties?.includes('itemKlass'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayItemName(!hideOrderProperties?.includes('itemName'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayItemHandlingOfCargoStyle(!hideOrderProperties?.includes('itemHandlingOfCargoStyle'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayItemCanBeMixed(!hideOrderProperties?.includes('itemCanBeMixed'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayItemInfo(displayItemCount || displayiIemTotalWeightKg || displayItemTotalVolumeM3 || displayItemPackingStyle || displayItemKlass || displayItemName || displayItemHandlingOfCargoStyle || displayItemCanBeMixed);
  }, [displayItemCount, displayiIemTotalWeightKg, displayItemTotalVolumeM3, displayItemPackingStyle, displayItemKlass, displayItemName, displayItemHandlingOfCargoStyle, displayItemCanBeMixed]);

  const itemElement = useMemo(() => (
    <List
      sx={{
        py: 0
      }}
    >
      {
        [displayItemCount, displayiIemTotalWeightKg, displayItemTotalVolumeM3].some((maybe) => maybe) && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary={
                (
                  <Stack
                    direction="row"
                    gap={1}
                  >
                    {
                      displayItemCount && (
                        <Typography>
                          数量
                          {itemCount}
                        </Typography>
                      )
                    }
                    {
                      displayiIemTotalWeightKg && (
                        <Typography>
                          {itemTotalWeightKg}
                        </Typography>
                      )
                    }
                    {
                      displayItemTotalVolumeM3 && (
                        <Typography>
                          {itemTotalVolumeM3}
                        </Typography>
                      )
                    }
                  </Stack>
                )
              }
            />
          </ListItem>
        )
      }
      {
        displayItemPackingStyle && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary="荷姿"
              secondary={itemPackingStyle}
            />
          </ListItem>
        )
      }
      {
        displayItemKlass && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary="輸送区分"
              secondary={itemKlass}
            />
          </ListItem>
        )
      }
      {
        displayItemName && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary="品名"
              secondary={itemName}
            />
          </ListItem>
        )
      }
      {
        displayItemHandlingOfCargoStyle && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary="荷扱"
              secondary={itemHandlingOfCargoStyle}
            />
          </ListItem>
        )
      }
      {
        displayItemCanBeMixed && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary="積み合わせ"
              secondary={itemCanBeMixed}
            />
          </ListItem>
        )
      }
    </List>
  ), [displayItemCount, displayiIemTotalWeightKg, displayItemTotalVolumeM3, itemCount, itemTotalWeightKg, itemTotalVolumeM3, displayItemPackingStyle, itemPackingStyle, displayItemKlass, itemKlass, displayItemName, itemName, displayItemHandlingOfCargoStyle, itemHandlingOfCargoStyle, displayItemCanBeMixed, itemCanBeMixed]);

  const [displayDesignatedTruckInfo, setDisplayDesignatedTruckInfo] = useState(false);
  const [displayaDesignatedTruckKlass, setDisplayaDesignatedTruckKlass] = useState(false);
  const [displayDesignatedTruckCarModel, setDisplayDesignatedTruckCarModel] = useState(false);
  const [displayDesignatedTruckLoadingPlatformHeight, setDisplayDesignatedTruckLoadingPlatformHeight] = useState(false);
  const [displayDesignatedTruckLoadingPlatformWidth, setDisplayDesignatedTruckLoadingPlatformWidth] = useState(false);
  const [displayDesignatedTruckLoadingPlatformLength, setDisplayDesignatedTruckLoadingPlatformLength] = useState(false);
  const [displayDesignatedTruckFloorSpecification, setDisplayDesignatedTruckFloorSpecification] = useState(false);
  const [displayDesignatedTruckFeature, setDisplayDesignatedTruckFeature] = useState(false);
  useEffect(() => {
    setDisplayaDesignatedTruckKlass(!hideOrderProperties?.includes('designatedTruckKlass'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayDesignatedTruckCarModel(!hideOrderProperties?.includes('designatedTruckCarModel'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayDesignatedTruckLoadingPlatformHeight(!hideOrderProperties?.includes('designatedTruckLoadingPlatformHeight'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayDesignatedTruckLoadingPlatformWidth(!hideOrderProperties?.includes('designatedTruckLoadingPlatformWidth'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayDesignatedTruckLoadingPlatformLength(!hideOrderProperties?.includes('designatedTruckLoadingPlatformLength'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayDesignatedTruckFloorSpecification(!hideOrderProperties?.includes('designatedTruckFloorSpecification'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayDesignatedTruckFeature(!hideOrderProperties?.includes('designatedTruckFeature'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayDesignatedTruckInfo(displayaDesignatedTruckKlass || displayDesignatedTruckCarModel || displayDesignatedTruckLoadingPlatformHeight || displayDesignatedTruckLoadingPlatformWidth || displayDesignatedTruckLoadingPlatformLength || displayDesignatedTruckFloorSpecification || displayDesignatedTruckFeature);
  }, [displayaDesignatedTruckKlass, displayDesignatedTruckCarModel, displayDesignatedTruckLoadingPlatformHeight, displayDesignatedTruckLoadingPlatformWidth, displayDesignatedTruckLoadingPlatformLength, displayDesignatedTruckFloorSpecification, displayDesignatedTruckFeature]);

  const designatedTruckElement = useMemo(() => (
    <List
      sx={{
        py: 0
      }}
    >
      { displayaDesignatedTruckKlass && (
        <ListItem
          disablePadding
        >
          <ListItemText
            primary="指定車両タイプ"
            secondary={designatedTruckKlass}
          />
        </ListItem>
      )}
      { displayDesignatedTruckCarModel && (
        <ListItem
          disablePadding
        >
          <ListItemText
            primary="指定車種"
            secondary={designatedTruckCarModel}
          />
        </ListItem>
      )}
      { displayDesignatedTruckLoadingPlatformHeight && (
        <ListItem
          disablePadding
        >
          <ListItemText
            primary="荷台高さ"
            secondary={designatedTruckLoadingPlatformHeight}
          />
        </ListItem>
      )}
      { displayDesignatedTruckLoadingPlatformWidth && (
        <ListItem
          disablePadding
        >
          <ListItemText
            primary="荷台幅"
            secondary={designatedTruckLoadingPlatformWidth}
          />
        </ListItem>
      )}
      { displayDesignatedTruckLoadingPlatformLength && (
        <ListItem
          disablePadding
        >
          <ListItemText
            primary="荷台長さ"
            secondary={designatedTruckLoadingPlatformLength}
          />
        </ListItem>
      )}
      { displayDesignatedTruckFloorSpecification && (
        <ListItem
          disablePadding
        >
          <ListItemText
            primary="床仕様"
            secondary={designatedTruckFloorSpecification}
          />
        </ListItem>
      )}
      { displayDesignatedTruckFeature && (
        <ListItem
          disablePadding
        >
          <ListItemText
            primary="装置"
            secondary={designatedTruckFeature}
          />
        </ListItem>
      )}
    </List>
  ), [displayaDesignatedTruckKlass, designatedTruckKlass, displayDesignatedTruckCarModel, designatedTruckCarModel, displayDesignatedTruckLoadingPlatformHeight, designatedTruckLoadingPlatformHeight, displayDesignatedTruckLoadingPlatformWidth, designatedTruckLoadingPlatformWidth, displayDesignatedTruckLoadingPlatformLength, designatedTruckLoadingPlatformLength, displayDesignatedTruckFloorSpecification, designatedTruckFloorSpecification, displayDesignatedTruckFeature, designatedTruckFeature]);

  const [displayChargeInfo, setDisplayChargeInfo] = useState(false);
  const [displayChargeBasicFeeYen, setDisplayChargeBasicFeeYen] = useState(false);
  const [displayChargeHighwayFeeYen, setDisplayChargeHighwayFeeYen] = useState(false);
  const [displayChargeLoadingFeeYen, setDisplayChargeLoadingFeeYen] = useState(false);
  const [displayChargeAncillaryFeeYen, setDisplayChargeAncillaryFeeYen] = useState(false);
  const [displayChargeWaitingTimeFeeYen, setDisplayChargeWaitingTimeFeeYen] = useState(false);
  const [displayChargeUnloadingFeeYen, setDisplayChargeUnloadingFeeYen] = useState(false);
  const [displayChargeExpensesFeeYen, setDisplayChargeExpensesFeeYen] = useState(false);
  const [displayChargeAncillaryContent, setDisplayChargeAncillaryContent] = useState(false);
  useEffect(() => {
    setDisplayChargeBasicFeeYen(!hideOrderProperties?.includes('chargeBasicFeeYen'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayChargeHighwayFeeYen(!hideOrderProperties?.includes('chargeHighwayFeeYen'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayChargeLoadingFeeYen(!hideOrderProperties?.includes('chargeLoadingFeeYen'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayChargeAncillaryFeeYen(!hideOrderProperties?.includes('chargeAncillaryFeeYen'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayChargeWaitingTimeFeeYen(!hideOrderProperties?.includes('chargeWaitingTimeFeeYen'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayChargeUnloadingFeeYen(!hideOrderProperties?.includes('chargeUnloadingFeeYen'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayChargeExpensesFeeYen(!hideOrderProperties?.includes('chargeExpensesFeeYen'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayChargeAncillaryContent(!hideOrderProperties?.includes('chargeAncillaryContent'));
  }, [hideOrderProperties]);
  useEffect(() => {
    setDisplayChargeInfo(displayChargeBasicFeeYen || displayChargeHighwayFeeYen || displayChargeLoadingFeeYen || displayChargeAncillaryFeeYen || displayChargeWaitingTimeFeeYen || displayChargeUnloadingFeeYen || displayChargeExpensesFeeYen || displayChargeAncillaryContent);
  }, [displayChargeBasicFeeYen, displayChargeHighwayFeeYen, displayChargeLoadingFeeYen, displayChargeAncillaryFeeYen, displayChargeWaitingTimeFeeYen, displayChargeUnloadingFeeYen, displayChargeExpensesFeeYen, displayChargeAncillaryContent]);

  const chargeElement = useMemo(() => (
    <List
      sx={{
        py: 0
      }}
    >
      {
        displayChargeBasicFeeYen && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary="基本運賃"
              secondary={chargeBasicFeeYen}
            />
          </ListItem>
        )
      }
      {
        displayChargeHighwayFeeYen && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary="高速代"
              secondary={chargeHighwayFeeYen}
            />
          </ListItem>
        )
      }
      {
        displayChargeLoadingFeeYen && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary="積込料"
              secondary={chargeLoadingFeeYen}
            />
          </ListItem>
        )
      }
      {
        displayChargeAncillaryFeeYen && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary="付帯業務料"
              secondary={chargeAncillaryFeeYen}
            />
          </ListItem>
        )
      }
      {
        displayChargeWaitingTimeFeeYen && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary="待機時間料"
              secondary={chargeWaitingTimeFeeYen}
            />
          </ListItem>
        )
      }
      {
        displayChargeUnloadingFeeYen && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary="取卸料"
              secondary={chargeUnloadingFeeYen}
            />
          </ListItem>
        )
      }
      {
        displayChargeExpensesFeeYen && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary="諸経費"
              secondary={chargeExpensesFeeYen}
            />
          </ListItem>
        )
      }
      {
        displayChargeAncillaryContent && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary="付帯業務内容"
              secondary={chargeAncillaryContent}
            />
          </ListItem>
        )
      }
    </List>
  ), [displayChargeBasicFeeYen, chargeBasicFeeYen, displayChargeHighwayFeeYen, chargeHighwayFeeYen, displayChargeLoadingFeeYen, chargeLoadingFeeYen, displayChargeAncillaryFeeYen, chargeAncillaryFeeYen, displayChargeWaitingTimeFeeYen, chargeWaitingTimeFeeYen, displayChargeUnloadingFeeYen, chargeUnloadingFeeYen, displayChargeExpensesFeeYen, chargeExpensesFeeYen, displayChargeAncillaryContent, chargeAncillaryContent]);

  const [displayAllowedTrucks, setDisplayAllowedTrucks] = useState<boolean>(false);
  useEffect(() => {
    setDisplayAllowedTrucks(!hideOrderProperties?.includes('allowedTrucks'));
  }, [hideOrderProperties]);
  const [displayDeniedDrivers, setDisplayDeniedDrivers] = useState<boolean>(false);
  useEffect(() => {
    setDisplayDeniedDrivers(!hideOrderProperties?.includes('deniedDrivers'));
  }, [hideOrderProperties]);
  const [displayDriverTruckDesignation, setDisplayDriverTruckDesignation] = useState<boolean>(false);
  useEffect(() => {
    setDisplayDriverTruckDesignation(displayAllowedTrucks || displayDeniedDrivers);
  }, [displayAllowedTrucks, displayDeniedDrivers]);

  const driverTruckDesignationElement = useMemo(() => (
    <List
      sx={{
        py: 0
      }}
    >
      {
        displayAllowedTrucks && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary="指定トラック"
              secondary={allowedTrucks.map((it) => it.name).join(', ')}
            />
          </ListItem>
        )
      }
      {
        displayDeniedDrivers && (
          <ListItem
            disablePadding
          >
            <ListItemText
              primary="NGドライバー"
              secondary={deniedDrivers.map((it) => it.name).join(', ')}
            />
          </ListItem>
        )
      }
    </List>
  ), [allowedTrucks, deniedDrivers, displayAllowedTrucks, displayDeniedDrivers]);

  useEffect(() => {
    if (!notAllocReasons) {
      setShowNotAllocReasons(false);
      return;
    }

    const filtered = notAllocReasons.filter((it) => it.order_id === data.id);
    const deliveryIds = filtered.map((it) => it.delivery_id);
    const deliveries = deliveryEntities.filter((it) => deliveryIds.includes(it.id));
    const truckIds = deliveries.map((it) => it.truckId);
    const trucks = truckEntities.filter((it) => truckIds.includes(it.id));
    const driverIds = deliveries.map((it) => it.driverId);
    const drivers = driverEntities.filter((it) => driverIds.includes(it.id));

    setShowNotAllocReasons(filtered.length > 0);
    setFilteredNotAllocReasons(filtered);
    setNotAllocDeliveries(deliveries);
    setNotAllocTrucks(trucks);
    setNotAllocDrivers(drivers);
  }, [deliveryEntities, driverEntities, notAllocReasons, data, truckEntities]);

  const notAllocReasonElement = useMemo(() => (
    <NotAllocReasonPresenter
      filteredNotAllocReasons={filteredNotAllocReasons}
      notAllocDeliveries={notAllocDeliveries}
      notAllocTrucks={notAllocTrucks}
      notAllocDrivers={notAllocDrivers}
    />
  ), [filteredNotAllocReasons, notAllocDeliveries, notAllocDrivers, notAllocTrucks]);

  return (
    <Stack
      spacing={0.3}
      sx={{
        width: '100%'
      }}
      bgcolor={theme.palette.background.paper}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          position: 'absolute',
          top: 5,
          right: 5,
          zIndex: 100
        }}
      >
        {showNotAllocReasons && notAllocReasonElement}
        <IconButton
          onClick={onClickMenu}
          sx={{ padding: '3px' }}
        >
          <MoreVertIcon />
        </IconButton>
      </Stack>
      {
        !customInputFields.every((it: string) => hideOrderProperties?.includes(it)) && customFieldsElement
      }
      {
        displayBasicInfo && basicElement
      }

      {
        displayShipperInfo && shipperElement
      }

      {
        displayItemInfo && itemElement
      }

      {
        displayLoadingInfo && loadingElement
      }

      {
        displayUnloadingInfo && unloadingElement
      }

      {
        displayDesignatedTruckInfo && designatedTruckElement
      }

      {
        displayChargeInfo && chargeElement
      }

      {
        displayDriverTruckDesignation && driverTruckDesignationElement
      }
    </Stack>
  );
});

export default DisplayPropertiesSelectableUnallocatedOrderPresenter;
