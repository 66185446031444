import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { ApiResponse } from 'src/constants/apiResponse';
import { TruckEntity, TruckRequestEntity } from 'src/entities/truckEntity';

const getTrucks = (queryClient: QueryClient, page: number, allVisible?: boolean) => async () => {
  const res = await axios.get<ApiResponse>(`/api/v2/trucks?with_data=1&all_visible=${String(!!allVisible)}`);
  const list = res.data.data;
  return list.map((entity: TruckEntity) => {
    queryClient.setQueryData(['trucks', { id: entity.id }], entity);
    return entity;
  });
};

const getTruck = (id: number) => async () => {
  const res = await axios.get<ApiResponse>(`/api/v2/trucks/${id}`);
  const entity = JSON.parse(res.data as unknown as string) as TruckEntity;
  return entity;
};

export const useQueryTrucks = (page: number, params: { enabled: boolean, allVisible?: boolean }) => {
  const queryClient = useQueryClient();
  return useQuery(['trucks', { page, allVisible: params.allVisible }], getTrucks(queryClient, page, params.allVisible), {
    enabled: params.enabled,
    staleTime: Infinity
  });
};

export const useQueryTruck = (id: number) => {
  const queryClient = useQueryClient();
  return useQuery(['trucks', { id }], getTruck(id), {
    staleTime: Infinity,
    enabled: Boolean(id)
  });
};

export const useMutationTruck = () => {
  const queryClient = useQueryClient();
  return {
    addTruck: useMutation(
      (trucks: TruckRequestEntity) => axios.post('/api/v2/trucks', trucks),
      {
        onSuccess: () => queryClient.invalidateQueries(['trucks']),
      }
    ),
    updateTruck: useMutation(
      (trucks: TruckEntity) => axios.put(`/api/v2/trucks/${trucks.id}`, trucks),
      {
        onSuccess: (res) => queryClient.invalidateQueries(['trucks']),
      }
    ),
    deleteTrucks: useMutation(
      (ids: number[]) => axios.delete('/api/v2/trucks/bulk', { data: { ids } }),
      {
        onSuccess: () => {
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['trucks']);
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['drivers']);
        },
      }
    ),
  };
};
