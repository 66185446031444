import * as yup from 'yup';

export const schema = yup.object({
  garage_id: yup.number().required('必須です'),
  maximum_loading_capacity_kg: yup.number().required('必須です'),
  license_plate_value: yup.string().required('必須です'),
  truck_fuel_cost_yen_per_km: yup
    .number()
    .integer('整数で入力してください')
    .positive('整数で入力してください')
    .typeError('整数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  truck_expressway_fee_yen_per_shift: yup
    .number()
    .integer('整数で入力してください')
    .positive('整数で入力してください')
    .typeError('整数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  truck_insurance_fee_yen_per_day: yup
    .number()
    .integer('整数で入力してください')
    .positive('整数で入力してください')
    .typeError('整数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  truck_repair_cost_yen_per_day: yup
    .number()
    .integer('整数で入力してください')
    .positive('整数で入力してください')
    .typeError('整数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  max_num_pistons: yup
    .number()
    .integer('整数で入力してください')
    .positive('整数で入力してください')
    .typeError('整数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  max_num_locations_per_pistons: yup
    .number()
    .integer('整数で入力してください')
    .positive('整数で入力してください')
    .typeError('整数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
});
