import { Dialog, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { FC, memo } from 'react';

type Props = {
  open: boolean;
  message: string;
  handleOk: () => void;
  handleCancel: () => void;
}

export const ConfirmDialog: FC<Props> = memo(({ open, message, handleOk, handleCancel }) => (
  <Dialog
    sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
    maxWidth="xs"
    open={open}
  >
    <DialogContent dividers>
      <Typography>
        {message}
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={handleCancel}>
        キャンセル
      </Button>
      <Button onClick={handleOk}>Ok</Button>
    </DialogActions>
  </Dialog>
  ));
