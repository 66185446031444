import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import FindInPageRoundedIcon from '@mui/icons-material/FindInPageRounded';
import { Checkbox, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import numberDecorator from 'src/decorators/number.decorator';
import { PlanningsOperationEntityWithStatsEntity } from 'src/entities/PlanningsOperationEntityWithStats.entity';

const LoadIcon: JSX.Element = <ArrowCircleUpRoundedIcon color="success" fontSize="small" />;
const UnloadIcon = <ArrowCircleDownRoundedIcon color="error" fontSize="small" />;

type Props = {
  entity: PlanningsOperationEntityWithStatsEntity;
  loadCapacityWeightG: number;
  loadCapacityVolumeMm3?: number;
  selectedOrderIds: number[];
  addSelectedOrderIds: (orderIds: number[]) => void;
  removeSelectedOrderIds: (orderIds: number[]) => void;
  updateDisplayOrderId: (orderId: number) => void;
  isLoading: boolean;
  mutateRestoreSplittedOrder: (orderId: number) => void;
}

const PlanningMapOperationPresenter: FC<Props> = memo((
  {
    entity,
    loadCapacityWeightG,
    loadCapacityVolumeMm3,
    selectedOrderIds,
    addSelectedOrderIds,
    removeSelectedOrderIds,
    updateDisplayOrderId,
    isLoading,
    mutateRestoreSplittedOrder,
  }
) => {
  const [action, setAction] = useState<string>('');
  const [waitingMinutes, setWaitingMinutes] = useState('待機時間 -');
  const [operationMinutes, setOperationMinutes] = useState('作業時間 -');
  const [shipperName, setShipperName] = useState('');

  const [currentWeightG, setCurrentWeightG] = useState<number | undefined>(undefined);
  const [currentVolumeMm3, setCurrentVolumeMm3] = useState<number |undefined>(undefined);

  const [itemTotalWeightKg, setItemTotalWeightKg] = useState('重量 -');
  const [itemTotalVolumeM3, setItemTotalVolumeM3] = useState('体積 -');

  const [weightUtilizationRate, setWeightUtilizationRate] = useState('-');
  const [volumeUtilizationRate, setVolumeUtilizationRate] = useState('-');
  const [specifiedTime, setSpecifiedTime] = useState('');

  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isFixed, setIsFixed] = useState<boolean>(false);

  useEffect(() => {
    if (!entity) return;

    setAction(entity.action);

    if (entity.waitingDurationSeconds) {
      setWaitingMinutes(
        [
          '待機時間',
          numberDecorator.convertSecondsToMinutes(entity.waitingDurationSeconds) || '- 分'
        ].join(' ')
      );
    }
    if (entity.operationDurationSeconds) {
      setOperationMinutes(
        [
          '作業時間',
          numberDecorator.convertSecondsToMinutes(entity.operationDurationSeconds) || '- 分'
        ].join(' ')
      );
    }
    setShipperName(
      entity.shipperName
    );
    setItemTotalWeightKg(
      [
        '重量',
        numberDecorator.convertGramToKg(entity.itemTotalWeightForCalculation) || '- kg'
      ].join(' ')
    );
    if (entity.itemTotalVolumeMm3) {
      setItemTotalVolumeM3(
        [
          '体積',
          numberDecorator.convertMm3ToM3(entity.itemTotalVolumeMm3) || '- m3'
        ].join(' ')
      );
    }
    setCurrentWeightG(
      entity.currentWeightG
    );
    setCurrentVolumeMm3(
      entity.currentVolumeMm3
    );

    setIsFixed(entity.fixed);
  }, [entity]);

  useEffect(() => {
    if (![currentWeightG, loadCapacityWeightG].every((maybe) => maybe)) return;

    setWeightUtilizationRate(
      numberDecorator.toRoundedRate(
        currentWeightG / loadCapacityWeightG,
        ''
      )
    );
  }, [currentWeightG, loadCapacityWeightG]);

  useEffect(() => {
    if (![currentVolumeMm3, loadCapacityVolumeMm3].every((maybe) => maybe)) return;

    setVolumeUtilizationRate(
      numberDecorator.toRoundedRate(
        currentVolumeMm3 / loadCapacityVolumeMm3,
        ''
      )
    );
  }, [currentVolumeMm3, loadCapacityVolumeMm3]);

  useEffect(() => {
    if (!entity) return;

    setAction(entity.action);
  }, [entity]);

  useEffect(() => {
    if (!action) return;

    if (action === '積') {
      const startAt = new Date(
        entity.minLoadingStartAt
      );
      const endAt = new Date(
        entity.maxLoadingEndAt
      );

      setSpecifiedTime(
        [
          '指定時間',
          [
            datetimeDecorator.toHourMinutes(
              startAt
            ),
            datetimeDecorator.toHourMinutes(
              endAt
            )
          ].join(' - ')
        ].join(' ')
      );
    } else {
      const startAt = new Date(
        entity.minUnloadingStartAt
      );
      const endAt = new Date(
        entity.maxUnloadingEndAt
      );

      setSpecifiedTime(
        [
          '指定時間',
          [
            datetimeDecorator.toHourMinutes(
              startAt
            ),
            datetimeDecorator.toHourMinutes(
              endAt
            )
          ].join(' - ')
        ].join(' ')
      );
    }
  }, [action]);

  const [icon, setIcon] = useState<JSX.Element | undefined>(undefined);
  useEffect(() => {
    if (!action) return;

    setIcon(
      action === '積' ? LoadIcon : UnloadIcon
    );
  }, [action]);

  useEffect(() => {
    setIsSelected(
      selectedOrderIds.includes(entity.orderId)
    );
  }, [selectedOrderIds, entity]);

  const checkboxOnClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING_PLACE_DIALOG, button_name: `案件チェックボックス ${isSelected ? 'OFF' : 'ON'}` });
    if (isFixed) { return; }

    if (isSelected) {
      removeSelectedOrderIds([entity.orderId]);
    } else {
      addSelectedOrderIds([entity.orderId]);
    }
  };

  const buttonOnClick = () => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING_PLACE_DIALOG, button_name: '案件詳細' });
    updateDisplayOrderId(entity.orderId);
  };

  useEffect(() => {
    if (isFixed) {
      removeSelectedOrderIds([entity.orderId]);
    } else {
      addSelectedOrderIds([entity.orderId]);
    }
  }, [entity, isFixed]);

  const onClickRestoreSplittedOrder = useCallback((event: React.MouseEvent) => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING_PLACE_DIALOG, button_name: '案件分割の復元', label: entity.orderId });

    event.preventDefault();
    event.stopPropagation();

    mutateRestoreSplittedOrder(entity.orderId);
  }, [entity, mutateRestoreSplittedOrder]);

  const orderSplitMemo = useMemo(() => (
    <Tooltip title="自動分割された案件です">
      <IconButton
        onClick={onClickRestoreSplittedOrder}
        sx={{ p: 0.5, m: 0 }}
      >
        <CallSplitIcon color="error" fontSize="small" sx={{ p: 0, m: 0, }} />
      </IconButton>
    </Tooltip>
  ), [onClickRestoreSplittedOrder]);

  return (
    <Paper
      square
    >
      <Stack
        p={1}
        pr={0}
        spacing={0.5}
        sx={{ backgroundColor: isFixed ? '#ddd' : '' }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0.5}
          mr={1}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            onClick={checkboxOnClick}
            style={{
              cursor: 'pointer'
            }}
          >
            <Stack>
              <Checkbox
                checked={isSelected}
                onClick={checkboxOnClick}
                disabled={isLoading || isFixed}
                size="small"
                sx={{
                  m: 0,
                  p: 0.3,
                }}
              />
            </Stack>
            <Stack>
              {icon}
            </Stack>
            <Stack>
              <Stack
                direction="row"
                gap={1}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  {shipperName}
                </Typography>
                <Typography>
                  {specifiedTime}
                </Typography>
                <Typography>
                  {waitingMinutes}
                </Typography>
                <Typography>
                  {operationMinutes}
                </Typography>
                <Typography>
                  {itemTotalWeightKg}
                </Typography>
                <Typography>
                  (
                  {weightUtilizationRate}
                  )
                </Typography>
                <Typography>
                  {itemTotalVolumeM3}
                </Typography>
                <Typography>
                  (
                  {volumeUtilizationRate}
                  )
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            gap={0.3}
          >
            {entity.orderWasSplit && orderSplitMemo}
            <IconButton
              size="small"
              onClick={buttonOnClick}
            >
              <FindInPageRoundedIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
});

export default PlanningMapOperationPresenter;
